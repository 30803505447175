import React, { Dispatch, SetStateAction } from 'react'
import IntegrationModal from './IntegrationModal';
import { useTranslation } from 'react-i18next';
type Props = {
    modal: (val:boolean,set:React.Dispatch<React.SetStateAction<boolean>>,company:any)=>JSX.Element
    companyInfo: any
}

export default function IntegrationCard({modal,companyInfo} : Props) {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className="col-sm-6 col-md-4 mb-3">
            <div className="card card-bordered card-transition h-100">
                <a className="card-body" onClick={() => setModalShow(true)} >
                    <div className="d-flex">
                        <div className="flex-shrink-0">
                            <img className="avatar avatar-sm" src={companyInfo.logo} alt="Logo" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0">{companyInfo.companyName}</h5>
                            </div>
                            <p className="card-text text-body small">{companyInfo.companyType}</p>
                        </div>
                    </div>
                </a>
            </div>
            {
                modal(modalShow,setModalShow,companyInfo)
            }
        </div>
    )
}