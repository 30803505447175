import { auto } from '@popperjs/core';
import React, { ReactNode } from 'react';

type Props = {
    image: string;
    alignChild: 'left' | 'right';
    isOdyssey?: boolean,
    children: React.ReactNode
};

const ImageSideBySideWithText: React.FC<Props> = (props) => {

    const column1Classes = props.alignChild === 'left' ? 'col-md-7 mb-7 mb-md-0' : 'col-md-7 order-md-2 mb-7 mb-md-0';
    const column2Classes = props.alignChild === 'left' ? 'col-md-5 order-md-2' : 'col-md-5';


    return (
        <div className="container content-space-t-2 content-space-b-2 content-space-b-lg-3">
            <div className="row align-items-md-center">
                <div className={column1Classes}>
                    <div className=" mx-sm-auto" data-aos="fade-up">
                        <div className=" mx-auto">
                            {
                                props.isOdyssey != null 
                                    ?
                                    <figure className="device-browser">
                                        <div className="device-browser-header">
                                            <div className="device-browser-header-btn-list">
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                                <span className="device-browser-header-btn-list-btn"></span>
                                            </div>
                                            <div className="device-browser-header-browser-bar">{`https://${props.isOdyssey ? "odyssey" : "protectorNet"}:11001`}</div>
                                        </div>

                                        <div className="device-browser-frame">
                                            <img className="device-browser-img" src={props.image} alt="Image Description" style={{width:"640px"}} />
                                        </div>
                                    </figure>
                                    :
                                    <img className="img-fluid rounded-top-2" src={props.image} alt="Image Description" />
                            }
                        </div>
                    </div>
                </div>

                <div className={column2Classes}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ImageSideBySideWithText;