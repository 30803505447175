const DocumentationData = [
    {
        heading: "Quick Start Guide",
        desc: `This guide is a quick overview of the PROTECTOR.Net hardware installation procedures.`,
        image: "",
        filePath: "/public/documentation/ProtectorNet%20QuickStart.pdf" 
    },
    {
        heading: "Software Installation/Upgrade Guide",
        desc: `This guide covers minimum PC requirements as well as steps to install/upgrade the PROTECTOR.Net software.`,
        image: "",
        filePath: "/public/documentation/protectornet_installation_upgrade.pdf" 
    },
    {
        heading: "Software Setup Guide",
        desc: `This guide is intended for users/installers who will be administering the software, 
        including configuring controllers, card holders, privilege roles etc.`,
        image: "",
        filePath: "/public/documentation/ProtectorNetTechGuide.pdf" 
    },

];

export default DocumentationData;