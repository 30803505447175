import React, { useEffect } from 'react';
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import background from "../assets/svg/components/wave-pattern-light.svg";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

type Cards = {
    paragraph: string,
    image: string,
    link: string,
    title: string,
}

type Props = {
    heading: string,
    subheading: string,
    cards: Cards[],
    seeMore: string,
    seeMoreLink: string,
}


const ScrollingCards: React.FC<Props> = (props) => {
    return (
        <>
            <div className="bg-dark rounded-2 mx-3 mx-xl-10" style={{ backgroundImage: `url(${background})` }}>
                <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                    <div className="">
                        <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
                            <span className="text-cap text-white-70">{props.subheading}</span>
                            <h3 className="text-white" style={{fontWeight: "normal"}}>{props.heading}</h3>
                        </div>

                        <Swiper modules={[Navigation, Pagination]} slidesPerView={1} pagination={{
                            el: '.js-swiper-card-blocks-pagination',
                            dynamicBullets: true,
                            clickable: true,
                        }} breakpoints={{
                            620: {
                                width: 620,
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                width: 990,
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                        }}>
                            {
                                props.cards.map((card, index) => {
                                    return (
                                        <SwiperSlide key={nanoid()}>
                                            <div className="card h-100">
                                                <img className="card-img-top" src={card.image} alt="Image Description" />

                                                <div className="card-body" style={{height:"150px"}}>
                                                    <div className='pb-3 fw-bold'>{card.title}</div>
                                                    <p className="card-text" >{card.paragraph}</p>
                                                </div>

                                                <div className="card-footer">
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }

                        </Swiper>

                        {/* <div className="js-swiper-card-blocks-pagination swiper-pagination swiper-pagination-light"></div> */}
                        <div className='text-center pt-10'>
                            <Link to={props.seeMoreLink}><span className="card-link">{props.seeMore}<i className="bi bi-chevron-right small ms-1"></i></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScrollingCards;