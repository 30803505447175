import React from 'react';
import HalfPageHeroImage from '../../../components/halfPageHeroImage';
import ImageCollage3x2 from '../../../components/imageCollage3x2';
import wavepattern from '../../../assets/svg/components/wave-pattern-light.svg';
import ImageSideBySideWithText from '../../../components/imageSideBySideWithText';
import SectionPopOutWithParagraphs from '../../../components/sectionPopOutWithParagrapsh';
import ReadMoreWithPics from '../../../components/readMoreWithPics';
import { useTranslation } from 'react-i18next';
import {ReactComponent as Gen012} from '../../../assets/svg/duotone-icons/gen012.svg'
import {ReactComponent as Gen014} from '../../../assets/svg/duotone-icons/gen014.svg'
import {ReactComponent as Gra004} from '../../../assets/svg/duotone-icons/gra004.svg'
import {ReactComponent as Gen013} from '../../../assets/svg/duotone-icons/gen013.svg'
import {ReactComponent as Gen007} from '../../../assets/svg/duotone-icons/gen007.svg'

const Synagogues: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <HalfPageHeroImage image='/images/1920x800/JC-CS-Header.jpg' heading='The Jewish Community' paragraph='Providing custom solutions for' headingBelow={true}/>
            <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <div className="mb-5 mb-md-10">
                        <h1 className="display-6">More Than Access Control for Synagogues</h1>
                        <p className="lead">Working closely with the Jewish community allowed the team at Hartmann to develop a suite of solutions that enables users to do more than secure their building. Our team has created several custom action plans that take care of additional challenges the users were facing like; securing doors automatically on holidays and much more! </p>
                    </div>
                </div>

                <div className="row gx-3">

                    <div className="w-100"></div>

                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - Jewish Community 1.jpg')`, height: "15rem" }}></div>
                    </div>
                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - Jewish Community 2.jpg')`, height: "15rem" }}></div>
                    </div>
                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - Jewish Community 3.jpg')`, height: "15rem" }}></div>
                    </div>
                </div>
            </div>
     
            <div className="container content-space-1">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2 className="h1">Key Benefits</h2>
                    <p>Using Hartmann’s Access Control in Places of Worship.</p>
                </div>

                <div className="row gx-3 mb-5 mb-md-9">
                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/prevent-access.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Prevent unauthorized access</span>
                                <div className="card-text lh-base">By implementing access control, you can prevent unauthorized users from accessing sensitive information or resources.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/tracking.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Auditing and tracking</span>
                                <div className="card-text lh-base">Receive detailed logs of who accesses what resources and when. It can also be useful for auditing and tracking purposes.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/safety.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Community safety</span>
                                <div className="card-text lh-base">Access control provides a safer environment. This can be particularly important for properties that serve or house vulnerable populations.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/efficiency.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Improved efficiency</span>
                                <div className="card-text lh-base">Having authorized access can improve the overall efficiency of your place of worship, as it reduces the need for security personnel to manually check IDs or monitor entry points.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <SectionPopOutWithParagraphs backgroundPattern={wavepattern}
                sectionHeading={"Hartmann is making buildings in the Jewish community safer and easier to manage."} sectionSubHeading={""}
                paragraphs={
                    [   
                        
                        {
                            icon: <Gra004/>,
                            heading: 'Lockdown and Crisis Levels',
                            paragraph: `The Lockdown feature provides a quick and efficient way to secure a facility in case of a security breach or emergency situation. When a lockdown is activated, all doors controlled by Hartmann’s access control system can be locked, preventing anyone from entering the affected areas except for authorized users based on their crisis-level permissions set by the administrator.`,

                        },
                        {
                            icon: <Gen013/>,
                            heading: "Flexible Schedules with Door OTR (One Time Run)",
                            paragraph: `Using Door OTRs allows for different schedules to be assigned to different areas of a facility for specified durations without affecting the main schedule, ensuring security and optimizing the flow of people and resources in a facility. It also allows for adjustments to be made as needed for special events or temporary access.`,

                        }, 
                        {
                            icon: <Gen007/>,
                            heading: 'Notifications',
                            paragraph: `Alert notifications are essential and ensure no notifications are missed. They provide real-time information from late employees to notify administrators of doors forced or held open. One or more administrators will then be able to respond and acknowledge the event, ensuring the system is always operational.`,

                        },
                        {
                            icon: <Gen012/>,
                            heading: 'Support for Sabbath/Shabbat',
                            paragraph: `The abillity to have doors scheduled based around the sunset time on Friday. This allows Synagogues to have doors automatically unlock and lock for their Friday service.`,

                        },
                        {
                            icon: <Gen014/>,
                            heading: 'Hebrew Holiday Support',
                            paragraph: `Built in support for holdiays from the Hebrew calendar. Set it once and it will automatically update anually.`,

                        },
                       
                    ]
                } />
            {/* <ImageSideBySideWithText
                alignChild='right'
                image={"/images/600x400/600x400 - Jewish holidays.jpg"}
            >
                <>
                    <div className="mb-5">
                        <h2 className="mb-3">Jewish Holiday Case Study</h2>
                        <p>Read how Hartmann has created a script to keep exterior doors secured on major Jewish Holidays.</p>
                    </div>

                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                        <li className="list-checked-item">One time configuration</li>
                        <li className="list-checked-item">Fully Automated</li>
                        <li className="list-checked-item">Continiously improved</li>
                    </ul>
                </>
            </ImageSideBySideWithText>
            <ImageSideBySideWithText
                alignChild='left'
                image={"/images/600x400/600x400 - Synagogue.jpg"}
            >
                <>
                    <div className="mb-5">
                        <h2 className="mb-3">Shabbat Case Study</h2>
                        <p>Read how the Hartmann team created a script to create easier access when a Synagogue observes Shabbat.</p>
                    </div>

                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                        <li className="list-checked-item">Fully Automated</li>
                        <li className="list-checked-item">DTZ Modifications</li>
                        <li className="list-checked-item">Resets Weekly</li>
                    </ul>
                </>
            </ImageSideBySideWithText> */}
           </>
    )
}

export default Synagogues;