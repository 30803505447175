import React from 'react';
import HeroImageWithText from '../../../components/heroImageWithText';
import FullPageHeroImage from '../../../components/fullPageHeroImage';
import ImageSideBySideWithText from '../../../components/imageSideBySideWithText';
import VerticalNavTabs from '../../../components/verticalNavTabs';
import HeroImageForApps from '../../../components/heroImageForApps';
import AperioSvg from '../../../components/SVG/AperioSvg';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import SoftwareComponent1 from '../../../components/SoftwareComponent1';
import ContactModal from '../../../components/contact/contact form/ContactModal'
import { Link } from 'react-router-dom';

const ProtectorNetSoftware: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>

            {/* <FullPageHeroImage heading='PROTECTOR.Net' paragraph='More Than Access Control' image='/images/1920x1080/img1.jpg' />
             */}

            <div className="bg-img-start" style={{ backgroundImage: "url('/images/protector-software-hero.jpg')" }}>
                <div className="container content-space-2 content-space-lg-3">
                    <div className="w-md-65 w-lg-40">
                        <div className="mb-2">
                            <h2 className= "h1 text-white"> {"PROTECTOR.Net Software"} </h2>
                            <p className= "text-white mb-2"> All-In-One Access Control Solution.</p>
                        </div>
                            <Link className="btn btn-light btn-transition rounded-pill" to="/resources/softwaredownloads">Download PROTECTOR.Net</Link>

                            <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent m-2" onClick={()=>setModalShow(true)}>Request Demo</div>
                                <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                                
                            />
                            
                    </div>
                </div>
            </div>
           
            <div className="shape-container content-space-t-2">
                <div className="container">
                    <div className="w-md-75 text-center mx-md-auto mb-5 mb-md-9">
                        <h2 className="h1">Next Generation Access Control</h2>
                        <p>The most customizable access control system on the market.</p>
                    </div>

                    <div className="devices">
                        <figure className="device-mobile rotated-3d-right">
                            <div className="device-mobile-frame">
                                <img className="device-mobile-img" src="/images/407x867/protectornetApp.jpg" alt="Image Description" />
                            </div>
                        </figure>

                        <figure className="device-browser">
                            <div className="device-browser-header">
                                <div className="device-browser-header-btn-list">
                                    <span className="device-browser-header-btn-list-btn"></span>
                                    <span className="device-browser-header-btn-list-btn"></span>
                                    <span className="device-browser-header-btn-list-btn"></span>
                                </div>
                                <div className="device-browser-header-browser-bar">https://protectornet:11001/</div>
                            </div>

                            <div className="device-browser-frame">
                                <img className="device-browser-img" src="/images/1618x1010/homepage.jpg" alt="Image Description" />
                            </div>
                        </figure>
                    </div>
                </div>
                <div className="shape shape-bottom zi-3">
                    <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 500H3000V0L0 500Z" fill="#fff" />
                    </svg>
                </div>
            </div>

            {/* <HeroImageForApps header='PROTECTOR.Net' paragraph='Open all the doors to your favorite places.' image='/images/900x900/900x900-protectornet - light.png'/> */}
            <div className="container ">
                <div className="container ">

                    {/* <div className="w-lg-75 text-center mx-lg-auto mb-9">
                        <h1 className="display-5 mb-4">Next Generation Access Control</h1>
                        <p className="lead">Bring your idea to life in no time. The website solution for all your needs. For UX designers, entrepreneurs, product managers, marketers, and anyone.</p>
                    </div> */}
                    {/* <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <span className="text-cap">Anywhere, Anytime</span>
                        <h2>Next generation IP Access Control</h2>
                    </div> */}

                    <VerticalNavTabs tabOnLeft={true} tabs={[
                        {
                            heading: "Modern",
                            paragraph: "Intuitive and easy-to-use interface",
                            image: <div>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item my-5" key={nanoid()}>Modern responsive HTML5 interface adapts to best suit the device being used to access PROTECTOR.Net. This includes tablets, phones, notebooks or traditional desktop computers.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>100% web-based access control solution.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Intuitive easy-to-use interface.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Enhanced security via SSL.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>A simplified installer takes care of all the required components and dependencies.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Effortlessly view the status of your entire system. As well as override doors, floors, inputs and outputs quickly.</li>
                                </ul>
                            </div>
                        },
                        {
                            heading: "Powerful",
                            paragraph: "Unlimited users, doors and more.",
                            image: <div>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Users.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Door Time Zones.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Partitions.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Doors.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Administrators.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited Access Groups.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>One-time-run time zones (Great for booking ahead for party rooms or move-in rooms in condos).</li>
                                </ul>
                            </div>
                        },
                        {
                            heading: "Secure",
                            paragraph: "Enhanced security via SSL.",
                            image: <div>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item my-5" key={nanoid()}>First Card In time zone activated by selectable users.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Dual custody access with supervisor option.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Unlimited administrative accounts with fine-grained per partition access rules.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Invalid password attempt lock-out feature prevents brute force attacks.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Supports integration into alarm systems.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Man-trap and local anti-passback support.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>AES 256 encryption between server and controllers.</li>
                                </ul>
                            </div>
                        },
                        {
                            heading: "Admin",
                            paragraph: "Advanced system administration.",
                            image: <div>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item my-5" key={nanoid()}>Administrative accountability via detailed historical activity reporting.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Per partition administrative privileges.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Automatic panel update procedure.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Simple/Advanced Edit modes for records allow quick access to edit complex settings for more advanced users or simple guided edits for general users.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Add as many user fields as required, i.e. parking number, employee number, or phone number.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Override - Panels, Doors, Auxiliary relays, Inputs/Outputs, Elevators. Easy screens for overriding all field devices on the system.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Allow certain users to perform various actions by triple swiping at the keypad and entering a code (up to 5 different actions can be programmed).</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Integrated backup/restore utility.</li>
                                </ul>
                            </div>
                        },
                        {
                            heading: "Reporting",
                            paragraph: "Detailed reports of the system.",
                            image: <div>
                                <ul className="list-checked list-checked-primary">
                                    <li className="list-checked-item my-5" key={nanoid()}>Detailed Reporting of User, Door, Floor, Input and Output activity.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Area-based muster reporting.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Detailed administration activity reporting.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Detailed system configuration report.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Live monitoring window with support for configurable notification types, cardholder pictures and video integration.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>The report can be exported to CSV or HTML.</li>
                                    <li className="list-checked-item my-5" key={nanoid()}>Flexible transaction emailing.</li>
                                </ul>
                            </div>
                        },
                    ]} />



                </div>
                <div className="content-space-t-2">
                    <div className="container">
                        <div className="bg-light p-4 p-sm-10">
                            <div className="w-md-75 text-center mx-md-auto mb-5 mb-md-9">
                                <h2>ACE Solutions</h2>
                                <p className="fs-4">Our unique ACE solution provides the tools you need to resolve real-world problems for your clients. Here are some of the creative solutions our dealers and support team have created. Click below to see a few scenarios.</p>
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewSolutionModal">View Solutions</button>
                            </div>
                            <div className="modal fade" id="viewSolutionModal" tabIndex={-1} role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-close">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="container content-space-1 content-space-lg-1">
                                                <div className="w-lg-85 mx-lg-auto">
                                                    <div className="accordion accordion-flush accordion-xl" id="accordionFAQ">
                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousOne">
                                                                <a className="accordion-button" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Track and report employee truancy
                                                                </a>
                                                            </div>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingCuriousOne" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    The ProtectorNet access control system was able to utilize its server-side scripting engine (ACE) to email and text Ted a short email every morning at 9:30 am with a list of employees who have not entered the building that day.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousTwo">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    Card counting and limiting
                                                                </a>
                                                            </div>
                                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingCuriousTwo" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that will use a custom attribute on each user. This attribute will represent how many times that card is allowed to be used each day. Every time one of those cards is granted access to a reader, the server will decrement that custom attribute by one. Once it reaches zero, the credential is instantly disabled and the card will be denied access. The count can be reset at a specific time each day.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousThree">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    Special action card to unlock communal rooms
                                                                </a>
                                                            </div>
                                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingCuriousThree" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that will allow a set of designated cards to be used in the community rooms. When a community card is presented, it will unlock the door for a configurable amount of time. Can be customized even further based on any specific needs.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousFour">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    Alternate door schedule weekly
                                                                </a>
                                                            </div>
                                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingCuriousFour" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that would run every Sunday morning to switch the door schedule on the factory doors to match the shift changes each week without user intervention.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousFive">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                    Public to secure on sunrise and sunset
                                                                </a>
                                                            </div>
                                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingCuriousFive" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a third-party service on the internet could be calculated to give back the exact time of sunrise and sunset each week. The script would then modify the existing door schedule whenever these times we’re updated, removing the need for manually changing schedules.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousSix">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                    Set all doors to public on the first credential in
                                                                </a>
                                                            </div>
                                                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingCuriousSix" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a set of rules can be implemented so that once the manager or owner swipes at any door in the morning, all the public doors will go from locked to unlocked. This saves time and is convenient.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <img className="img-fluid rounded-2" src={"/images/ProtectorNetsoftware/ACE solutions.png"} alt="Image Description" />
                        </div>
                    </div>
                </div>

                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/ProtectorNetSoftware/ldapintegration.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">LDAP Integration</h2>
                        <p>PROTECTOR.Net seamlessly integrates with LDAP providers such as Active Directory (AD) to simplify user management. With the ability to import and automatically synchronize AD users based on security group membership, administrators can easily grant or revoke permissions to doors and floors. Additionally, access to doors and floors can be automatically revoked when AD users are disabled or expired. PROTECTOR.Net also offers the option to import user credentials directly from AD User Attribute Fields, streamlining the process of credential management.</p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='right'
                    image={"/images/ProtectorNetSoftware/partitions.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Powerful Partitioning</h2>
                        <p>Our powerful partitioning system facilitates administrative segregation. This provides the ability to have an administrator in a single building log in and only see their building; while a full administrator would see all buildings at the same time.</p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/ProtectorNetSoftware/crisislevels.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Customizable Crisis Levels</h2>
                        <p>Customizable Crisis levels can be initiated from almost any condition such as triple swipe, input, special card, or mobile app. Each crisis level can restrict what type of users can go through the door and change the mode of the door such as making a public door into a door that needs a card and a PIN.</p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='right'
                    image={"/images/ProtectorNetSoftware/activemaps.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Active Maps</h2>
                        <p>Our Active Maps allow the integrator or client to import a blueprint of their building then drag and drop areas, doors, inputs, outputs or cameras etc. This interface has an active meaning that if a door is forced open it will change status on our maps page. We also give users the ability to override/resume doors, run Action Plans and even view cameras associated with doors. You can keep track of all users by seeing what location they are in.</p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/ProtectorNetSoftware/timezone.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Unlimited Doors, Users, Timezones and Administrators</h2>
                        <p>PROTECTOR.Net is capable of supporting an unlimited number of doors, users, time zones, access groups, and administrators, making it suitable for any sized facility. This means that as your organization grows, you can easily scale up your access control system without any limitations or constraints. Additionally, the software allows for multiple administrators to manage the system, giving your organization the flexibility to assign different levels of access to different individuals depending on their roles and responsibilities.</p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='right'
                    image={"/images/ProtectorNetSoftware/buildingsegregation.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Building Segregation</h2>
                        <p>Our powerful partitioning system allows you to manage multiple buildings from a single deployment. This means that you can create separate partitions for each building or location, and set access permissions for each partition as needed. This makes it easy to manage access for multiple buildings without having to set up and maintain separate systems for each one. </p>
                    </div>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/ProtectorNetSoftware/notificationfiltering.jpg"}
                    isOdyssey={false}
                >
                    <div className="mb-5">
                        <h2 className="mb-3">Notification Filtering</h2>
                        <p>PROTECTOR.Net provides a rule-based notification filtering and alert management system that allows integrators and clients to customize how they receive and manage notifications. Users can define rules to filter and prioritize notifications based on factors such as event type, door or floor, user group, or time of day. This system helps users to avoid unnecessary or redundant notifications and to focus on the most important ones. The system also allows users to style notifications with colours, pictures, and sounds to provide quick visual and auditory cues about the nature of the notification.</p>
                    </div>
                </ImageSideBySideWithText>

               <SoftwareComponent1 /> 
            </div>



            
            <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9 container content-space-t-2">
                <h2 className="h1">{t('package.Software Packages')}</h2>
                <p>{t('package.Package Description')}</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-lg-9">
                    <div className="table-responsive-lg  w-lg-75 mx-lg-auto" >
                        <table className="table table-lg table-striped table-bordered table-nowrap table-vertical-border-striped">
                            <thead className="table-text-center">
                                <tr>
                                    <th scope="col" style={{ width: '40%' }}></th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Essentials')}</span>
                                        {/* <span className="d-block text-muted small">$0/mon</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Small')} {/*<span className="badge bg-warning text-dark rounded-pill ms-1">Hot</span>*/}</span>
                                        {/* <span className="d-block text-muted small">$60/mon</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Mid')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Large')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Enterprise')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Doors')}</th>
                                    <td className="table-text-center">40</td>
                                    <td className="table-text-center">80</td>
                                    <td className="table-text-center">120</td>
                                    <td className="table-text-center">240</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Cameras')}</th>
                                    <td className="table-text-center">40</td>
                                    <td className="table-text-center">80</td>
                                    <td className="table-text-center">120</td>
                                    <td className="table-text-center">240</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Partitions')}</th>
                                    <td className="table-text-center">1</td>
                                    <td className="table-text-center">5</td>
                                    <td className="table-text-center">20</td>
                                    <td className="table-text-center">40</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Elevators')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.LDAP')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Unmanaged Doors')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProtectorNetSoftware;