import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullPageHeroImage from '../../../components/fullPageHeroImage';
import HeroImageForApps from '../../../components/heroImageForApps';

const HCAdmin: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [toggler, setToggler] = useState(false);

    React.useEffect(() => {
        document.title = "HC Admin Mobile - Hartmann";
    });

    return (
        <>
            <HeroImageForApps header='HC-Admin' paragraph='Administer your buildings from any device.' image='/images/900x900/900x900-hcadmin.png' />            <div className="container fluid no-padding">

                <div className="overflow-hidden">
                    <div className="container content-space-2 content-space-t-lg-3">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>Optimized for remote management</h2>
                                    <p>Remotely access and manage your buildings security from any mobile device. Issue credentials, revoke access, view recent alerts and notifications. Monitor all of your sites, locations and users from one app.</p>
                                </div>


                                {/* <div className="w-md-50 w-lg-75 mb-2">
                                    <div className="bg-img-start text-center rounded-2 py-10 px-5" style={{ backgroundImage: "url('/images/480x320/img18.jpg')" }}>
                                        <a className="video-player video-player-btn" role="button" onClick={() => setToggler(!toggler)}>
                                            <span className="d-flex justify-content-center align-items-center">
                                                <span className="video-player-icon shadow-sm">
                                                    <i className="bi-play-fill"></i>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <FsLightbox
                                        sources={['https://www.youtube.com/watch?v=d4eDWc8g0e0']}
                                        toggler={toggler}
                                    />
                                </div> */}

                                {/* <p className="small">Checkout how to setup and use the app.</p> */}
                            </div>

                            <div className="col-lg-6">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <figure className="device-mobile mx-auto">
                                        <div className="device-mobile-frame">
                                            <img className="device-mobile-img" src="/images/mobile/mobile1.png" alt="Image Description" />
                                        </div>
                                    </figure>

                                    <div className="position-absolute top-50 start-50 translate-middle zi-n1" style={{ width: "25rem" }}>
                                        <img className="img-fluid" src="/images/svg/components/shape-2.svg" alt="SVG" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>Simple to configure</h2>
                                    <p>Scan the QR code on your administrator settings page from the app and you are all set! </p>
                                </div>

                                <h4 className="mb-3">Get our mobile app</h4>

                                <div className="d-flex gap-3">
                                    <a className="btn btn-dark btn-icon rounded-circle" href="https://apps.apple.com/us/app/protector-net-mobile/id1470461775?ls=1">
                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M11.76,6.69a.52.52,0,0,1-.59-.52,4.37,4.37,0,0,1,1-2.61A4.82,4.82,0,0,1,14.64,2a.51.51,0,0,1,.63.51,4.66,4.66,0,0,1-1,2.63A4.07,4.07,0,0,1,11.76,6.69Zm5.42,5.82c0-2.72,2-3.33,2-3.92s-1.73-1.91-3.56-1.91-2.54.86-3.78.86-2.25-.86-3.79-.86A5.16,5.16,0,0,0,3.89,9.21,6.64,6.64,0,0,0,3,12.75C3,17.14,6.15,22,8.47,22c1.3,0,1.63-.85,3.42-.85S14,22,15.22,22c2.52,0,4.62-5,4.62-5.07a.51.51,0,0,0-.3-.46A4.44,4.44,0,0,1,17.18,12.51Z" />
                                        </svg>
                                    </a>

                                    <a className="btn btn-dark btn-icon rounded-circle" href="https://play.google.com/store/apps/details?id=com.pnetadmin">
                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M16.6,15.7l5-2.9c0.2-0.1,0.4-0.4,0.4-0.6v-0.3c0-0.3-0.1-0.5-0.4-0.6l-5-2.9L13.3,12L16.6,15.7z M12.5,11.1l3-3.4L4.4,1.1
                C4.2,1,4,1,3.8,1.1L12.5,11.1z M12.5,13L3.8,23c0.2,0.1,0.4,0.1,0.6,0l11.1-6.5L12.5,13z M3,2.1v19.8l8.7-9.9L3,2.1z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6 order-lg-1">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <figure className="device-mobile mx-auto">
                                        <div className="device-mobile-frame">
                                            <img className="device-mobile-img" src="/images/mobile/mobile3.png" alt="Image Description" />
                                        </div>
                                    </figure>

                                    <div className="position-absolute top-50 start-50 translate-middle zi-n1" style={{ width: "25rem" }}>
                                        <img className="img-fluid" src="/images/svg/components/shape-1.svg" alt="SVG" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                <div className="mb-4">
                                    <h2>Power at your fingertips</h2>
                                    <p>The PROTECTOR.Net Mobile App on Android and iOS allows for easy access to your PROTECTOR.Net to see status of the
                                        system and perform actions such as:</p>
                                </div>

                                <ul className="list-checked list-checked-soft-bg-dark list-checked-lg">
                                    <li className="list-checked-item">View real time status of Doors, Floors, Inputs and Outputs</li>
                                    <li className="list-checked-item">Override Doors, Floors, Inputs and Outputs</li>
                                    <li className="list-checked-item">Add and edit users</li>
                                    <li className="list-checked-item">Pulse Unlock Doors</li>
                                    <li className="list-checked-item">View recent notifications</li>
                                    <li className="list-checked-item">Execute Action Plans</li>


                                </ul>
                            </div>

                            <div className="col-lg-6">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <figure className="device-mobile mx-auto">
                                        <div className="device-mobile-frame">
                                            <img className="device-mobile-img" src="/images/mobile/mobile3.png" alt="Image Description" />
                                        </div>
                                    </figure>

                                    <div className="position-absolute top-50 start-50 translate-middle zi-n1" style={{ width: "25rem" }}>
                                        <img className="img-fluid" src="/images/svg/components/shape-4.svg" alt="SVG" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default HCAdmin;