import React, { Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ProductCard } from '../../../Types'

type Props = {
    product: ProductCard
}

export default function ProductItemCard(props: Props): ReactElement {
    return (
        <div className="col mb-3">
            {/* <!-- Card --> */}
            <Link to={`/products/${props.product.partName}`}>
                <div className="card card-flush shadow-none h-100">
                    <div className="card-pinned" data-fslightbox="propertyGridGallery7">
                        <img className="card-img" src={props.product.images[0]} alt="Image Description" />

                        {
                            props.product.partName == "HC ATOM"
                                ? <div className="card-pinned-top-start">
                                    <span className="badge bg-success">New</span>
                                </div>
                                : <Fragment />
                        }

                    </div>

                    <div className="card-body" >


                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <h4 className="card-title text-inherit text-center">{props.product.partName}</h4>
                            </div>
                        </div>
                        <span className="card-subtitle text-body text-center" style={{ textTransform: 'none' }}>{props.product.shortDesc}</span>


                    </div>
                </div>
            </Link>


        </div>

    )
}