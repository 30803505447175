import React, { Component } from 'react'
import { useTranslation } from 'react-i18next';

export type Props = {
    title: string,
    text: string,
    img: string,
    href: string,
    modal: (val:boolean,set:React.Dispatch<React.SetStateAction<boolean>>)=>JSX.Element
}

export default function ContactCard(props: Props) {
    const [modalShow, setModalShow] = React.useState(false);
    const { t, i18n } = useTranslation();
    return (
        <div className='card card-sm card-transition h-100'>
            <img className="card-img-top" src={`${props.img}`} alt='' height={200}/>
            <div className='card-body' style={{height:200}}>
                <h3 className='card-title'>{t("contact."+props.title)}</h3>
                <p className='card-text text-body'>{t("contact."+props.text)}</p>
            </div>

            <div className='card-footer pt-0'>
                    <div style={{color:"#377dff",cursor:'pointer'}} onClick={() => setModalShow(true)}>
                    {t('contact.contact')} <i className="bi-chevron-right small ms-1"></i>
                    </div>
            </div>
            {
                props.modal(modalShow,setModalShow)
            }
        </div>
    )
}