import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next'
import websiteEN from './assets/locales/en/website.json'
import websiteES from './assets/locales/es/website.json'
import websiteFR from './assets/locales/fr/website.json'
import portalEN from './assets/locales/en/portal.json'
import portalES from './assets/locales/es/portal.json'
import portalFR from './assets/locales/fr/portal.json'


//@ts-ignore
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                website:websiteEN,
                portal:portalEN
            },
            es: {
                website:websiteES,
                portal:portalES
            },
            fr: {
                website:websiteFR,
                portal:portalFR
            }
        },
        /* default language when load the website in browser */
        lng: "en-US",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en-US",
        /* debugger For Development environment */
        debug: true,
        /*websit and portal namespcae, whcih means website.json and portal.json files*/
        ns: ["website","portal"],
        defaultNS: "website",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default'
        }
    })

export default i18n;