import FsLightbox from 'fslightbox-react';
import React, { Fragment, useState } from 'react'
import { ProductItem } from '../Types';

type Props = {
    data: ProductItem
}

export default function MultiImageLightbox(props: Props) {
    const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(0);
    //const [singleImg, setSingleImg] = useState<boolean>(props.data.images.length == 1);
    function setLightboxAndIndex(event: any, index: number) {
        event.preventDefault();
        setToggler(!toggler)
        setProductIndex(index);
        return;
    }

    let fullSizeImages: string[];
    fullSizeImages = [];

    props.data.Images?.map((image, index) => {
        if (index > 5 || index & 1) {
            fullSizeImages.push(image)
        }
    })

    return (
        <React.Fragment>
            <FsLightbox
                toggler={toggler}
                key={productIndex}
                sources={fullSizeImages}
            />
            <div className="container mb-5">
                <div className="rounded-2 overflow-hidden">
                    <div className="row gx-2">
                        {
                            //singleImg
                            // ? <Fragment>
                            //     <div className='col-md-3' />
                            //     <div className="col-md-6">
                            //         <a className="d-block position-relative" onClick={(event: any) => setLightboxAndIndex(event, 1)} href="#">
                            //             <img className="img-fluid w-100" src={props.data.images[0]} alt="Image Description"
                            //                 style={{ borderRadius: "0px", borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} />

                            //             <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                            //                 <span className="d-md-none btn btn-sm btn-light">
                            //                     <i className="bi-arrows-fullscreen me-2"></i> View photos
                            //                 </span>
                            //             </div>
                            //         </a>
                            //     </div>
                            //     <div className='col-md-3' />
                            // </Fragment>
                            // : 
                            <Fragment>
                                <div className="col-md-8">
                                    <div className="d-block position-relative">
                                        <img className="img-fluid w-100" src={props.data.Images[0]} alt="Image Description"
                                            style={{ borderRadius: "0px", borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" ,cursor:'pointer'}} />

                                        <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                                            <span className="d-md-none btn btn-sm btn-light">
                                                <i className="bi-arrows-fullscreen me-2"></i> View photos
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-4 d-none d-md-inline-block">
                                        <a className="d-block mb-2" onClick={(event: any) => setLightboxAndIndex(event, 2)} href="#">
                                            <img className="img-fluid w-100" src={props.data.images[0]} alt="Image Description" style={{ borderTopRightRadius: "8px" }} />
                                        </a>
                                        <a className="d-block position-relative" onClick={(event: any) => setLightboxAndIndex(event, 3)} href="#">
                                            <img className="img-fluid w-100" src={props.data.images[1]} alt="Image Description" style={{ borderBottomRightRadius: "8px" }} />

                                            <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                                                <span className="d-none d-md-inline-block btn btn-sm btn-light">
                                                    <i className="bi-arrows-fullscreen me-2"></i> View photos
                                                </span>
                                            </div>
                                        </a>
                                        <a className="d-none" onClick={(event: any) => setLightboxAndIndex(event, 4)} href="#"></a>
                                        <a className="d-none" onClick={(event: any) => setLightboxAndIndex(event, 5)} href="#"></a>
                                    </div> */}
                            </Fragment>
                        }
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}