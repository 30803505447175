import React from 'react';
import ReactMarkdown from 'react-markdown';
import FullPageHeroImage from '../../../components/fullPageHeroImage';
import NavTabsHorizontal from '../../../components/navTabsHorizontal';
import ProductData from '../../../data/productData';
import { ReactComponent as Abs028 } from '../../../assets/svg/duotone-icons/abs028.svg'
import ScrollingCards from '../../../components/scrollingCards';
import AperioSvg from '../../../components/SVG/AperioSvg';
import VerticalNavTabs from '../../../components/verticalNavTabs';
import HorizonalNavTabs from '../../../components/horizontalNavTabs';
import ContentSections15 from '../../../components/ContentSections15';
import { useTranslation } from 'react-i18next';
import HorizontalNavTabs from '../../../components/horizontalNavTabs';
import Pricing2 from '../../../components/Pricing2';
import { Link, useParams } from 'react-router-dom';
import ContactModal from '../../../components/contact/contact form/ContactModal'

const OdysseyHardware: React.FC = () => {
    const { t, i18n } = useTranslation();
    let { productName } = useParams();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            {/* <FullPageHeroImage heading='ODYSSEY Hardware' paragraph='Enterprise Access Control' image='/images/1920x1080/img1.jpg' /> */}
        {/* <div className= "mb-4" />
            <div className="bg-img-start" style={{ backgroundImage: "url('/images/Odyssey-software-hero-2.jpg')" }}>
                <div className="container content-space-5 content-space-lg-3">
                    <div className="w-md-65 w-lg-40">
                        <div className="mb-2">
                            <h2 className="h1 text-white">{`${productName?.toUpperCase()} Hardware`}</h2>
                            <p className= "text-white mb-2"> Robust, durable and flexible access control hardware for our enterprise-level system.</p>
                        </div>
                            <a className="btn btn-light btn-transition rounded-pill" href="/products">All Products</a>

                            <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent m-2" onClick={()=>setModalShow(true)}>Request Demo</div>
                                <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                                     
                            />    
                    </div>
                </div>
            </div> */}

{productName === "protectornet" &&
<>
        <div className="bg-img-start" style={{ backgroundImage: "url('/images/protector-software-hero.jpg')" }}>
            <div className="container content-space-5 content-space-lg-3">
                <div className="w-md-65 w-lg-40">
                    <div className="mb-2">
                        <h2 className="h1 text-white">{`${productName?.toUpperCase()} Hardware`}</h2>
                        <p className= "text-white mb-2"> Robust, durable and flexible access control hardware for our streamlined access control system.</p>
                    </div>
                        <Link className="btn btn-light btn-transition rounded-pill" to="/products">All Products</Link>

                        <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent m-2" onClick={()=>setModalShow(true)}>Request Demo</div>
                            <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                                 
                        />    
                </div>
            </div>
        </div>
        </>
      }

{productName === "odyssey" &&
        <>
        <div className="bg-img-start" style={{ backgroundImage: "url('/images/Odyssey-software-hero-2.jpg')" }}>
            <div className="container content-space-5 content-space-lg-3">
                <div className="w-md-65 w-lg-40">
                    <div className="mb-2">
                        <h2 className="h1 text-white">{`${productName?.toUpperCase()} Hardware`}</h2>
                        <p className= "text-white mb-2"> Robust, durable and flexible access control hardware for our enterprise-level system.</p>
                    </div>
                        <Link className="btn btn-light btn-transition rounded-pill" to="/products">All Products</Link>

                        <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent m-2" onClick={()=>setModalShow(true)}>Request Demo</div>
                            <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                                 
                        />    
                </div>
            </div>
        </div>
        </>
      }

   
            <div className="container content-space-2 content-space-lg-3">

                <NavTabsHorizontal tab={['Controllers', 'Prox Readers', 'Bluetooth Readers', 'HC-Atom']}
                    pages={[
                        <React.Fragment >
                            <div className="container  ">
                                <div className="row justify-content-lg-between">
                                    <div className="col-lg-6 mb-5 mb-lg-0" >
                                        <img src="/images/575x450/controller-575x450-JPG.jpg" width="100%"/>
                                    </div>

                                    <div className="col-lg-5">
                                        <p>Our PoE controllers have been designed to minimize installation time and cost. The Above-The-Door design eliminates the need to have excessive access cabling and instead utilizes one CAT5 cable to power every component at the door. This coupled with the LCD, and onboard diagnostics ensures that one technician can troubleshoot all the hardware themself. These controllers also come with the option of a built-in motion sensor.</p>
                                        <p>Our PRS line of controllers are the perfect controllers for applications when traditional access cabling has already been installed. These controllers also offer more input and output flexibility and come in 2-8 door or 2-64 floor kits.</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>,
                        <React.Fragment>
                            <div className="container">
                                <div className="row justify-content-lg-between">
                                    <div className="col-lg-6 mb-5 mb-lg-0">
                                        <img src="/images/575x450/readers-575x450.jpg" width="100%"/>
                                    </div>

                                    <div className="col-lg-5">
                                        <p>Proximity readers are devices used in access control systems to authenticate individuals and grant access to a facility.</p>
                                        <p>Hartmann offers single gang and mullion mount proximity readers suitable for any application. They are often used in facilities such as office buildings, schools, or residential properties. In most cases, they work by reading Hartmann 40-bit credentials, HID, AWID, and Pyramid proximity cards or key fobs that are brought within close range of the reader. The reader then sends a signal to Odyssey to grant or deny access based on the user’s permissions.</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>,
                        <React.Fragment>
                            <div className="container">
                                <div className="row justify-content-lg-between">
                                    <div className="col-lg-6 mb-5 mb-lg-0">
                                        <img src="/images/575x450/bluetooth-575x450.jpg" width="100%"/>
                                    </div>

                                    <div className="col-lg-5">
                                        <p>Bluetooth readers are devices used in access control systems that use Bluetooth to grant access to a facility.</p>
                                        <p>Hartmann offers a full line of readers that use Bluetooth and NFC technology for communication. These readers connect to our controllers using a standard Wiegand connection and work by connecting wirelessly to Bluetooth-enabled devices. The reader then sends a signal to Odyssey to grant or deny access based on the user’s permissions. Bluetooth readers are becoming increasingly popular as a convenient and secure alternative to traditional key-based access control systems.</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>,
                        <React.Fragment>
                            <div className="container">
                                <div className="row justify-content-lg-between">
                                    <div className="col-lg-6 mb-5 mb-lg-0">
                                        <img src="/images/575x450/hc-atom-575x450.jpg" width="100%"/>
                                    </div>

                                    <div className="col-lg-5">
                                        <p>HC Atom is a powerful and versatile access control solution that provides a reliable and low-maintenance option for businesses with 40 doors or less. It's Linux-based and hosts Odyssey, a web-based management interface that makes it easy for you to backup, restore, and upgrade the system as needed. With its compact design, HC Atom is the perfect choice for businesses that want to keep their security systems on-premise and avoid the hassle of managing cloud-based solutions.</p>
                                        <p>HC Atom is designed to make managing your security system as easy as possible. The web-based management interface provides a user-friendly experience that's accessible from any device with an internet connection. This means you can manage your system from anywhere, at any time, without having to worry about technical issues or maintenance.</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>,
                        <React.Fragment>
                            <div className="devices">

                                <figure className="device-mobile rotated-3d-right">
                                    <div className="device-mobile-frame">
                                        <img className="device-mobile-img" src="/images/407x867/img1.jpg" width="100%"></img>
                                    </div>
                                </figure>

                                <figure className="device-browser">
                                    <div className="device-browser-header">
                                        <div className="device-browser-header-btn-list">
                                            <span className="device-browser-header-btn-list-btn"></span>
                                            <span className="device-browser-header-btn-list-btn"></span>
                                            <span className="device-browser-header-btn-list-btn"></span>
                                        </div>
                                        <div className="device-browser-header-browser-bar">www.htmlstream.com/front/</div>
                                    </div>

                                    <div className="device-browser-frame">
                                        <img className="device-browser-img" src="/images/1618x1010/img1.jpg" alt="Image Description"></img>
                                    </div>
                                </figure>

                            </div>
                        </React.Fragment>

                    ]} />
            </div>
            <ScrollingCards heading='Hartmann offers a variety of Controllers allowing for a flexible and cost effective install. With many different deployment options you can be assured of a hassle free installation.'
                subheading='Controller Lineup' cards={[
                    {
                        paragraph: "Designed to be easily scalable and ideal for retrofits. Available in two to eight-door configurations.",
                        image: "/images/ProductPics/PRS-2DR-STR.jpg",
                        link: "../page-customer-story.html",
                        title: "PRS Controller",
                    },
                    {
                        paragraph: "Above the door design, integrated motion sensor, on-board LCD screen with diagnostics and technician-friendly design",
                        image: "/images/ProductPics/POE-ODM-MB.jpg",
                        link: "../page-customer-story.html",
                        title: "PoE Controller",
                    },
                    {
                        paragraph: "Our I/O and elevator controllers can be used to control up to 64 floors actress 2 elevator cabs or 64 inputs and outputs.",
                        image: "/images/ProductPics/PRS-IO-STR.jpg",
                        link: "../page-customer-story.html",
                        title: "I/O & Elevator Controller",
                    }

                ]} seeMore='View All Products' seeMoreLink='/products' />





            <div className="container content-space-1">
                <HorizontalNavTabs tabs={[
                    {
                        heading: "PRS IO Expander",
                        paragraph: "Expand inputs, outputs, or floors.",
                        image: <ContentSections15
                            image="/images/ProductPics/PRS-IO-EXP8PCB.png"
                            description={`The PRS-IO is used to expand the number of inputs, outputs, or floors in a PRS-IO-STR or PRS-ELV-STR by 8. Communicating via RS485, this controller can be connected via interconnect strips or standard RS485 wiring. The PRS-IO Expander can be located up to 2000' from the master controller`}
                            bulletPoints={[
                                "The PRS-Master controller provides 12VDC power through interconnected strips.",
                                "8 Dry contact solid state relays per door SSR relays can switch up to 30VDC 0.5A.",
                                "8 Dry contact inputs per door, fully configurable inputs can be assigned to any door, allowing you to re-assign inputs for complex door setups.",
                                "Easy to set addressing via dip switches. Clear labeling of inputs, outputs and reader ports. LED indicators for input, output and connectivity."
                            ]} />
                    },
                    {
                        heading: "PRS TDM Expander",
                        paragraph: "Expand doors and readers.",
                        image: <ContentSections15
                            image="/images/ProductPics/PRS-TDM.png"
                            description={`The PRS-TDM (Two Door Module) is used to expand the door, reader, input, and output capacity on the PRS-2DR Starter. Up to 3 PRS TDMs can be added to a PRS-2DR Starter for a total of 8 doors. Communicating via RS485, this controller can be connected via interconnect strips or standard RS485 wiring.`}
                            bulletPoints={[
                                "The PRS-Master controller provides 12VDC power through interconnected strips. Can be externally powered.",
                                "1 Wet 12VDC 500mA relay per door, eliminating the need for external lock power supplies. Intelligent power control to reduce power consumption and reduce strain on solenoid-based locksets.",
                                "2 Dry contact solid state relays per door. SSR relays can switch up to 24VDC 1A. Outputs can be assigned to any door, allowing you to reassign relays for complex door setups.",
                                "3 Dry contact inputs per door, fully configurable. Inputs can be assigned to any door, allowing you to reassign inputs for complex door setups.",
                                "Easy to set addressing via dip switches. Clear labeling of inputs, outputs and reader ports. LED indicators for input, output and connectivity."
                            ]} />
                    },
                    {
                        heading: "PRS TDM Expander",
                        paragraph: "With mechanical relays.",
                        image: <ContentSections15
                            image="/images/ProductPics/PRS-TDM-MECH.jpg"
                            description={`The PRS-TDM-MECH (Two Door Module with mechanical relays) is used to expand the door/reader/input/output capacity on the PRS-2DR Starter. ideal for installs or retrofits where power supplies and wiring are pre-existing. Up to 4 can be added within a single PRS-Door controller for a maximum of 8 doors.`}
                            bulletPoints={[
                                "The PRS-Master provides 12VDC power throught interconnect strips. Can be externally powered.",
                                "4 Mechanical relays with N/O and N/C outputs, each capable of switching up to 5A, 30VDC or 35VAC.",
                                "2 Dry contact solid state relays, capable of switching 1A, 30VDC or 30VAC.",
                                "6 Dry contact inputs per door, fully configurable. Inputs can be assigned to any door, allowing you to reassign inputs for complex door setups.",
                                "Easy to set addressing via dip switches. Clear labeling of inputs, outputs and reader ports. LED indicators for input, output and connectivity."
                            ]} />
                    }
                ]} />

            </div>


            <div className="container content-space-t-2 content-space-b-2 content-space-b-lg-3">
                <div className="row align-items-md-center">
                    <div className="col-md-7 mb-7 mb-md-0">
                        <div className=" mx-sm-auto" data-aos="fade-up">
                            <img className="img-fluid rounded-top-2" src={"/images/Topology/ProtectorNetTopology1.png"}/>
                        </div>
                    </div>

                    <div className="col-md-5 order-md-2">
                        <div className="mb-5">
                            <h2 className="mb-3">Full Aperio Integration Topology</h2>
                        </div>
                        <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">Backs onto a Microsoft SQL database</li>
                            <li className="list-checked-item">SSL 128bit transport security ensures all web transmissions are kept confidential and your information protected.</li>
                            <li className="list-checked-item">Multi-threaded, asynchronous, service-based Communication Server allows for optimal use of system resources and increased scalability.</li>
                            <li className="list-checked-item">Connect field panels via TCP/IP (PoE) network allowing installation to use existing network infrastructure.</li>
                            <li className="list-checked-item">Fully distributed hardware meaning each controller runs independent of communication back to server making sure your credentials and doors operate as programmed regardless of your network state.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="row align-items-md-center">
                    <div className="col-md-7 order-md-2 mb-7 mb-md-0">
                        <div className="mx-sm-auto" data-aos="fade-up">
                            <img className="img-fluid rounded-top-2" src={"/images/Topology/ProtectorNetAperioTopology.png"}/>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="mb-5">
                            <h2 className="mb-3">Multi-Site with Aperio Integration</h2>
                            <p>We are partnered with ASSA Abloy in order to fully integrate PROTECTOR.Net and Odyssey with their Aperio family of devices. The HC-Aperio controller interfaces directly via Cat5e/6 cable to one or more Aperio Hubs; each hub can in turn interface wirelessly with up to 8 locking devices. </p>
                        </div>

                        <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">The HC-Aperio8 will support up to 8 locking devices via 1 to 4 Aperio hubs.</li>
                        </ul>
                        <p>From a programming perspective, the integration is seamless and administrators simply see each device as another access point on the system. By combining the Aperio system with the PROTECTOR.Net and Odyssey, our customers get the full benefit of all the PROTECTOR.Net and Odyssey features and performance while gaining extra flexibility in where and what devices can be controlled. </p>
                    </div>
                </div>
            </div>



            <Pricing2 />
            <div className="overflow-hidden">
                <div className="container content-space-2 content-space-lg-1">
                    <div className="container content-space-b-2">
                        <div className="text-center bg-img-start py-6" style={{ background: "url('/images/svg/components/shape-6.svg')"  }}>
                            <div className="mb-5">
                                <h2>Find the right hardware for your projects</h2>
                                <p>Detailed specifications on Hartmann's complete line of products.</p>
                            </div>

                            <Link className="btn btn-primary btn-transition" to="/products">Explore products</Link>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default OdysseyHardware;
