import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react'
import { PlaylistItemSnippet } from './ResponseType';

type Props = {
    source:PlaylistItemSnippet
}

export default function FsLightCard(props: Props) {
    const [toggler, setToggler] = useState(false);
    return (
        <div className="col mb-5 col-sm-4">
            <div className="card text-center h-100" >
                <div className="bg-img-start text-center rounded-top py-10 px-5" style={{ backgroundImage: `url(${props.source.thumbnails.medium?.url})` }}>
                    <a className="video-player video-player-btn" role="button" onClick={() => setToggler(!toggler)}>
                        <span className="d-flex justify-content-center align-items-center">
                            <span className="video-player-icon shadow-sm">
                                <i className="bi-play-fill"></i>
                            </span>
                        </span>
                    </a>
                </div><FsLightbox
                    sources={[`https://www.youtube.com/watch?v=${props.source.resourceId.videoId}`]}
                    toggler={toggler}
                />
                <div className="card-body my-3" style={{overflow:"hidden"}}>
                    <h3 className="card-title h-50">{props.source.title}</h3>
                    <p className="card-text" >{props.source.description}</p>
                </div>
            </div>
        </div>
    )
}