import React, { useEffect } from 'react';
import HsNavScroller from '../../../js/vendor/hs-nav-scroller/hs-nav-scroller';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Shuffle from 'shufflejs';
import { nanoid } from 'nanoid';
import { NavLink } from 'react-router-dom';
import CS from '../../../assets/caseStudy.json';

interface CaseStudyCardProps {
    dataGroups: string,
    linkToCaseStudy: string,
    imageUrl: string,
    title: string,
    desc: string,

};

const CaseStudyCard: React.FC<CaseStudyCardProps> = (props) => {
    return (
        <div className="js-shuffle-item col mb-5" data-groups={props.dataGroups}>
            <NavLink className="card card-flush card-transition" to={props.linkToCaseStudy}>
                <img className="card-img-top" src={props.imageUrl} alt="Image Description" />
                <div className="card-body">
                    <span className="card-subtitle text-body">{props.desc}</span>
                    <h3 className="card-title">{props.title}</h3>
                </div>
            </NavLink>

        </div>
    );
}

interface CaseStudyCategorySelector {
    category: string,
    cssClass?: string,
};

const CaseStudyCategorySelector: React.FC<CaseStudyCategorySelector> = (props) => {
    return (
        <li className="nav-item">
            <a className={`nav-link ${props.cssClass ? props.cssClass : ""}`} href="#!" data-group={props.category}>{props.category}</a>
        </li>
    );
}

type CSCard = {
    id: number
    industry: string
    thumbnail: string
    location: string
    title: string
    desc: string
    fullStory: string
}

const CaseStudyList: React.FC = () => {
    const [caseStudies, setCaseStudy] = React.useState<CSCard[]>()
    const { t, i18n } = useTranslation();
    const [category, setCategory] = React.useState<string>("All")

    useEffect(() => {
        const fetchData = async () => {
            // const result = await axios.get(`/api/casestudy?IndustryCategory=${category}&perpage=15&pageNumber=1&culture=${i18n.language}`)
            // setCaseStudy(result.data.results)
            // console.log(result.data)
            
            let filtered = CS.filter(c => category == 'All' || c.Industry == category)
                .map(c => {
                    return {
                        id: c.Id,
                        industry: c.Industry,
                        thumbnail: c.Thumbnail,
                        location: c.Location,
                        title: i18n.language == "en-US" ? c.Title_EN :
                            i18n.language == "es-ES" ? c.Title_ES :
                                i18n.language == 'fr-FR' ? c.Title_FR :
                                    c.Title_EN,
                        desc: "",
                        fullStory: ""
                    }
                })
            setCaseStudy(filtered);
        }
        fetchData();
        new HsNavScroller('.js-nav-scroller');
    }, [i18n.language, category])

    return (
        <React.Fragment>
            <div className="bg-img-start" style={{ backgroundImage: "url('/images/svg/components/card-11.svg')" }}>
                    <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                            <h1>{t('case study.Case studies')}</h1>
                            <p>{t('case study.How the flexibility of our system has helped people like you')}</p>
                        </div>
                    </div>
                </div>
            <div className="container content-space-b-2 content-space-b-lg-3" style={{ marginTop: "100px" }}>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3" style={{ marginTop: "50px" }}>
                    {
                        caseStudies?.map((caseStudy, index) => {
                            return (
                                <CaseStudyCard key={nanoid()} title={caseStudy.title} linkToCaseStudy={`/casestudies/${caseStudy.id}`}
                                    desc={caseStudy.desc} imageUrl={caseStudy.thumbnail} dataGroups={`["${caseStudy.industry}"]`} />
                            );
                        })
                    }

                </div>
            </div>

        </React.Fragment>
    );
}

export default CaseStudyList;