import React, { Fragment, useEffect, useLayoutEffect } from 'react';
import HSStickyBlock from '../../../js/vendor/hs-sticky-block/hs-sticky-block';
import AmazonSvg from '../../../assets/svg/brands/amazon.svg';
import { CaseStudies } from '../../../data/caseStudies';
import { useParams } from 'react-router-dom';
import i18n from '../../../i18n';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import CS from '../../../assets/caseStudy.json';

type CSCard = {
    id: number
    industry: string
    thumbnail: string
    images: string
    location: string
    title: string
    desc: string
    fullstory: string
    goals: string
}

//@ts-ignore
const CaseStudy: React.FC = () => {
    const { t, i18n } = useTranslation();
    let { casestudyId } = useParams();
    const [caseStudy, setCaseStudy] = React.useState<CSCard>()
    let casestudy = CaseStudies.find(object => object.Id === casestudyId);

    useEffect(() => {
        const fetchData = async () => {
            // const result = await axios.get(`/api/casestudy/${casestudyId}?culture=${i18n.language}`)
            // setCaseStudy(result.data)
            // console.log(result.data)
            let cc = CS.find(c => c.Id.toString() ==  casestudyId)
            if (cc==null){
                return
            }
            let data={
                id:cc.Id,
                industry:cc.Industry,
                thumbnail:cc.Thumbnail,
                images:cc.Images,
                location:cc.Location,
                title:i18n.language == "en-US" ? cc.Title_EN :
                            i18n.language == "es-ES" ? cc.Title_ES :
                                i18n.language == 'fr-FR' ? cc.Title_FR :
                                    cc.Title_EN,
                desc:i18n.language == "en-US" ? cc.Desc_EN :
                            i18n.language == "es-ES" ? cc.Desc_ES :
                                i18n.language == 'fr-FR' ? cc.Desc_FR :
                                    cc.Desc_EN,
                fullstory:i18n.language == "en-US" ? cc.FullStory_EN :
                            i18n.language == "es-ES" ? cc.FullStory_ES :
                                i18n.language == 'fr-FR' ? cc.FullStory_FR :
                                    cc.FullStory_EN,
                goals:cc.Goadls

            }
            setCaseStudy(data)

            document.title = data.title+" - Hartmann"
        }
        fetchData();
    }, [i18n.language])

    useLayoutEffect(() => {
        //@ts-ignore
        new HSStickyBlock('.js-sticky-block', {
            //@ts-ignore
            targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
        })
        return () => { }
    }, [])



    return (
        <React.Fragment>
            <div className="bg-dark" style={{ backgroundImage: "url(../assets/svg/components/wave-pattern-light.svg)" }}>
                <div className="container content-space-2">
                    <div className="w-lg-65 text-center mx-lg-auto">
                        <span className="badge bg-warning text-dark rounded-pill mb-3">{t('case study.Customer success story')}</span>
                        <h1 className="text-white mb-0">{t('Millions turn to Hartmann for a great customer experience')}</h1>
                    </div>
                </div>
            </div>

            <div className="container content-space-2 content-space-lg-0">
                <div className="row">
                    {
                        caseStudy?.goals ?
                            <Fragment>
                                <div className="col-lg-4 mt-lg-n10 mb-7 mb-lg-0">
                                    <div id="stickyBlockStartPointEg1">
                                        <div className="js-sticky-block"
                                            data-hs-sticky-block-options='{
                                    "parentSelector": "#stickyBlockStartPointEg1",
                                    "targetSelector": "#header",
                                    "breakpoint": "lg",
                                    "startPoint": "#stickyBlockStartPointEg1",
                                    "endPoint": "#stickyBlockEndPointEg1",
                                    "stickyOffsetTop": 80
                            }'>
                                            <div className="card">
                                                <div className="card-header border-bottom text-center">
                                                    <img className="avatar avatar-xxl avatar-4x3" src={caseStudy?.images} alt="Logo" />
                                                </div>

                                                <div className="card-body">
                                                    <dl className="row">
                                                        <dt className="col-sm-4">{t('Industry')}</dt>
                                                        <dd className="col-sm-8">{caseStudy?.industry}</dd>
                                                    </dl>

                                                    <dl className="row">
                                                        <dt className="col-sm-4">{t('Location')}</dt>
                                                        <dd className="col-sm-8">{caseStudy?.location}</dd>
                                                    </dl>

                                                    <dl className="row">
                                                        <dt className="col-sm-4">{t('Goals')}</dt>
                                                        <dd className="col-sm-8">{caseStudy?.goals}</dd>
                                                    </dl>


                                                    <hr className="my-4" />

                                                    <div className="mb-3">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 content-space-t-lg-2">
                                    <div className="ps-lg-4">
                                        <ReactMarkdown>
                                            {caseStudy?.fullstory!}
                                        </ReactMarkdown>

                                        <div id="stickyBlockEndPointEg1"></div>
                                    </div>
                                </div>
                            </Fragment>
                            : <div className="col-lg-12 content-space-t-lg-2">
                                <div className="ps-lg-4">
                                    <ReactMarkdown>
                                        {caseStudy?.fullstory!}
                                    </ReactMarkdown>

                                    <div id="stickyBlockEndPointEg1"></div>

                                </div>
                            </div>

                    }

                </div>
            </div>
        </React.Fragment>
    );
}

export default CaseStudy;