/*
reference
https://www.youtube.com/watch?v=NZKUirTtxcg
*/
import { useEffect, useRef, useState } from 'react'
import { FetchResponse, Posting } from '../../Types';
import axios, { Canceler } from 'axios'

/*
*this function assumes your respose data has a property called 'results' which is type of T[]
*/
function useFetch<T>(fetchUrl: string, perPage: number, pageNumber: number, optionalParam?: any) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [results, setResults] = useState<T[]>([])
  const [hasMore, setHasMore] = useState(false)

  const paramRef = useRef(optionalParam)

  if (JSON.stringify(paramRef.current)!==JSON.stringify(optionalParam)) {
    //if optional parameter is changed, update paramRef value
    paramRef.current = optionalParam
  }

  useEffect(() => {
    setResults([])
  }, [perPage, paramRef.current])//cannot depen on optionalParam directly, has to use paramRef to memorize previous value

  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel: Canceler;
    axios.get<FetchResponse<T>>(fetchUrl, {
      params: { PageNumber: pageNumber, PerPage: perPage, ...optionalParam },
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
      .then(res => {
        setResults((prev: any) => {
          return [...prev, ...res.data.results]
        })
        setHasMore(res.data.results.length > 0)
        setLoading(false)
      }).catch(e => {
        if (axios.isCancel(e)) return
        setError(true)
      })
    return () => cancel()
  }, [perPage, pageNumber,paramRef.current])

  return { loading, error, results, hasMore }
}

export default useFetch