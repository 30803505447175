import React, { useEffect } from 'react';
// import Circles from '../js/circles';
// import HSCore from '../js/hs.core';
// import HSCircles from '../js/hs.circles';

interface StatCircleProps {
    value: number, 
    maxValue: number,
    duration?: number, 
    color1?: string, 
    color2?: string, 
    textColor?: string, 
    secondaryText: string,
    secondaryTextColor?: string, 
    
}

const StatCircle:React.FC<StatCircleProps> = (props) => {
    
    // useEffect(() => {
    //     setTimeout(() => {
    //         HSCore.components.HSCircles.init('.js-circle')
    //       })
    // });
    
    // let params = `{
    //     "value": 47,
    //     "maxValue": 100,
    //     "duration": 2000,
    //     "isViewportInit": true,
    //     "colors": ["#f8fafd", "#00c9a7"],
    //     "radius": 100,
    //     "width": 8,
    //     "fgStrokeLinecap": "round",
    //     "fgStrokeMiterlimit": "10",
    //     "textClass": "circles-chart-content",
    //     "textFontSize": 24,
    //     "textFontWeight": 500,
    //     "textColor": "#00c9a7",
    //     "secondaryText": "Fast-paced, detail-oriented plugins",
    //     "secondaryTextColor": "#77838f",
    //     "secondaryTextFontSize": "13",
    //     "secondaryTextFontWeight": "400",
    //     "dividerSpace": "10"
    //   }`

    return(
        <>
        </>
        // <div className="circles-chart">
        // <div className="js-circle"
        //      data-hs-circles-options='{
        //        "value": 47,
        //        "maxValue": 100,
        //        "duration": 2000,
        //        "isViewportInit": true,
        //        "colors": ["#f8fafd", "#00c9a7"],
        //        "radius": 100,
        //        "width": 8,
        //        "fgStrokeLinecap": "round",
        //        "fgStrokeMiterlimit": "10",
        //        "textClass": "circles-chart-content",
        //        "textFontSize": 24,
        //        "textFontWeight": 500,
        //        "textColor": "#00c9a7",
        //        "secondaryText": "Fast-paced, detail-oriented plugins",
        //        "secondaryTextColor": "#77838f",
        //        "secondaryTextFontSize": "13",
        //        "secondaryTextFontWeight": "400",
        //        "dividerSpace": "10"
        //      }'></div>
        //      </div>
    );
}

export default StatCircle;