import { nanoid } from 'nanoid';
import React from 'react';

type Props = {
    tabs: {
        image: JSX.Element;
        heading: string;
        paragraph: string;
    }[];
    tabOnLeft: boolean
};
// CONTENT - Features - Navs - Component#2
const VerticalNavTabs: React.FC<Props> = (props) => {
    return (
        <>
            <div className="row align-items-lg-center">
                <div className={`col-lg-5 order-lg-${props.tabOnLeft ? "1" : "2"} mb-7 mb-lg-0`}>

                    <div className="js-nav-scroller hs-nav-scroller-horizontal">
                        <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                            <a className="hs-nav-scroller-arrow-link" href="#!">
                                <i className="bi-chevron-left"></i>
                            </a>
                        </span>

                        <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                            <a className="hs-nav-scroller-arrow-link" href="#!">
                                <i className="bi-chevron-right"></i>
                            </a>
                        </span>

                        <ul className="nav nav-lg nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3" id="featuresTab" role="tablist">
                            {
                                props.tabs.map((tab, index) => {
                                    return (
                                        <li className="nav-item" role="presentation" key={nanoid()}>
                                            <a className={`nav-link ${index === 0 ? "active" : ""}`} href={`#features${index}`} id={`features${index}-tab`} data-bs-toggle="tab" data-bs-target={`#features${index}`} role="tab" aria-controls={`#features${index}`} aria-selected="true" style={{ minWidth: '25rem' }}>

                                                <div className="d-flex align-items-center align-items-lg-start">
                                                    <span className="svg-icon svg-icon-sm text-primary">


                                                    </span>

                                                    <div className="flex-grow-1 ms-3">
                                                        <h4 className="mb-1">{tab.heading}</h4>
                                                        <p className="text-body mb-0">{tab.paragraph}</p>
                                                    </div>
                                                </div>

                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </div>

                </div>


                <div className={`col-lg-7 order-lg-${props.tabOnLeft ? "2" : "1"}`}>

                    <div className="tab-content" id="featuresTabContent">

                        {
                            props.tabs.map((tab, index) => {
                                return (
                                    <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`features${index}`} role="tabpanel" aria-labelledby={`features${index}-tab`} key={nanoid()}>

                                        <figure className="device-browser">
                                            {/* <div className="device-browser-header">
                                                    <div className="device-browser-header-btn-list">
                                                        <span className="device-browser-header-btn-list-btn"></span>
                                                        <span className="device-browser-header-btn-list-btn"></span>
                                                        <span className="device-browser-header-btn-list-btn"></span>
                                                    </div>
                                                    <div className="device-browser-header-browser-bar">myprotectornet:11001</div>
                                                </div> */}

                                            {/* <img className="device-browser-img" src={tab.image} alt="Image Description"></img> */}
                                            {tab.image}
                                        </figure>

                                    </div>
                                );
                            })
                        }
                    </div>

                </div>

            </div>
        </>
    );
}

export default VerticalNavTabs;