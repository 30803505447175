import React from 'react';
import FullPageHeroImage from '../../../components/fullPageHeroImage';
import ImageSideBySideWithText from '../../../components/imageSideBySideWithText';
import threePointers from "../../../assets/svg/components/three-pointers.svg";
import DocsPage from "../../../assets/svg/illustrations/docs-main-page.svg";
import DocsFrame from "../../../assets/svg/illustrations/docs-frame.svg";
import SnippetsFrame from "../../../assets/svg/illustrations/snippets-frame.svg";
import LayoutsFrame from "../../../assets/svg/illustrations/layouts-frame.svg";
import { ReactComponent as Abs028 } from '../../../assets/svg/duotone-icons/abs028.svg'
import { ReactComponent as Fil024 } from '../../../assets/vendor/duotone-icons/fil/fil024.svg';
import { ReactComponent as Gen022 } from '../../../assets/vendor/duotone-icons/gen/gen022.svg';
import { useTranslation } from 'react-i18next';
import ContactModal from '../../../components/contact/contact form/ContactModal'

import VerticalNavTabs from '../../../components/verticalNavTabs';
import SoftwareComponent1 from '../../../components/SoftwareComponent1';

const OdysseySoftware: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>

            {/* <FullPageHeroImage heading='ODYSSEY' paragraph='Enterprise Access Control' image='/images/1920x1080/img1.jpg' /> */}
            <div className="bg-img-start" style={{ backgroundImage: "url('/images/Odyssey-software-hero-2.jpg')" }}>
                <div className="container content-space-2 content-space-lg-3">
                    <div className="w-md-65 w-lg-40">
                        <div className="mb-2">
                            <h2 className= "h1 text-white"> {"ODYSSEY Software"} </h2>
                            <p className= "text-white mb-2"> Advanced access control software, optimized for larger systems.</p>
                        </div>
                            <a className="btn btn-light btn-transition rounded-pill" href="https://portal.hartmann-controls.com">Download Odyssey</a>

                            <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent m-2" onClick={()=>setModalShow(true)}>Request Demo</div>
                                <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                                
                            />
                    </div>
                </div>
            </div>
            <div className="container content-space-b-2 content-space-b-lg-3">
                <React.Fragment>
                    {/* <!-- Icon Blocks --> */}
                    <div id="aboutSection" className="container content-space-t-2 content-space-t-lg-3">
                        {/* <!-- Heading --> */}
                        {/* <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9">
                            <h2 className="h1">What we do?</h2>
                            <p></p>
                        </div> */}
                        {/* <!-- End Heading --> */}

                        <div className="row">
                            <div className="col-md-4 mb-7">
                                {/* <!-- Icon Blocks --> */}
                                <div className="text-center px-lg-3">
                                    <span className="svg-icon svg-icon-lg text-primary mb-3">

                                        <Abs028 />


                                    </span>

                                    <h3>Improved performance</h3>
                                    <p>With improvements to our Real-Time Status, you can view far more doors and devices at the same time than ever before</p>
                                </div>
                                {/* <!-- End Icon Blocks --> */}
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col-md-4 mb-7">
                                {/* <!-- Icon Blocks --> */}
                                <div className="text-center px-lg-3">
                                    <span className="svg-icon svg-icon-lg text-primary mb-3">
                                        <Gen022 />
                                    </span>

                                    <h3>Optimized For Larger Systems</h3>
                                    <p>Manage tens of thousands of doors with all the tools needed to handle and operate demanding sites and systems</p>
                                </div>
                                {/* <!-- End Icon Blocks --> */}
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col-md-4 mb-7">
                                {/* <!-- Icon Blocks --> */}
                                <div className="text-center px-lg-3">
                                    <span className="svg-icon svg-icon-lg text-primary mb-3">
                                        <Fil024 />
                                    </span>

                                    <h3>Refreshed User Interface</h3>
                                    <p>With the ability to customize your dashboard to see what's important to you at a glance.
                                    </p>
                                </div>
                                {/* <!-- End Icon Blocks --> */}
                            </div>
                            {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Row --> */}
                    </div>
                    {/* <!-- End Icon Blocks --> */}

                    {/* <!-- Devices --> */}
                    <div className="container">
                        <div className="w-75 mx-auto mb-7">
                            <img className="img-fluid" src={threePointers} alt="SVG Arrow" />
                        </div>

                        {/* <!-- Heading --> */}
                        <div className="w-md-60 w-lg-50 text-center mx-auto mb-7">
                            <p><span className="text-dark fw-semi-bold">The all-new Odyssey software</span>  will provide you with the most customizable and powerful user experience yet!</p>
                        </div>
                        {/* <!-- End Heading --> */}

                        {/* <!-- Devices --> */}
                        <div className="devices">
                            {/* <!-- Mobile Device --> */}
                            <figure className="device-mobile rotated-3d-right">
                                <div className="device-mobile-frame">
                                    <img className="device-mobile-img" src="/images/OdysseySoftware/phone2.jpg" alt="Image Description" />
                                </div>
                            </figure>
                            {/* <!-- End Mobile Device --> */}

                            {/* <!-- Browser Device --> */}
                            <figure className="device-browser">
                                <div className="device-browser-header">
                                    <div className="device-browser-header-btn-list">
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                    </div>
                                    <div className="device-browser-header-browser-bar">https://odyssey:11001</div>
                                </div>

                                <div className="device-browser-frame">
                                    <img className="device-browser-img" src="/images/OdysseySoftware/bannerimage.png" alt="Image Description" />
                                </div>
                            </figure>
                            {/* <!-- End Browser Device --> */}
                        </div>
                        {/* <!-- End Devices --> */}

                        <div className="text-center mx-auto" style={{ maxWidth: "20rem" }}>
                        </div>
                    </div>
                    {/* <!-- End Devices --> */}
                </React.Fragment>
                {/* <div className="container content-space-1"> */}

                {/* <div className="w-lg-75 text-center mx-lg-auto mb-9">
                        <h1 className="display-5 mb-4">Next Generation Access Control</h1>
                        <p className="lead">TODO Some text here</p>
                    </div> */}
                {/* <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <span className="text-cap">Anywhere, Anytime</span>
                        <h2>Next generation IP Access Control</h2>
                    </div> */}

                {/* </div> */}

                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/OdysseySoftware/RefreshedUI.png"}
                    isOdyssey={true}
                >
                    <>
                        <div className="mb-5">
                            <h2 className="mb-3">Refreshed User Interface</h2>
                            <p>Hartmann is proud to present a clean and modern access control web interface designed for any size job. With a customizable dashboard, Odyssey will enable administrators to create widgets such as graphs, shortcuts and more to tailor their experience.
                                <br /><br />Inspired by feedback on the PROTECTOR.Net software, this interface scales much better to larger displays and supports the latest HTML5 web technologies. In Odyssey, all screens are built to support enterprise systems. Including status screens with search and filter capabilities, as well as support for thousands of devices.
                            </p>
                        </div>

                        {/* <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">Extensive API documentation</li>
                            <li className="list-checked-item">Customizable & open source</li>
                            <li className="list-checked-item">Continiously improved</li>
                        </ul> */}
                    </>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='right'
                    image={"/images/OdysseySoftware/DSCIntegration.png"}
                    isOdyssey={true}
                >
                    <>
                        <div className="mb-5">
                            <h2 className="mb-3">DSC PowerSeries Neo Integration</h2>
                            <p>IP level DSC integration allows us to go beyond key switch level integration and permit more advanced arming/disarming and status functions through IP. Our mobile apps have been enhanced to allow remote monitoring and arming/disarming of DSC PowerSeries Neo intrusion panels. New alarm-related actions have been added to our critically acclaimed Action Control Engine (ACE) to enable scripts and macros to seamlessly interface with intrusion panels.
                            </p>
                        </div>

                        {/* <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">Extensive API documentation</li>
                            <li className="list-checked-item">Customizable & open source</li>
                            <li className="list-checked-item">Continiously improved</li>
                        </ul> */}
                    </>
                </ImageSideBySideWithText>

                <div className="content-space-t-2">
                    <div className="container">
                        <div className="bg-light p-4 p-sm-10">
                            <div className="w-md-75 text-center mx-md-auto mb-5 mb-md-9">
                                <h2>ACE Solutions</h2>
                                <p className="fs-4">Our unique ACE solution provides the tools you need to resolve real-world problems for your clients. Here are some of the creative solutions our dealers and support team have created. Click below to see a few scenarios.</p>
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewSolutionModal">View Solutions</button>
                            </div>
                            <div className="modal fade" id="viewSolutionModal" tabIndex={-1} role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-close">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="container content-space-1 content-space-lg-1">
                                                <div className="w-lg-85 mx-lg-auto">
                                                    <div className="accordion accordion-flush accordion-xl" id="accordionFAQ">
                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousOne">
                                                                <a className="accordion-button" role="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Track and report employee truancy
                                                                </a>
                                                            </div>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingCuriousOne" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    The Odyssey access control system was able to utilize its server-side scripting engine (ACE) to email and text Ted a short email every morning at 9:30 am with a list of employees who have not entered the building that day.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousTwo">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    Card counting and limiting
                                                                </a>
                                                            </div>
                                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingCuriousTwo" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that will use a custom attribute on each user. This attribute will represent how many times that card is allowed to be used each day. Every time one of those cards is granted access to a reader, the server will decrement that custom attribute by one. Once it reaches zero, the credential is instantly disabled and the card will be denied access. The count can be reset at a specific time each day.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousThree">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    Special action card to unlock communal rooms
                                                                </a>
                                                            </div>
                                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingCuriousThree" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that will allow a set of designated cards to be used in the community rooms. When a community card is presented, it will unlock the door for a configurable amount of time. Can be customized even further based on any specific needs.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousFour">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    Alternate door schedule weekly
                                                                </a>
                                                            </div>
                                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingCuriousFour" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a script was created that would run every Sunday morning to switch the door schedule on the factory doors to match the shift changes each week without user intervention.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousFive">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                    Public to secure on sunrise and sunset
                                                                </a>
                                                            </div>
                                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingCuriousFive" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a third-party service on the internet could be calculated to give back the exact time of sunrise and sunset each week. The script would then modify the existing door schedule whenever these times we’re updated, removing the need for manually changing schedules.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <div className="accordion-header" id="headingCuriousSix">
                                                                <a className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                    Set all doors to public on the first credential in
                                                                </a>
                                                            </div>
                                                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingCuriousSix" data-bs-parent="#accordionFAQ">
                                                                <div className="accordion-body">
                                                                    By utilizing ACE, a set of rules can be implemented so that once the manager or owner swipes at any door in the morning, all the public doors will go from locked to unlocked. This saves time and is convenient.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <figure className="device-browser">
                                <div className="device-browser-header">
                                    <div className="device-browser-header-btn-list">
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                        <span className="device-browser-header-btn-list-btn"></span>
                                    </div>
                                    <div className="device-browser-header-browser-bar">https://odyssey:11001</div>
                                </div>

                                <div className="device-browser-frame">
                                    <img className="device-browser-img" src="/images/OdysseySoftware/ACEsolutions.png" alt="Image Description" />
                                </div>
                            </figure>


                            {/* <img className="img-fluid rounded-2" src={"/images/OdysseySoftware/ACEsolutions.png"} alt="Image Description" /> */}
                        </div>
                    </div>
                </div>
                <ImageSideBySideWithText
                    alignChild='left'
                    image={"/images/OdysseySoftware/Photobadging.png"}
                    isOdyssey={true}
                >
                    <>
                        <div className="mb-5">
                            <h2 className="mb-3">Integrated Photo Badging</h2>
                            <p>Odyssey includes embedded photo badge design and printing functions through an easy-to-use click and drag interface. This eliminates the need for third-party badge design software. Easily create professional ID badges with a highly customizable design tool that allows you to create unique cards by adding, fields, images, QR or Barcodes, shapes and much more.</p>
                        </div>

                        {/* <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">Extensive API documentation</li>
                            <li className="list-checked-item">Customizable & open source</li>
                            <li className="list-checked-item">Continiously improved</li>
                        </ul> */}
                    </>
                </ImageSideBySideWithText>
                <ImageSideBySideWithText
                    alignChild='right'
                    image={"/images/OdysseySoftware/AdvancedAdminPermission.png"}
                    isOdyssey={true}
                >
                    <>
                        <div className="mb-5">
                            <h2 className="mb-3">Advanced Administrator Permission System</h2>
                            <p>Built with feedback from hundreds of system integrators; the Odyssey administrator permissions system was built from the ground up to provide the ability to precisely define what an administrator can view, edit, delete and manage.
                                <br /><br />This includes the ability to exclude or include specific users, doors, and access groups. The addition of security groups and permission templates allows simple management of this powerful system.
                            </p>
                        </div>

                        {/* <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                            <li className="list-checked-item">Extensive API documentation</li>
                            <li className="list-checked-item">Customizable & open source</li>
                            <li className="list-checked-item">Continiously improved</li>
                        </ul> */}
                    </>
                </ImageSideBySideWithText>
                <SoftwareComponent1 />

            </div>
            <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9">
                <h2 className="h1">{t('package.Software Packages')}</h2>
                <p>{t('package.Package Description')}</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-lg-9">
                    <div className="table-responsive-lg  w-lg-75 mx-lg-auto" >
                        <table className="table table-lg table-striped table-bordered table-nowrap table-vertical-border-striped">
                            <thead className="table-text-center">
                                <tr>
                                    <th scope="col" style={{ width: '40%' }}></th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Basic')}</span>
                                        {/* <span className="d-block text-muted small">$0/mon</span> */}
                                    </th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Essentials')}</span>
                                        {/* <span className="d-block text-muted small">$0/mon</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Small')}{/*<span className="badge bg-warning text-dark rounded-pill ms-1">Hot</span>*/}</span>
                                        {/* <span className="d-block text-muted small">$60/mon</span> */}
                                    </th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Standard')}{/*<span className="badge bg-warning text-dark rounded-pill ms-1">Hot</span>*/}</span>
                                        {/* <span className="d-block text-muted small">$60/mon</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Mid')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Large')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('package.Enterprise')}</span>
                                        {/* <span className="d-block text-muted small">Custom</span> */}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Doors')}</th>
                                    <td className="table-text-center">20</td>
                                    <td className="table-text-center">40</td>
                                    <td className="table-text-center">125</td>
                                    <td className="table-text-center">250</td>
                                    <td className="table-text-center">500</td>
                                    <td className="table-text-center">1000</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Cameras')}</th>
                                    <td className="table-text-center">20</td>
                                    <td className="table-text-center">40</td>
                                    <td className="table-text-center">125</td>
                                    <td className="table-text-center">250</td>
                                    <td className="table-text-center">500</td>
                                    <td className="table-text-center">1000</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Partitions')}</th>
                                    <td className="table-text-center">5</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                    <td className="table-text-center">{t('package.Unlimited')}</td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Elevators')}</th>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.LDAP')}</th>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('package.Unmanaged Doors')}</th>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OdysseySoftware;