import React from 'react';
import { nanoid } from 'nanoid';

interface NavTabsHorizontalProps {
    tab: string[]|undefined,
    pages: JSX.Element[]|undefined,
}

const NavTabsHorizontal: React.FC<NavTabsHorizontalProps> = (props) => {
    return (
        <React.Fragment>
            <div className="js-nav-scroller hs-nav-scroller-horizontal">
                <span className="hs-nav-scroller-arrow-prev" style={{ display: "none" }}>
                    <a className="hs-nav-scroller-arrow-link" href="#">
                        <i className="bi-chevron-left"></i>
                    </a>
                </span>

                <span className="hs-nav-scroller-arrow-next" style={{ display: "none" }}>
                    <a className="hs-nav-scroller-arrow-link" href="#">
                        <i className="bi-chevron-right"></i>
                    </a>
                </span>
                <ul className="nav nav-segment nav-pills nav-fill mb-7" id="propertyOverviewNavTab" role="tablist" >
                    {
                        props.tab?.map((tab, index) => {
                            return (
                                <li key={index} className="nav-item" role="presentation" >
                                    <a className={`nav-link ${index === 0 ? 'active' : ''}`} href={`#productOverviewNav${index}`}
                                        id={`#productOverviewNav${index}-tab`} data-bs-toggle="tab" data-bs-target={`#productOverviewNav${index}`}
                                        role="tab" aria-controls="propertyOverviewNavOne" aria-selected="true" style={{ minWidth: "7rem"}}>
                                        {tab}
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="tab-content">
                {
                    props.pages?.map((object, id) => {
                        return (
                            <div key={id} className={`tab-pane fade ${id === 0 ? 'active show' : ''}`} 
                            id={`productOverviewNav${id}`} role="tabpanel" aria-labelledby={`#productOverviewNav${id}-tab`}>
                                {object}
                            </div>
                        );
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default NavTabsHorizontal;