import React from 'react'
import { Link, NavLink } from 'react-router-dom'

type Props = {
}

function SoftwareComponent1({}: Props) {
  return (
    <div className="row">
                    <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0" data-aos="fade-up">
                        <Link className="js-go-to card card-transition h-100" to="../hardware" relative="path">
                            <div className="p-2">
                                <img className="card-img" src={"/images/OdysseySoftware/hardware.jpg"} alt="Image Description" />
                            </div>
                            <div className="card-body">
                                <h3 className="card-title text-inherit">Hardware</h3>
                                <p className="card-text text-body">Hartmann offers a large variety of hardware allowing for flexible and cost-effective installs.</p>
                            </div>
                            <div className="card-footer pt-0">
                                <span className="card-link">Learn more <i className="bi-chevron-right small"></i></span>
                            </div>
                        </Link>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0" data-aos="fade-up" data-aos-delay="150">
                        <Link className="js-go-to card card-transition h-100" to="/products/mobile/admin">
                            <div className="p-2">
                                <img className="card-img" src={"/images/OdysseySoftware/mobile.jpg"} alt="Image Description" />
                            </div>
                            <div className="card-body">
                                <h3 className="card-title text-inherit">Mobile</h3>
                                <p className="card-text text-body">Hartmann's mobile applications offer powerful access control capabilities in the palm of your hand.</p>
                            </div>
                            <div className="card-footer pt-0">
                                <span className="card-link">Learn more <i className="bi-chevron-right small"></i></span>
                            </div>
                        </Link>
                    </div>

                    <div className="col-sm-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
                        <Link className="js-go-to card card-transition h-100" to="../deploymentoptions" relative="path">
                            <div className="p-2">
                                <img className="card-img" src={"/images/OdysseySoftware/deploymentoptions.jpg"} alt="Image Description" />
                            </div>
                            <div className="card-body">
                                <h3 className="card-title text-inherit">Deployment options</h3>
                                <p className="card-text text-body">No matter the requirements, Hartmann has a deployment option that will meet your needs.</p>
                            </div>
                            <div className="card-footer pt-0">
                                <span className="card-link">Learn more <i className="bi-chevron-right small"></i></span>
                            </div>
                        </Link>
                    </div>
                </div>
  )
}

export default SoftwareComponent1