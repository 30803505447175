import React from 'react'
import { useTranslation } from 'react-i18next';
import HalfPageHeroImage from '../../../components/halfPageHeroImage'
import SectionPopOutWithParagraphs from '../../../components/sectionPopOutWithParagrapsh'
import wavepattern from '../../../assets/svg/components/wave-pattern-light.svg';
import { ReactComponent as Arr032 } from '../../../assets/vendor/duotone-icons/arr/arr032.svg';
import { ReactComponent as Ecm006 } from '../../../assets/vendor/duotone-icons/ecm/ecm006.svg';
import { ReactComponent as Elc002 } from '../../../assets/vendor/duotone-icons/elc/elc002.svg';
import { ReactComponent as Gen007 } from '../../../assets/vendor/duotone-icons/gen/gen007.svg';
import { ReactComponent as Elc011 } from '../../../assets/vendor/duotone-icons/elc/elc011.svg';
import { ReactComponent as Fil001 } from '../../../assets/vendor/duotone-icons/fil/fil001.svg';


type Props = {}

function Commercial({ }: Props) {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <HalfPageHeroImage image='/images/1920x800/Commercial-Header.jpg' heading={"Commercial & Residential properties"} paragraph={"Integrated solutions to provide peace of mind to commercial and residential properties"} headingBelow={false} />
            <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <div className="mb-5 mb-md-10">
                        <p className="lead">Security is important on commercial properties because it is crucial to protect the assets of the business that is operating on the property. This can include physical assets, such as inventory, equipment, and tools, as well as intangible assets, such as confidential information and intellectual property.</p>
                        <p className="lead">Security is also important for the safety of employees, customers and residents. Commercial and residential properties attract a lot of foot traffic, and it is important to ensure that the environment is safe and secure for everyone who enters the premises.</p>
                        <p className="lead">Due to compliance and regulations, many industries have specific security requirements that businesses must adhere to in order to operate legally. By implementing security measures, commercial property owners can ensure that they are meeting these requirements.</p>
                        <p className="lead">Hartmann offers complete access control solutions to help protect the assets, employees, customers, and reputation of the businesses that operate on the premises.</p>
                    </div>
                </div>
                <div className="row gx-3">

                    <div className="w-100"></div>

                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - commercial 1.jpg')`, height: "15rem" }}></div>
                    </div>

                    <div className="col-4 d-none d-md-block mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - commercial 2.jpg')`, height: "15rem" }}></div>
                    </div>

                    <div className="col">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - commercial 3.jpg')`, height: "15rem" }}></div>
                    </div>
                </div>
            </div>



            <div className="container content-space-1">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2 className="h1">Key Benefits</h2>
                    <p>Using Hartmann’s Access Control in commercial and residential properties.</p>
                </div>

                <div className="row gx-3 mb-5 mb-md-9">
                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100">
                            <img className="card-img-top" src="/images/education/prevent-access.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Prevent unauthorized access</span>
                                <div className="card-text lh-base">By implementing access control, you can prevent unauthorized users from accessing sensitive information or resources.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100">
                            <img className="card-img-top" src="/images/education/tracking.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Auditing and tracking</span>
                                <div className="card-text lh-base">Receive detailed logs of who accesses what resources and when. It can also be useful for auditing and tracking purposes.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
                        <div className="card card-transition h-100">
                            <img className="card-img-top" src="/images/education/safety.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Community safety</span>
                                <div className="card-text lh-base">Access control provides a safer environment. This can be particularly important for properties that serve or house vulnerable populations, such as healthcare facilities or schools.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-transition h-100">
                            <img className="card-img-top" src="/images/education/efficiency.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Improved efficiency</span>
                                <div className="card-text lh-base">Having authorized access can improve the overall efficiency of your business, as it reduces the need for security personnel to manually check IDs or monitor entry points.</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <SectionPopOutWithParagraphs backgroundPattern={wavepattern}
                sectionHeading={"Hartmann tools catered for commercial & residential properties."} sectionSubHeading={""}
                paragraphs={
                    [
                        {
                            icon:<Arr032/>,
                            heading: "Elevator Access",
                            paragraph: "Properties that use elevators may benefit from having access control to restrict access to certain floors or areas within the building. This is especially important in Multi-tenant buildings, ensuring that only authorized users have access to floors, apartments, rooms or areas.",

                        },
                        {
                            icon:<Ecm006/>,
                            heading: "Gate management",
                            paragraph: "Hartmann offers gate management solutions with features like anti-passback, the ability to pulse gates remotely, and much more. Our access control is an essential component of gate management. improving the overall security, monitoring, and auditing of the parking area.",

                        },
                        {
                            icon:<Elc002/>,
                            heading: "Mobile Applications",
                            paragraph: "HC-Key and HC-Admin applications open all the doors to your favourite places! HC-Key eliminates the need to carry physical credentials and allows you to unlock doors swiftly and with ease using your smartphone. HC-Admin allows for easy access to deployments to view the real-time status of doors, floors, inputs, and outputs, and perform all actions using your smartphone.",

                        },
                        {
                            icon:<Gen007/>,
                            heading: "Notifications",
                            paragraph: "Alert notifications are essential and ensure no notifications are missed. They provide real-time information from late employees to notify administrators of doors forced or held open. One or more administrators will then be able to respond and acknowledge the event, ensuring the system is always operational.",

                        },
                        {
                            icon:<Elc011/>,
                            heading: "Video Management",
                            paragraph: "Video management systems can be integrated with PROTECTOR.Net and Odyssey and allow for the monitoring of access to a facility, as well as the recording of video footage for the purpose of security, monitoring, and incident response.",

                        },
                        {
                            icon:<Fil001/>,
                            heading: "Additional features",
                            paragraph: "Property managers may also benefit from having multiple partitions, each for a property with its own administrators. As well as automated schedules, unmanaged door monitoring, easy integration with other systems such as IP Bell and much more!",
                        },
                    ]
                } />
        </div>
    )
}

export default Commercial