import React, { useEffect } from 'react';


const MobileBanner: React.FC = (props) => {
 



    return (
        <React.Fragment>
            {/* <!-- Hero --> */}
            <div className="position-relative bg-img-start" style={{ backgroundImage: "url('/images/card-11.svg')" }}>
                <div className="container content-space-t-4 content-space-b-1 content-space-b-lg-3">
                    <div className="row justify-content-lg-between align-items-lg-center pt-lg-5">
                        <div className="col-md-8 col-lg-5">
                            {/* <!-- Heading --> */}
                            <div className="mb-5">
                                <h1 className="display-4 mb-3">Access Control From Anywhere</h1>
                                <p className="lead">Modify, manage and monitor your facillity from anywhere with a single interface.</p>
                            </div>
                            {/* <!-- End Title & Description --> */}

                            {/* <!-- Form --> */}
                            
                            {/* <!-- End Form --> */}

                            <span className="text-cap">Front partners:</span>

                            <div className="row">
                                <div className="col py-3">
                                    <img className="avatar avatar-lg avatar-4x3" src="../assets/svg/brands/layar-dark.svg" alt="Logo" />
                                </div>
                                {/* <!-- End Col --> */}

                                <div className="col py-3">
                                    <img className="avatar avatar-lg avatar-4x3" src="../assets/svg/brands/fitbit-dark.svg" alt="Logo" />
                                </div>
                                {/* <!-- End Col --> */}

                                <div className="col py-3">
                                    <img className="avatar avatar-lg avatar-4x3" src="../assets/svg/brands/capsule-dark.svg" alt="Logo" />
                                </div>
                                {/* <!-- End Col --> */}
                            </div>
                            {/* <!-- End Row --> */}
                        </div>
                        {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Row --> */}
                </div>

                {/* <!-- Gallery --> */}
                {/* <!-- Devices --> */}
                {/* <div id="secondContent" className="py-5 devices-wrap devices-rotated-body" style={{ display: "none", opacity: "0" }}> */}
                <div className="devices-top-start-50 devices-rotated zi-2" style={{ width: "47rem" }} >
                    <div className="devices-wrap devices-rotated-body" >
                        {/* <!-- Tablet Device --> */}
                        <figure className="device-tablet" style={{ width: "27rem" }} data-aos="fade-up"
                            data-aos-delay="300" data-aos-duration="1000">
                            <div className="device-tablet-frame">
                                <img className="device-tablet-img" src="/images/1618x1010/homePage.jpg" alt="Image Description" />
                            </div>
                        </figure>
                        {/* <!-- End Tablet Device --> */}

                        {/* <!-- Vertical Tablet Device --> */}
                        <figure className="device-vertical-tablet" style={{ width: "18rem" }} data-aos="fade-up"
                            data-aos-delay="500" data-aos-duration="1000">
                            <div className="device-vertical-tablet-frame">
                                <img className="device-vertical-tablet-img" src="/images/533x711/tabletAddUser.jpg" alt="Image Description" />
                            </div>
                        </figure>
                        {/* <!-- End Vertical Tablet Device --> */}

                        {/* <!-- Mobile Device --> */}
                        <figure className="device-mobile align-self-start" style={{ width: "10rem" }} data-aos="fade-up"
                            data-aos-delay="400" data-aos-duration="1000">
                            <div className="device-mobile-frame">
                                <img className="device-mobile-img" src="/images/407x867/protectornetApp.jpg" alt="Image Description" />
                            </div>
                        </figure>
                        {/* <!-- End Mobile Device --> */}

                        {/* <!-- Browser Device --> */}
                        <figure className="device-browser" style={{ width: "35rem" }} data-aos="fade-up"
                            data-aos-delay="600" data-aos-duration="1000">
                            <div className="device-browser-header">
                                <div className="device-browser-header-btn-list">
                                    <span className="device-browser-header-btn-list-btn"></span>
                                    <span className="device-browser-header-btn-list-btn"></span>
                                    <span className="device-browser-header-btn-list-btn"></span>
                                </div>
                                <div className="device-browser-header-browser-bar">https://protectornet:11001</div>
                            </div>
                            <div className="device-browser-frame">
                                <img className="device-browser-img" src="/images/1618x1010/status.jpg" alt="Image Description" />
                            </div>
                        </figure>
                        {/* <!-- End Browser Device --> */}
                    </div>
                </div>
                {/* <!-- End Devices --> */}
            </div>
            {/* <!-- End Gallery --> */}
            {/* <!-- End Hero --> */}
        </React.Fragment >
    );
}

const MobileApps: React.FC = () => {
    return (
        <>
            <MobileBanner />
            <div className="border-top">
                <div className="container content-space-2 content-space-lg-3">
                    {/* <!-- Heading --> */}
                    <div className="w-lg-50 text-center mx-lg-auto mb-7">
                        <span className="text-cap">There's an app for that</span>
                        <h2>An app for admins and an app for users</h2>
                        <p>Manage your building from your phone or issue users mobile credentials that can unlock doors remotely.</p>
                    </div>
                    {/* <!-- End Heading --> */}

                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-7 justify-content-center">
                            {/* <!-- Card --> */}
                            <div className="card bg-soft-primary shadow-none text-center mx-5" data-aos="fade-up" >
                                <div className="card-body d-flex flex-column">
                                    <img className="img-fluid" src="/images/mockups/img7.png" alt="Image Description" />

                                    <h3 className="card-title">HC-Key</h3>
                                    <p className="card-text">Unlock doors from your phone.</p>

                                    {/* <div className="mb-4">
                                        <img className="avatar avatar-xss avatar-4x3 me-2" src="../assets/svg/brands/figma-icon.svg" alt="Figma" />
                                        <img className="avatar avatar-xss avatar-4x3" src="../assets/svg/brands/adobe-xd-icon.svg" alt="Adobe XD" />
                                    </div> */}

                                </div>
                                <div className="card-footer pt-0">
                                    <div className="d-grid ">
                                        <a className="btn btn-primary card-transition" href="/products/mobile/user">Learn More </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Card --> */}
                        {/* <!-- End Col --> */}

                            {/* <!-- Card --> */}
                            <div className="card bg-soft-dark shadow-none text-center mx-5" data-aos="fade-up" data-aos-delay="100">
                                <div className="card-body d-flex flex-column">
                                    <img className="img-fluid" src="/images/mockups/img8.png" alt="Image Description" />

                                    <h3 className="card-title">PROTECTOR.Net Mobile</h3>
                                    <p className="card-text">Manage your system from anywhere.</p>

                                </div>
                                <div className="card-footer pt-0">
                                    <div className="d-grid">
                                        <a className="btn btn-primary card-transition" href="/products/mobile/admin">Learn More </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Card --> */}
                        {/* <!-- End Col --> */}

                        {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Row --> */}

                </div>
            </div>
        </>
    );
}

export default MobileApps;