import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FullPageHeroImage from '../../../components/fullPageHeroImage';
import HeroImageForApps from '../../../components/heroImageForApps';
import HeroImageWithText from '../../../components/heroImageWithText';

const HCKey: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [toggler, setToggler] = useState(false);

    React.useEffect(() => {
        document.title = "HC Key Mobile - Hartmann";
    });

    return (
        <>
            <HeroImageForApps header='HC-Key' paragraph='Open all the doors to your favorite places.' image='/images/900x900/900x900-hckey.png'/>
            <div className="container fluid no-padding">

                <div className="overflow-hidden">
                    <div className="container content-space-2 content-space-t-lg-3">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>Created for the mobile world.</h2>
                                    <p>Crafted with the mobile user in mind, our HC Key app enables seamless building access via your phone, requiring only an internet connection. {/*<Link to="/">Click Here!</Link>*/}</p>
                                </div>

                                {/* <div className="w-md-50 w-lg-75 mb-2">
                                    <div className="bg-img-start text-center rounded-2 py-10 px-5" style={{ backgroundImage: "url('/images/480x320/img18.jpg')" }}>
                                        <a className="video-player video-player-btn" role="button" onClick={() => setToggler(!toggler)}>
                                            <span className="d-flex justify-content-center align-items-center">
                                                <span className="video-player-icon shadow-sm">
                                                    <i className="bi-play-fill"></i>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <FsLightbox
                                        sources={['https://www.youtube.com/watch?v=d4eDWc8g0e0']}
                                        toggler={toggler}
                                    />
                                </div>

                                <p className="small">See how to configure and use HC-Key.</p> */}
                            </div>

                            <div className="col-lg-6">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    {/* <!-- Mobile Device --> */}
                                    <figure className="device-mobile mx-auto">
                                        <div className="device-mobile-frame">
                                            <img className="device-mobile-img" src="/images/407x867/407x867 - HC-Key 1.jpg" alt="Image Description" />
                                        </div>
                                    </figure>
                                    {/* <!-- End Mobile Device --> */}

                                    {/* <!-- SVG Shape --> */}
                                    <div className="position-absolute top-50 start-50 translate-middle zi-n1" style={{ width: "25rem" }}>
                                        <img className="img-fluid" src="/images/svg/components/shape-2.svg" alt="SVG" />
                                    </div>
                                    {/* <!-- End SVG Shape --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                                {/* <!-- Heading --> */}
                                <div className="mb-5">
                                    <h2>The easiest way to add mobile access.</h2>
                                    <p>Since HC-Key makes a connection to the server over the internet, there is no need to replace existing readers. This means it can easily be added to existing sites!</p>
                                </div>
                                {/* <!-- End Heading --> */}

                                <h4 className="mb-3">Get our mobile app</h4>

                                <div className="d-flex gap-3">
                                    <a className="btn btn-dark btn-icon rounded-circle" href="https://apps.apple.com/ca/app/hc-key/id1559953335">
                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M11.76,6.69a.52.52,0,0,1-.59-.52,4.37,4.37,0,0,1,1-2.61A4.82,4.82,0,0,1,14.64,2a.51.51,0,0,1,.63.51,4.66,4.66,0,0,1-1,2.63A4.07,4.07,0,0,1,11.76,6.69Zm5.42,5.82c0-2.72,2-3.33,2-3.92s-1.73-1.91-3.56-1.91-2.54.86-3.78.86-2.25-.86-3.79-.86A5.16,5.16,0,0,0,3.89,9.21,6.64,6.64,0,0,0,3,12.75C3,17.14,6.15,22,8.47,22c1.3,0,1.63-.85,3.42-.85S14,22,15.22,22c2.52,0,4.62-5,4.62-5.07a.51.51,0,0,0-.3-.46A4.44,4.44,0,0,1,17.18,12.51Z" />
                                        </svg>
                                    </a>

                                    <a className="btn btn-dark btn-icon rounded-circle" href="https://play.google.com/store/apps/details?id=com.protectornetmobilecredential">
                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M16.6,15.7l5-2.9c0.2-0.1,0.4-0.4,0.4-0.6v-0.3c0-0.3-0.1-0.5-0.4-0.6l-5-2.9L13.3,12L16.6,15.7z M12.5,11.1l3-3.4L4.4,1.1
                                        C4.2,1,4,1,3.8,1.1L12.5,11.1z M12.5,13L3.8,23c0.2,0.1,0.4,0.1,0.6,0l11.1-6.5L12.5,13z M3,2.1v19.8l8.7-9.9L3,2.1z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <figure className="mx-auto">
                                        <img className="device-mobile-img" src="/images/900x900/900x900 - hckey - 1.jpg"
                                            alt="Image Description" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Features --> */}
                <div className="overflow-hidden">
                    <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                {/* <!-- Heading --> */}
                                <div className="mb-4">
                                    <h2>Share Access with our Visitor Pass!</h2>
                                    <p>HC-Key has the abillity to issue visitor passes with a customizable number of uses. You can also set start and expiry times. </p>
                                </div>
                                {/* <!-- End Heading --> */}

                                {/* <!-- List Checked --> */}
                                <ul className="list-checked list-checked-soft-bg-dark list-checked-lg">
                                    <li className="list-checked-item">Text or email visitor passes.</li>
                                    <li className="list-checked-item">No need for visitors to install the app, just click the shared link.</li>
                                </ul>
                                {/* <!-- End List Checked --> */}
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col-lg-6">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    {/* <!-- Mobile Device --> */}
                                    <figure className="device-mobile mx-auto">
                                        <div className="device-mobile-frame">
                                            <img className="device-mobile-img" src="/images/407x867/407x867 - HC-Key 3.jpg" alt="Image Description" />
                                        </div>
                                    </figure>
                                    {/* <!-- End Mobile Device --> */}

                                    {/* <!-- SVG Shape --> */}
                                    <div className="position-absolute top-50 start-50 translate-middle zi-n1" style={{ width: "25rem" }}>
                                        <img className="img-fluid" src="/images/svg/components/shape-4.svg" alt="SVG" />
                                    </div>
                                    {/* <!-- End SVG Shape --> */}
                                </div>
                            </div>
                            {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Row --> */}
                    </div>
                </div>
            </div>
        </>
    );
}


export default HCKey;