import React from 'react'
import ContactCard from '../../../components/contact/ContactCard'
import ContactModal from '../../../components/contact/contact form/ContactModal'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
type Props = {}

export default function Contact({ }: Props) {
    const { t, i18n } = useTranslation();
    return (
        <div className='container content-space-2 content-space-lg-3'>
            <div className='w-lg-80 mx-lg-auto mb-7'>

                <h4 className='pb-6 text-center fs-2'>{t('contact.Get in touch with Hartmann.')}</h4>
                <h2 className='text-center fs-3'>{t(`contact.Whether you're curious about features, in need of support, or any other information`)}</h2>
                <h2 className='pb-6 text-center fs-3'>{t(`contact.- we're ready to answer any and all questions`)}</h2>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className='col-sm-6 col-lg-4 mb-md-0'>
                        <ContactCard
                            img='images/480x320/480x320 - techsupport.jpg'
                            title='Tech Support'
                            text='The support team at Hartmann is there to help you during the quoting, installation and maintenance process.'
                            href='not used hrf'
                            modal={(e, f) =>
                                <ContactModal
                                    whom={"Tech"}
                                    show={e}
                                    onHide={f}
                                    reasons={["Request Technical Support", "Request Training"]}
                                    products={["Protector.Net", "Odyssey", "Hardwares", "Accessories"]} 
                                />
                            }
                        />

                    </div>
                    <div className='col-sm-6 col-lg-4 mb-md-0'>
                        <ContactCard
                            img="images/480x320/480x320 - sales.jpg"
                            title='Sales'
                            text='The sales team at Hartmann ensures you choose and have the right products for your projets. Request webinars and general information on our products.'
                            href='not used hrf'
                            modal={(e, f) =>
                                <ContactModal whom={"Sales"} show={e} onHide={f} reasons={["Upgrade product", "Purchase product"]} products={["Hardware", "Software"]} />
                            }
                        />

                    </div>
                    <div className='col-sm-6 col-lg-4 mb-md-0'>
                        <ContactCard
                            img='images/480x320/480x320 - dealer.jpg'
                            title='Dealer'
                            text='Get paired with the right dealer for your projects through our vast dealer directly.'
                            href='not used hrf'
                            modal={(e, f) => <ContactModal whom={"Sales"} show={e} onHide={f} />}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}