import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "./useFetch";
import "intersection-observer";
import BlogCard from "./BlogCard";
import { PostingCard } from "../../Types";
type Props = {};
export function BlogListComponent(props: Props) {
  const { t, i18n } = useTranslation();
  const [perPage, setPerPage] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);

  const { loading, error, results, hasMore } = useFetch<PostingCard>(
    "/api/blog",
    perPage,
    pageNum
  );
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node:any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <React.Fragment>
      <div className="container content-space-2 content-space-lg-3">
        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <span className="text-cap">Success stories</span>
          <h2>
            See how Front is helping teams get organized and work smarter
          </h2>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-5 ">
        {results.length !== 0 &&
          results.map((e, index) => (
            <div key={e.id}>
                {results.length === index + 1 ? (
                  <div ref={lastElementRef} key={e.id}>
                    <BlogCard post={e}/>
                  </div>
                ) : (
                    <BlogCard post={e} key={e.id}/>
                )}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
