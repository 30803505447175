import React from 'react'
import ReactMarkdown from 'react-markdown'
import { nanoid } from 'nanoid';
type Props = {
  feature:Record<string,string>[]
}
export default function FeatureList(props: Props) {
  let featureListLength = 0
  if (props.feature!=null){
      featureListLength = props.feature.length;
      return (
          <React.Fragment>
              <div className="col-md-6">
                  {
                      props.feature.map((feature, index) => {
                          if (index <= featureListLength / 2) {
                              return (
                                  <div key={nanoid()}>
                                      <dt className="col-6"><i className={` me-2 ${feature.icon}`}></i> {feature.key}</dt>
                                      <ReactMarkdown children={feature.value} />
                                  </div>

                              );
                          }
                      })
                  }
              </div>
              <div className="col-md-6">
                  {
                      props.feature.map((feature, index) => {
                          if (index >= featureListLength / 2) {
                              return (
                                  <div key={nanoid()}>
                                      <dt className="col-6"><i className={feature.icon}></i> {feature.key}</dt>
                                      <ReactMarkdown children={feature.value} />
                                  </div>

                              );
                          }
                      })
                  }
              </div>
          </React.Fragment>
      );
  } else {
      return (
          <React.Fragment>

          </React.Fragment>
      )
  }
}