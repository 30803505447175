import React, { useEffect } from 'react'
import HSVideoPlayer from '../../js/vendor/hs-video-player/hs-video-player'
type Props = {}

export default function ({}: Props) {
  useEffect(()=>{
    //new HSVideoPlayer('.js-inline-video-player')
  },[])
  return (
    <div className="position-relative bg-img-start" style={{backgroundImage:"url(images/svg/components/card-11.svg)"}}>
      <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-md-2 position-relative zi-2">
        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-9">
          <div className="mb-5">
            <h1>Work with Hartmann</h1>
            <p>Work hard with a highly motivated team of talented people to be part of creating and supporting exceptional Hartmann products</p>
          </div>

          <a className="js-go-to position-static btn btn-primary btn-transition" href="#openRolesSection" data-hs-go-to-options='{
              "targetSelector": "openRolesSection",
              "compensationSelector": "#logoAndNav",
              "offsetTop": 0,
              "position": null,
              "animationIn": false,
              "animationOut": false
             }'>See job openings</a>
        </div>

        <div className="row gx-2">
          <div className="col-4 align-self-end mb-2">
            <div className="ms-md-8">
              <img className="img-fluid rounded-2" src="/images/careers/img1.jpg" alt="Image Description"/>
            </div>
          </div>

          <div className="col-8 mb-2">
            <div id="youTubeVideoPlayer" className="video-player video-player-inline-btn rounded-2">
              <img className="img-fluid video-player-preview rounded-2" src="/images/careers/img2.jpg" alt="Image"/>

              {/* <a className="js-inline-video-player video-player-btn video-player-centered" href="#" data-hs-video-player-options='{
                   "videoId": "d4eDWc8g0e0",
                   "parentSelector": "#youTubeVideoPlayer",
                   "targetSelector": "#youTubeVideoIframe",
                   "isAutoplay": true
                 }'>
                <span className="video-player-icon shadow-sm">
                  <i className="bi-play-fill"></i>
                </span>
              </a> */}

              <div className="ratio ratio-16x9">
                <div id="youTubeVideoIframe"></div>
              </div>
            </div>
          </div>

          <div className="col-8 mb-2">
            <img className="img-fluid rounded-2" src="/images/careers/img3.jpg" alt="Image Description"/>
          </div>

          <div className="col-4 mb-2">
            <div className="me-md-8">
              <img className="img-fluid rounded-2" src="/images/careers/img4.jpg" alt="Image Description"/>
            </div>
          </div>
        </div>
      </div>

      <div className="shape shape-bottom zi-1">
        <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 500H3000V0L0 500Z" fill="#fff"></path>
        </svg>
      </div>
    </div>
  )
}