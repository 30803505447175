import { url } from 'inspector'
import React, { useState } from 'react'
import { ReactComponent as Question } from "../../../assets/vendor/duotone-icons/cod/cod012.svg"
import { ReactComponent as Play } from "../../../assets/vendor/duotone-icons/arr/arr027.svg"
import { ReactComponent as Doc } from "../../../assets/vendor/duotone-icons/fil/fil024.svg"
import { ReactComponent as Dollar } from "../../../assets/vendor/duotone-icons/fin/fin010.svg"
import { ReactComponent as Tool } from "../../../assets/vendor/duotone-icons/cod/cod009.svg"
import { ReactComponent as Text } from "../../../assets/vendor/duotone-icons/txt/txt004.svg"
import { ReactComponent as Arrow } from "../../../assets/vendor/duotone-icons/arr/arr040.svg"
import axios from 'axios'
import { ReactComponent as Dots } from "../../../assets/svg/components/dots.svg"
import { useTranslation } from 'react-i18next'
import { stringify } from 'querystring'
import { ResolvedTypeReferenceDirectiveWithFailedLookupLocations } from 'typescript'
import { all } from 'core-js/fn/promise'
import { map } from 'core-js/core/array'
type Props = {}

let images: string[] = [
    "images//svg/Integration logos/Honda.svg",
    "images//svg/Integration logos/Honda.svg",
    "images//svg/Integration logos/Honda.svg",
    "images//svg/Integration logos/Honda.svg",
    "images//svg/Integration logos/Honda.svg",
]


type CustomerReview = {
    reviewer: Reviewer
    starRating: string
    comment: string

}
type Reviewer = {
    profilePhotoUrl: string,
    displayName: string,
    isAnonymous?: boolean,
    eTag?: string


}


export default function AboutUs({ }: Props) {
    const { t, i18n } = useTranslation();
    const [reviews, setReviews] = useState<CustomerReview[]>();


    let fetchData = async () => {
        let result = await axios.get<CustomerReview[]>('/api/relaystation/reviews')
        setReviews(result.data)
    }
    fetchData()

    return (
        <div className='container content-space-2 content-space-lg-3'>
            {/* Gallery #2 */}
            <div className="w-lg-75 text-center mx-lg-auto">
                <div className="mb-5 mb-md-10">
                    <h1 className="display-4">{t('company.About US')}</h1>
                    <p className="lead">{t('company.Description1')}</p>
                </div>
            </div>
            <div className="row gx-3">
                <div className="col mb-3">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img1.jpg")`, height: "15rem" }}></div>
                </div>

                <div className="col-3 d-none d-md-block mb-3">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img2.jpg")`, height: "15rem" }}></div>
                </div>

                <div className="col mb-3">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img3.jpg")`, height: "15rem" }}></div>
                </div>

                <div className="w-100"></div>

                <div className="col mb-3 mb-md-0">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img4.jpg")`, height: "15rem" }}></div>
                </div>

                <div className="col-4 d-none d-md-block mb-3 mb-md-0">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img5.jpg")`, height: "15rem" }}></div>
                </div>

                <div className="col">
                    <div className="bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img6.jpg")`, height: "15rem" }}></div>
                </div>
            </div>
            <hr className="my-6" />
            {/* contet Sections #1 */}
            <div className="container content-space-2 content-space-lg-3">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2>{t('company.Description2')}</h2>
                    </div>
                    <div className="col-lg-6">
                        <p>{t('company.Description3')}</p>
                        <p>{t('company.Description4')}</p>
                    </div>
                </div>
            </div>

            <hr className="my-6" />
            {/* Cards- Grid #15 */}
            <div className='mb-10'>
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <p >{t('company.OUR TEAM')}</p>
                    <h2 className="h1">{t('Has the tools to support you!')}</h2>
                </div>

                <div className="row gx-3">
                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a className="card card-sm card-transition h-100" href="/contact">
                            <div className="card-body mb-5">
                                <span className="svg-icon text-primary mb-3">
                                    <Question />
                                </span>

                                <p className="card-text text-body">{t("company.HARTMANN'S")}</p>
                                <h4 className="card-title">{t("company.Unrivaled support")}</h4>
                                <p className="card-text text-body">{t("company.Here to help you during quoting, installation and maintenance process.")}</p>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a className="card card-sm card-transition h-100" href="/resources/instructional-videos">
                            <div className="card-body">
                                <span className="svg-icon text-primary mb-3">
                                    <Play />
                                </span>

                                <p className="card-text text-body">{t("company.HARTMANN'S")}</p>
                                <h4 className="card-title">{t("company.Youtube repository")}</h4>
                                <p className="card-text text-body">{t("company.Find tutorials, case studies, product release videos and much more!")}</p>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
                        <a className="card card-sm card-transition h-100" href="/resources/softwaredownloads">
                            <div className="card-body">
                                <span className="svg-icon text-primary mb-3">
                                    <Doc />
                                </span>

                                <p className="card-text text-body">{t("company.HARTMANN'S")}</p>
                                <h4 className="card-title">{t("company.Technical guides")} </h4>
                                <p className="card-text text-body">{t("company.Documentations needed for every step during all your projects.")}</p>
                            </div>
                        </a>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <a className="card card-sm card-transition h-100" href="/resources/projectregistration">
                            <div className="card-body">
                                <span className="svg-icon text-primary mb-3">
                                    <Dollar />
                                </span>

                                <p className="card-text text-body">{t("company.HARTMANN'S")}</p>
                                <h4 className="card-title">{t("company.Project Registration")}</h4>
                                <p className="card-text text-body">{t("company.Project specified with Hartmann product? Receive project pricing.")}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            {/* Team Section #1 */}
            <div className="text-center">
                <div className="card card-info-link card-sm">
                    <div className="card-body">
                        {t("company.Want to work with us?")} <a className="card-link ms-2" href="/career">{t("company.We're hiring")} <span className="bi-chevron-right small ms-1"></span></a>
                    </div>
                </div>
            </div>
            <hr className="my-6" />

            {/* Testimonials #2 */}
            {/* <div className="overflow-hidden">
                <div className="container content-space-b-2">
                    <div className="position-relative">
                        <div className="bg-light text-center rounded-2 p-4 p-md-7">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">

                                    {reviews &&
                                        <div className="carousel-item active">
                                            <Review reviewer={reviews[0].reviewer} starRating={reviews[0].starRating} comment={reviews[0].comment} />
                                        </div>}

                                    {reviews?.slice(1).map((review) => {
                                            return (
                                                <div className="carousel-item">
                                                    <Review reviewer={review.reviewer} starRating={review.starRating} comment={review.comment} />
                                                </div>
                                            )
                                        })}
                                </div>
                                <a className="carousel-control-prev" role="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </a>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                        <div className="position-absolute bottom-0 start-0 w-100" style={{ maxWidth: "7rem" }}>
                            <div className="mb-n7 ms-n7">
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* Icon Blocks #4 */}

            <div className="overflow-hidden">
                <div className="container content-space-2 content-space-lg-3">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h2>{t("Our promise")}</h2>
                        <p>{t("At Hartmann you’re in control")}</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block">
                            <div className="position-relative pe-lg-4">
                                <div className="card card-transition shadow-none bg-img-start" style={{ backgroundImage: `url("/images/400x500/aboutus/img7.jpg")`, minHeight: "27rem", height: "30rem" }}>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <Tool />
                                        </span>

                                        <h3 className="h4">{t("company.Serviceable")}</h3>
                                        <p>{t("company.Service Description")}</p>
                                    </div>
                                </div>

                                <div className="col-sm-6 mb-3 mb-sm-7">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <Text />
                                        </span>

                                        <h4>{t("company.Rich feature set")}</h4>
                                        <p>{t("company.Feature Set Description")}</p>
                                    </div>
                                </div>

                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <div className="pe-lg-6">
                                        <span className="svg-icon text-primary mb-4">
                                            <Arrow />
                                        </span>

                                        <h4>{t("company.Scalable")}</h4>
                                        <p>{t("company.Scale Description")}</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}



const Review = (Props: CustomerReview) => {


    return (
        <figure className="w-md-80 w-lg-50 mx-md-auto">

            <blockquote className="blockquote">

                {Props.comment}
            </blockquote>


            <figcaption className="blockquote-footer">

                {Props.reviewer.displayName}

            </figcaption>

            <figcaption className="blockquote-footer">

                {starCount(Props.starRating)}


            </figcaption>


        </figure>


    )
}
const starCount = (starRating: String) => {
    switch (starRating) {
        case "FIVE":
            return <p className="hc-stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
        case "FOUR":
            return <p className="hc-stars">&#9733;&#9733;&#9733;&#9733;</p>
        case "THREE":
            return <p className="hc-stars">&#9733;&#9733;&#9733;</p>
        case "TWO":
            return <p className="hc-stars">&#9733;&#9733;</p>
        case "ONE":
            return <p className="hc-stars">&#9733;</p>
    }
}  