import * as React from 'react';

import { Link, NavLink, Route, Routes } from 'react-router-dom';
import { HomeComponent } from './home/HomeComponent';
import Blog from '../../components/Blog/Blog';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import ProductListComponent from './product/ProductListComponent';
import Product from './product/Product';
import CaseStudyList from './caseStudies/CaseStudyList';
import CaseStudy from './caseStudies/CaseStudy';

import background from '../../assets/svg/components/shape-3.svg';
import * as meagaMenu from '../../js/vendor/hs-mega-menu/hs-mega-menu';
import Footer from '../../components/footer';
import MobileOverview from './mobileApps/mobileOverview';
import HCKey from './mobileApps/hcKey';
import HCAdmin from './mobileApps/hcAdmin';
import SoftwareDownloads from './resources/softwareDownloads';
import TrainingAndCertification from './resources/trainingAndCertification';
import ProtectorNetSoftware from './protectornet/software';
import OdysseySoftware from './odyssey/software';
import OdysseyHardware from './odyssey/hardware';
import IntegrationsList from './integrations/integrationlist';
import HolidayHandler from './holidayHandler';
import Education from './marketVerticals/education';
import Synagogues from './marketVerticals/synagogues';
import Contact from './contact/Contact';
import InstructionalVideo from './resources/InstructionalVideo';
import { BlogListComponent } from '../../components/Blog/BlogListComponent';
import { useTranslation } from 'react-i18next';
import Healthcare from './marketVerticals/healthcare';
import ProjectRegistration from './resources/ProjectRegistration';
import AboutUs from './company/AboutUs';
import Career from './company/Career';
import AperioSvg from '../../components/SVG/AperioSvg';
import OdysseyDeploymentOptions from './odyssey/DeploymentOption';
import Commercial from './marketVerticals/commercial';
import CarrersOverview from '../../components/career/CarrersOverview';
import ProtectorNetDeploymentOptions from './protectornet/DeploymentOptions'
import HCBook from './resources/HCBook'

export function WebsiteShellComponent() {
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        new meagaMenu.default('.js-mega-menu', {
            desktop: {
                position: 'left'
            }
        })
    }, [])
    
    return (
        <React.Fragment>
            <header id="header" className="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light bg-white navbar-sticky-top" >
                <div className="container">
                    <nav className="js-mega-menu navbar-nav-wrap">

                        <NavLink to="/" className="navbar-brand">
                            <img className="navbar-brand-logo" src="/images/Logos/logo50px-dark.svg" alt="Logo" style={{ minWidth: "192px" }} />
                        </NavLink>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-default">
                                <i className="bi-list"></i>
                            </span>
                            <span className="navbar-toggler-toggled">
                                <i className="bi-x"></i>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="hs-has-mega-menu nav-item">
                                    <div id="landingsMegaMenu" className="hs-mega-menu-invoker nav-link dropdown-toggle" aria-current="page" role="button" data-bs-toggle="dropdown" aria-expanded="false" >{t('home.Solutions')}</div>

                                    <div className="hs-mega-menu dropdown-menu w-100" aria-labelledby="landingsMegaMenu" style={{ minWidth: "30rem" }}>
                                        <div className="row">
                                            <div className="col-lg-6 d-none d-lg-block">
                                                <div className="navbar-dropdown-menu-banner" style={{ backgroundImage: `url(${background})` }}>
                                                    <div className="navbar-dropdown-menu-banner-content">
                                                        <div className="mb-4">
                                                            <span className="h2 d-block">Hartmann's Difference</span>
                                                            <p>Providing unmatched flexibility, security solutions, and support.</p>
                                                        </div>
                                                        <NavLink className="btn btn-primary btn-transition" to="/company/aboutus">Learn more <i className="bi-chevron-right small"></i></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            

                                            <div className="col-sm">
                                                <div className="navbar-dropdown-menu-inner">
                                                    <div className="row">
                                                        <div className="col-sm mb-3 mb-sm-0">
                                                            <span className="dropdown-header" >{t('home.Market Verticals')}</span>
                                                            



                                                            {/* <LinkContainer to="/marketverticals/healthcare" className='background-inherit'>
                                                                <a className="dropdown-item" href="#">{t('home.Healthcare')}</a>
                                                            </LinkContainer> */}
                                                            {/* <LinkContainer to="/3" className='background-inherit'>
                                                                <a className="dropdown-item" href="#">{t('home.Commercial')}</a>
                                                            </LinkContainer>
                                                            <LinkContainer to="/2" className='background-inherit'>
                                                                <a className="dropdown-item" href="#">{t('home.Condos')}</a>
                                                            </LinkContainer> */}
                                                            {/* <LinkContainer to="/marketverticals/synagogues" className='background-inherit'>
                                                                <a className="dropdown-item" href="#">{t('home.Synagogues')} </a>
                                                            </LinkContainer> */}
                                                            {/* <LinkContainer to="/casestudies/all" className='background-inherit'>
                                                                <a className="dropdown-item" href="#">{t('home.Places of Worship')}</a>
                                                            </LinkContainer> */}
                                                            
                                                          
                                                            <NavLink className="dropdown-item" to="/marketverticals/Commercial">{"Commercial & Residential"} </NavLink>
                                                            <NavLink className="dropdown-item" to="/marketverticals/healthcare">{"Healthcare"}</NavLink>
                                                            <NavLink className="dropdown-item" to="/marketverticals/education">{t('home.Education')} </NavLink>
                                                            <NavLink className="dropdown-item" to="/marketverticals/Synagogues">{"Places of Worship"}</NavLink>
                                                            
                                                            {/* <NavLink className="dropdown-item" to="/marketverticals/GovernmentFacilities">{"Government Facilities"}</NavLink> */}
                                                            {/* <NavLink className="dropdown-item" to="/marketverticals/Hospitality">{"Hospitality"}</NavLink> */}
                                                            {/* <NavLink className="dropdown-item" to="/marketverticals/Manufacturing">{"Manufacturing"}</NavLink> */}
                                                            {/* <NavLink className="dropdown-item" to="/marketverticals/Retail">{"Retail"}</NavLink> */}
                                                            
                                                            



                                                        </div>
                                                        
                                                        <div className="col-sm">
                                                                <div className="mb-3">
                                                                    <span className="dropdown-header">{t('home.Case Studies')}</span>
                                                                    <NavLink className="dropdown-item" to="/caseStudies/all">{"Case Studies"}</NavLink>
                                                                </div>

                                                        <div className="col-sm">
                                                            <div className="mb-3">
                                                                <span className="dropdown-header">{t('home.Other')}</span>
                                                                <NavLink className="dropdown-item" to="/products">{t('home.All Products')}</NavLink>
                                                                {/* <LinkContainer to="/casestudies/all" className='background-inherit'>
                                                                    <a className="dropdown-item" href="#">{t('home.Readers and Peripherals')}</a>
                                                                </LinkContainer>
                                                                <LinkContainer to="/casestudies/all" className='background-inherit'>
                                                                    <a className="dropdown-item" href="#">{t('home.Mobile Access')}</a>
                                                                </LinkContainer> */}
                                                                <NavLink className="dropdown-item" to="/integrations">{t('home.Integrations')}</NavLink>

                                                            </div>

                                                            
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="hs-has-sub-menu nav-item">
                                    <div id="companyMegaMenu" className="hs-mega-menu-invoker nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Company</div>

                                    <div className="hs-sub-menu dropdown-menu" aria-labelledby="companyMegaMenu" style={{ minWidth: "14rem" }}>
                                        <NavLink className="dropdown-item" to="/company/aboutus">About</NavLink>
                                        <NavLink className="dropdown-item" to="/career">Careers</NavLink>
                                        <NavLink className="dropdown-item" to="/contact">Contact</NavLink>
                                    </div>
                                </li>

                                <li className="hs-has-sub-menu nav-item">
                                    <div id="accountMegaMenu" className="hs-mega-menu-invoker nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Odyssey</div>

                                    <div className="hs-sub-menu dropdown-menu" aria-labelledby="accountMegaMenu" style={{ minWidth: "14rem" }}>
                                        <span className="dropdown-header" >{"Overview"}</span>
                                        <NavLink className="dropdown-item" to="/products/odyssey/software">Software</NavLink>
                                        <NavLink className="dropdown-item" to="/products/odyssey/hardware">Hardware</NavLink>
                                        <NavLink className="dropdown-item" to="/products/odyssey/deploymentoptions">Deployment Options</NavLink>
                                        <span className="dropdown-header pt-4" >{"Mobile"}</span>
                                        <NavLink className="dropdown-item" to="/products/mobile/user">HC Key</NavLink>
                                        <NavLink className="dropdown-item" to="/products/mobile/admin">HC Admin</NavLink>
                                    </div>
                                </li>

                                <li className="hs-has-sub-menu nav-item">
                                    <div id="pagesMegaMenu" className="hs-mega-menu-invoker nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">PROTECTOR.Net</div>

                                    <div className="hs-sub-menu dropdown-menu" aria-labelledby="pagesMegaMenu" style={{ minWidth: "14rem" }}>
                                        <span className="dropdown-header" >{"Overview"}</span>
                                        <NavLink className="dropdown-item" to="/products/protectornet/software">Software</NavLink>
                                        <NavLink className="dropdown-item" to="/products/protectornet/hardware">Hardware</NavLink>
                                        <NavLink className="dropdown-item" to="/products/protectornet/deploymentoptions">Deployment Options</NavLink>
                                        <span className="dropdown-header pt-4" >{"Mobile"}</span>
                                        <NavLink className="dropdown-item" to="/products/mobile/user">HC Key</NavLink>
                                        <NavLink className="dropdown-item" to="/products/mobile/admin">HC Admin</NavLink>
                                    </div>
                                </li>



                                <li className="hs-has-sub-menu nav-item">
                                    <div id="portfolioMegaMenu" className="hs-mega-menu-invoker nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Resources</div>

                                    <div className="hs-sub-menu dropdown-menu" aria-labelledby="portfolioMegaMenu" style={{ minWidth: "14rem" }}>
                                        <NavLink className="dropdown-item" to="/resources/softwaredownloads">Downloads</NavLink>
                                        {/* <LinkContainer to="/blog">
                                            <a className="dropdown-item" href="#">Documentation</a>
                                        </LinkContainer> */}
                                        <NavLink className="dropdown-item" to="/resources/trainingandcertification">Training & Certification</NavLink>
                                        {/* <a className="dropdown-item" href="/resources/projectregistration">Project Registration</a> */}
                                        <NavLink className="dropdown-item" to="/resources/instructional-videos">Instructional Videos</NavLink>

                                        {/* <LinkContainer to="/blog">
                                            <a className="dropdown-item" href="#">Guides</a>
                                        </LinkContainer> */}
                                        {/* <NavLink className="dropdown-item" to="/resources/HCBook">Broschure</NavLink> */}
                                    </div>
                                </li>

                                {/* <LinkContainer to="/blog" className="nav-item">
                                    <a className="hs-mega-menu-invoker nav-link" href="#">Blog</a>
                                </LinkContainer> */}

                                {/* <a className="hs-mega-menu-invoker nav-link button btn-barry btn-sm text-light fw-bold" href="https://portal.hartmann-controls.com" target="_blank" rel="nonreferrer" >Dealer Portal</a> */}
                                <li className='nav-item text-center'>

                                    <Link to={"https://portal.hartmann-controls.com"} target="_blank" rel='nonreferrer'>
                                        <div className='text-light fw-bold button btn-barry'>Dealer Portal</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                {/* Alert Message */}
                {/* <div className="alert alert-danger text-center" role="alert">
                Our Phone System is currently down until further notice, Sorry for the Inconvenience.
                </div> */}
                {/* Alert Message */}
            </header>
            <main id="content" role="main" className="pt-10">
                <Routes>
                    <Route path='/company/aboutus' element={<AboutUs />} />
                    <Route path='/casestudies/all' element={<CaseStudyList />} />
                    <Route path='/casestudies/:casestudyId' element={<CaseStudy />} />
                    <Route path='/blog' element={<BlogListComponent />} />
                    <Route path='/blog/:blogId' element={<Blog />} />
                    <Route path='/products' element={<ProductListComponent />} />
                    <Route path='/products/:productName' element={<Product />} />
                    <Route path='/products/mobile' element={<MobileOverview />} />
                    <Route path='/products/mobile/user' element={<HCKey />} />
                    <Route path='/products/mobile/admin' element={<HCAdmin />} />
                    <Route path='/products/:productName/hardware' element={<OdysseyHardware />} />
                    <Route path='/products/odyssey/software' element={<OdysseySoftware />} />
                    <Route path='/products/odyssey/deploymentoptions' element={<OdysseyDeploymentOptions />} />
                    <Route path='/products/protectornet/software' element={<ProtectorNetSoftware />} />
                    <Route path='/products/protectornet/deploymentoptions' element={<ProtectorNetDeploymentOptions />} />
                    <Route path='/resources/softwaredownloads' element={<SoftwareDownloads />} />
                    <Route path='/resources/trainingandcertification' element={<TrainingAndCertification />} />
                    {/* <Route path='/resources/HCBook' element={<HCBook />} /> */}
                    {/* <Route path='/resources/projectregistration' element={<ProjectRegistration />} /> */}
                    <Route path='/resources/instructional-videos' element={<InstructionalVideo />} />
                    <Route path='/integrations' element={<IntegrationsList />} />
                    <Route path='/marketverticals/education' element={<Education />} />
                    <Route path='/marketverticals/synagogues' element={<Synagogues />} />
                    <Route path='/marketverticals/commercial' element={<Commercial />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/marketverticals/healthcare' element={<Healthcare />} />
                    <Route path='/career' element={<Career />} />
                    <Route path='/career/overview' element={<CarrersOverview />} />
                    <Route path='/' element={<HomeComponent />} />

                </Routes>
            </main>
            <Footer />

        </React.Fragment>
    );
}
