import { use } from 'i18next';
import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FormikHelpers, useFormik } from 'formik';

function fileDownloadRequest(file: string, auth?: { userName: string, password: string }) {

    // trigger auth
    window.open("/api/Download/DownloadFile/"
        + auth?.userName + "/" + auth?.password + "/" + file);
}

function verifyAuth(file: string, auth?: { userName: string, password: string }): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await fetch('/api/Download/DownloadFile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ file: file, ...auth })
            })

            if (resp.ok) {
                resp.blob()
                    .then(blob => resolve(resp.ok))
                    .catch(err => reject(err));
            }
            else if (resp.status === 401) {
                reject(new Error("HTTP_UNAUTHORIZED"));
            }
            else {
                reject(new Error(`Error contacting server: ${resp.statusText} (${resp.status})`));
            }

        }
        catch (e) {
            reject(e);
        }
    })
}

const FileDownloadAuthorizationModal: React.FC<{ fileToDownload: string, close: () => void }> = (props) => {

    const isOpen = Boolean(props.fileToDownload);

    const formik = useFormik({
        initialValues: {
            userName: "",
            password: '',
        },
        onSubmit: downloadFile,
    });

    function downloadFile(values: { userName: string; password: string; }, formikHelpers: FormikHelpers<{ userName: string; password: string; }>) {
        if (props.fileToDownload) {
            return verifyAuth(props.fileToDownload, {
                userName: values.userName,
                password: values.password
            })
                .then((resp) => {
                    // trigger download
                    if (resp) {
                        fileDownloadRequest(props.fileToDownload, {
                            userName: values.userName,
                            password: values.password
                        })
                    }
                    return Promise.resolve();
                })
                .catch((e: Error) => {
                    if (e.message === "HTTP_UNAUTHORIZED") {
                        formikHelpers.setFieldError("password", "Password or username is invalid");
                    }
                    else {
                    }
                });
        }
        else {
            return Promise.reject();
        }
    }

    return (
        <Modal show={isOpen} onHide={props.close}
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Download File</Modal.Title>
            </Modal.Header>

            <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            name="userName"
                            placeholder="Username"
                            value={formik.values.userName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.userName && !!formik.errors.userName}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {formik.errors.userName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.password && !!formik.errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { formik.resetForm(); props.close(); }}>
                        Close
                    </Button>
                    {formik.isSubmitting ? <Spinner /> : <Button variant="primary" type="submit" disabled={formik.isSubmitting} >
                        Download
                    </Button>}
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default FileDownloadAuthorizationModal;