const SpecificationData = [
    {
        heading: "PROTECTOR.Net Hardware and Software Specification",
        desc: `The PROTECTOR.Net combined hardware and software specification file is used by architects and engineers to plan and deploy their projects. 
        It can also give insight to potential customers what the system is capable from a technical and engineering perspective.`,
        image: "",
        filePath: "/public/spec/ProtectorNetHardwareSoftwareSpecification.PDF" 
    },
    {
        heading: "PROTECTOR.Net Hardware Specification",
        desc: `This specification file only contains hardware specifications for PROTECTOR.Net Door, Elevator and IO controllers.`,
        image: "",
        filePath: "/public/spec/ProtectorNetHardwareSpecification.PDF" 
    },
    {
        heading: "PROTECTOR.Net Software Specification",
        desc: `This specification file only contains software specifications for the PROTECTOR.Net software platform.`,
        image: "",
        filePath: "/public/spec/ProtectorNetSoftwareSpecification.PDF" 
    },

];

export default SpecificationData;