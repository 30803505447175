import React, { ReactElement } from "react";
//@ts-ignore
import threePointers from "../../../assets/svg/components/three-pointers.svg";
import GenericModal from "../../../components/training/GenericModal";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Cod009 } from '../../../assets/vendor/duotone-icons/cod/cod009.svg';
import { ReactComponent as Com006 } from '../../../assets/vendor/duotone-icons/com/com006.svg';
import { ReactComponent as Fin006 } from '../../../assets/vendor/duotone-icons/fin/fin006.svg';

const PromotionalContent: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <React.Fragment>
            <div id="aboutSection" className="container ">
                <div className="row">
                    <div className="col-md-4 mb-7">
                    </div>
                    <div className="col-md-4 mb-7">
                    </div>
                    <div className="col-md-4 mb-7">
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="w-75 mx-auto mb-7">
                    <img className="img-fluid" src={threePointers} alt="SVG Arrow" />
                </div>
                <div className="w-md-60 w-lg-50 text-center mx-auto mb-7">
                    <p>Investing in our comprehensive training programs provides a competitive edge and ensures success in delivering state-of-the-art access control solutions.</p>
                </div>
                <div className="devices">
                    <figure className="px-10 mx-10 ">
                        <img className="device-browser-img rounded" src="/images/1920x1080/training hero -1920x1080.jpg" alt="Image Description" />
                    </figure>
                </div>
            </div>
            <div className="container content-space-1">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2>Integrator Training</h2>
                    </div>

                    <div className="col-lg-6">
                        <p>Hartmann offers training for integrators on how to install, configure, and customize our access control solutions to meet their clients' needs. Our training provides a hands-on approach to learning the ins and outs of our hardware and software, giving integrators the knowledge and skills they need to successfully implement and maintain Hartmann's solutions.</p>
                    </div>
                </div>
            </div>

            <div className="container content-space-1">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2>Administrator Training</h2>
                    </div>

                    <div className="col-lg-6">
                        <p>Our administrator training is designed to help clients get the most out of our access control solutions. Through our training, administrators learn how to use our software to manage users, access levels, schedules, and more. We also cover advanced features such as reporting, analytics, and integration with third-party systems.</p>
                    </div>
                </div>
            </div>
            <div className="container content-space-1">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2>Sales Training</h2>
                    </div>

                    <div className="col-lg-6">
                        <p>Hartmann's sales training program is designed to help our partners succeed by providing them with the tools and knowledge they need to effectively sell our products. Our training covers everything from product features and benefits to effective sales techniques and objection handling. With our sales training, partners can better understand our solutions and how they can benefit their clients, leading to more successful sales.</p>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

interface TrainingCardProps {
    image: string,
    title: string,
    desc: string,
    link: string,
    svg: ReactElement,
    modal: (val: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element
}

const TrainingCard: React.FC<TrainingCardProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <React.Fragment>
            <div className="card card-dashed shadow-none text-center rounded-2" onClick={() => setModalShow(true)} style={{cursor:"pointer"}}>
                <div className="card-body">
                    <span className="svg-icon text-primary mb-3">
                        {props.svg}
                    </span>
                    <p className="card-text fw-bold">{props.title}</p>
                </div>
            </div>

            {
                props.modal(modalShow, setModalShow)
            }
        </React.Fragment>
    )
}

const TrainingAndCertification: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <React.Fragment>
            <div className="bg-img-start" style={{ backgroundImage: "url('/images/svg/components/card-11.svg')" }}>
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto">
                        <h1>{"Hartmann’s Tailored Training"}</h1>
                        <p>Ensures you gain the skills you need to succeed. </p>
                    </div>
                </div>
            </div>
            <div className="row gx-3 justify-content-center" style={{ marginTop: "50px" }}>
                <div className="col-lg-4 col-sm-8 col-md-6 mb-3 mb-md-0 text-center">
                    <h2>{t('training.Training Options')}</h2>
                </div>
            </div>

            <div className="row gx-3 justify-content-center" style={{ marginTop: "50px" }}>
                <div className="col-lg-2 col-sm-6 col-md-4 mb-3 mb-md-0">
                    <TrainingCard title={t('training.Integrator')} link="test" image="/images/400x200/integrator-training.jpg" desc={t('training.Integrator Description')} svg={<Cod009 />}
                        modal={(e, f) => <GenericModal whom={"Sales"} show={e} onHide={f} dropdownlist={new Map<string, string[]>([["Training Type", ["ProtectorNet", "Odyssey"]]])} />}
                    />
                </div>
                <div className="col-lg-2 col-sm-6 col-md-4 mb-3 mb-md-0">
                    <TrainingCard title={t('training.Sales')} link="test" image="/images/400x200/sales-training.jpg" desc={t('training.Sales Description')} svg={<Com006 />}
                        modal={(e, f) => <GenericModal whom={"Sales"} show={e} onHide={f} />} />
                </div>
                <div className="col-lg-2 col-sm-6 col-md-4 mb-3 mb-md-0">
                    <TrainingCard title={t('training.Admin')} link="test" image="/images/400x200/admin-training.jpg" desc={t('training.Admin Description')} svg={<Fin006 />}
                        modal={(e, f) => <GenericModal whom={"Sales"} show={e} onHide={f} />} />
                </div>
            </div>
            <PromotionalContent />
        </React.Fragment>
    );
}

export default TrainingAndCertification;