import React from 'react';

type Props = {
    header: string, 
    paragraph: string,
    image: string, 
}

const HeroImageForApps: React.FC<Props> = (props) => {
    return (
        <>
            <div className="position-relative gradient-x-three-sm-primary rounded-2 content-space-t-md-1 content-space-b-md-2 mx-md-10">
                <div className="container position-relative content-space-t-2 content-space-t-lg-3 content-space-b-1">
                    {/* Some weirdness with Z-Index and column order so selectively rendering 2 different layouts based on screen size. */}
                    <div className="d-none d-lg-block">
                        <div className="row position-relative zi-2 pt-10">
                            <div className="col-lg-12 pt-10">

                                <div className="w-lg-75 text-center text-lg-start mb-5 mb-lg-7 pt-6">
                                    <h1 className="display-4">{props.header}</h1>
                                    <p>{props.paragraph}</p>
                                </div>
                            </div>
                        </div>
                        <form style={{ position: "relative", zIndex: "-1" }}>
                            <div className="input-card input-card-sm gold-colored-horizontal-divider">

                            </div>
                        </form>

                        <div className="d-block col-lg-7 position-lg-absolute top-0 end-0" style={{ zIndex: "2" }}>
                            <img className="img-fluid rounded-2" src={props.image} alt="Image Description" />
                        </div>
                    </div>
                    {/* This is the other selectively rendered header */}
                    <div className="d-block d-lg-none">
                        <div className="d-block col-lg-7 position-lg-absolute top-0 end-0" style={{ zIndex: "2" }}>
                            <img className="img-fluid rounded-2" src={props.image} alt="Image Description" />
                        </div>
                        <div className="row position-relative zi-2">
                            <div className="col-lg-12">

                                <div className="w-lg-75 text-center text-lg-start mb-5 mb-lg-7">
                                    <h1 className="display-4">{props.header}</h1>
                                    <p>{props.paragraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroImageForApps;