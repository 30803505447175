import React from 'react';

type Props = {
    heading: string, 
    paragraph: string, 
    image: string, 
    headingBelow:boolean
} 

const HalfPageHeroImage: React.FC<Props> = (props) => {
    return (
        <>
            <div className="border-bottom">
                <div className="gradient-y-overlay-sm-gray-900 bg-img-start" style={{ backgroundImage: `url(${props.image})` }}>
                    <div className="container d-md-flex align-items-md-center vh-md-70 content-space-t-4 content-space-b-3 content-space-md-0">

                        {
                            props.headingBelow?
                            <div className="w-75 w-lg-50">
                            <h3 className="text-white">{props.paragraph}</h3>
                            <h1 className="display-4 text-white mb-0">{props.heading}</h1>
                        </div>:
                            <div className="w-75 w-lg-50">
                            <h1 className="display-4 text-white mb-0">{props.heading}</h1>
                            <h3 className="text-white">{props.paragraph}</h3>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default HalfPageHeroImage;