import { is } from "core-js/core/object";


export function getLastUpdated(input: string): string {
  let lastUpdated= new Date(input)
  let timeDiff = new Date().getTime() - lastUpdated.getTime();
  let msec = timeDiff;
  let dd = Math.floor(msec / 1000 / 60 / 60 / 24);
  msec -= dd * 1000 * 60 * 60 * 24;
  let hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  let mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  let ss = Math.floor(msec / 1000);
  msec -= ss * 1000;
  if (dd>=30) return lastUpdated.toDateString()

  return dd !== 0
    ? dd + " days ago"
    : hh !== 0
      ? hh + " hours ago"
      : mm !== 0
        ? mm + " mins ago"
        : ss + " secs ago"
}


class API {
    public async get<T>(route: string): Promise<T> { return await this.apiCall<T>(route) };
    public async post<T>(route: string, body: object = {}): Promise<T> { return await this.apiCall<T>(route, "POST", body); }
    public async put<T>(route: string, body: object = {}): Promise<T> { return await this.apiCall<T>(route, "PUT", body); }
    public async delete<T>(route: string, body: object = {}): Promise<T> { return await this.apiCall<T>(route, "DELETE", body); }

    public async apiCall<T>(route: string, method: string = "GET", body: object = {}): Promise<T> {
        const opts: RequestInit = {
            method,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Accept": "application/json"
            },
            credentials: 'include',
            mode: 'cors'
        };

        if (method !== "GET" && typeof body !== 'object') {
            opts.body = JSON.stringify(body);
        }
        console.log(opts)
        const response = await fetch(route, opts);
        console.log("raw api response", response)
        if (response.status !== 200) {
            if (response.body !== null) {
                return Promise.reject(await response.json());
            } else {
                return Promise.reject(response.status);
            }
        }

        try {
            const result: T = await response.json();
            return result;
        } catch (e) {
            return Promise.reject(response.status);
        }
    }
}

export default new API();



export const validateName = (name:string):[boolean,string] => {
    const regexp = new RegExp('^[a-zA-Z ]+$')
    if (name == '') {
      return [false,"Name field is required"]
    } else if(name.length<3){
      return [false, "Name is too short"]
    }else if(name.length>50){
      return [false, "Name is too long"]
    }else if (!regexp.test(name)) {
      return [false, "Invalid name given"]
    }
    return [true,""]
  }

export const validatePhoneNumber = (phoneNumber:string):[boolean,string] => {

    const regexp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    if (phoneNumber == '') {
      return [false,"Phone number field is required"]
    } else if (!regexp.test(phoneNumber)) {
      return [false,"Invalid phone number given"]
    }
    return [true,'']
  }

  export const validateEmail = (email:string):[boolean,string] => {

    const regexp = new RegExp('^[_a-z0-9]+(\.[_a-z0-9]+)*@(?!.*--)[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,10})$')
    if (email == '') {
      return[false,"Email field is required"]
    } else if (!regexp.test(email)) {
      return[false, "Invalid email given"]
    }
    return [true,'']
  }

  export const validateCompanyName = (companyName:string):[boolean,string] => {
    console.log(companyName)
    if (companyName == '') {
      return[false, "Company name field is required"]
    }
    return [true,'']
  }

  export const validateAddress = (address:string):[boolean,string] =>{
    let error = new Map<string,string>();
    if (address==''){
        return [false, "Address field is required"]
    }else if (address.length<5){
        return [false, "Not long enough"]
    }else if(address.length>40){
        return [false, "Too long"]
    }
    return [true,'']
  }

  export const validateDate =(date:string):[boolean,string]=>{
    let error =new Map<string,string>();
    let d=date.split('-')
    let newDate= new Date(Number(d[0]),Number(d[1])-1,Number(d[2]))
    let current =new Date()

    let dayOfWeek = newDate.getDay();
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0);
    if(current>newDate){
        return [false, "Selected date is earlier than today"]
    }else if(isWeekend){
        return [false,"Selected date is a weekend"]
    } 
    return [true,'']
  }