import React, { ChangeEvent, InputHTMLAttributes, MouseEventHandler } from 'react'
import ReactMarkdown from 'react-markdown'

type Props = {}
type Trove={
    image:string,
    description:string
}
export default function Pricing2({ }: Props) {
    let tr:Trove[]=[{image:"/images/ProductPics/Trove8DR.png",description:`This 2-8 door kit consists of a master controller and 
expansion boards mounted in an Altronix Trove1 enclosure.

Powered by a built-in eFlow6NB power supply mounted inside the enclosure. Controllers connect via single TCP/IP to a local or external Protector.Net server for configuration data and event management. TDM expansion boards connect via RS485 in the same enclosure or
remotely.
`},{image:"/images/ProductPics/Trove16DR.png",description:`This 2-16 door kit consists of a master controller and expansion boards mounted in an Altronix Trove 2 enclosure.

Powered by a built-in eFlow102NB power supply mounted inside the enclosure. Controllers connect via single TCP/IP to a local or external Protector.Net server for configuration data and event management. TDM expansion boards connect via RS458 in the same enclosure or remotely.
`}]
    const [trove,setTrove] = React.useState<Trove>(tr[0]);

    const handlder=(input:ChangeEvent<HTMLInputElement>)=>{
        console.log(input)
        console.log(input.target.id)
            if(input.target.id=="btnradio1"){
                setTrove(tr[0])
            }else{
                setTrove(tr[1])
            }
    }



    return (
        <div className="bg-light rounded-2 mx-3 mx-xl-10">
            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2 className="h1">Altronix Trove Kits</h2>
                </div>

                <div className="position-relative">
                    <div className="row gx-0 align-items-md-center">
                        <div className="col-md-6 ">
                            <div className="card card-lg zi-2" data-aos="fade-up">
                                <div className='pt-3'>
                                    <form>
                                        <div className="text-center">
                                            <div className="btn-group btn-group-segment btn-group-pills" role="group" aria-label="Pricing radio button group">
                                                <input type="radio" className="js-switch-text btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked onChange={handlder}/>
                                                <label className="js-toggle-switch btn btn-sm" htmlFor="btnradio1">
                                                    Trove 1
                                                </label>

                                                <input type="radio" className="js-switch-text btn-check" name="btnradio" id="btnradio2" autoComplete="off" onChange={handlder}/>
                                                <label className="js-toggle-switch btn btn-sm" htmlFor="btnradio2">
                                                    <span className="form-switch-promotion">
                                                        Trove 2
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <img id="image" src={trove.image} className="img-fluid w-75 mx-auto d-block"/>

                                <a className="card-footer card-link bg-light text-center" href="https://www.altronix.com/system_design_tools/trove1/hartmann" target="_blank" rel="nonreferrer">View Kit Builder <i className="bi-chevron-right small ms-1"></i></a>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card card-lg bg-dark ms-md-n2 py-10" data-aos="fade-up" data-aos-delay="200">
                                <div className="card-body text-white ">
                                    <ReactMarkdown children={trove.description} />
                                    {/* <p>{trove.description}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}