import axios from 'axios';
import React, { Fragment, ReactElement, useEffect, useLayoutEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
//@ts-ignore
import HSStickyBlock from '../../../js/vendor/hs-sticky-block/hs-sticky-block';
import FsLightbox from 'fslightbox-react';
import Breadcrumb from '../../../components/breadcrumb';
//@ts-ignore
import NavTabsHorizontal from '../../../components/navTabsHorizontal';
import { Link, useParams } from 'react-router-dom'
import { FindResponse, ProductItem } from '../../../Types';

// TODO remove! Just to ensure this page works, move to Doosans format after.
import ProductData from '../../../data/productData';
import FeatureList from '../../../components/FeatureList';
import MultiImageLightbox from '../../../components/MultiImageLightbox';
import Contact from '../../../components/Contact';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { stringify } from 'querystring';
import { t } from 'i18next';

type Props = {}
interface Resolution {
  width: number
  height: number
}
type Diagram = "PRS_ENCL_ST" | "DRYMOD" | "MEMMOD" | "Piezo" | "PoE_ENCL_ABS_W" | "PoE_ENCL_ABS_B";
export default function Product({ }: Props): ReactElement {
  const { productName } = useParams();
  const [data, setData] = useState<ProductItem>();
  const [tab, setTab] = useState<Record<string, JSX.Element>>({});
  const additionalDiagram = new Map<string, Resolution>([
    ["PRS_ENCL_ST", { width: 2368, height: 3363 }],
    ["DRYMOD", { width: 2302, height: 1713 }],
    ["MEMMOD", { width: 1993, height: 1695 }],
    ["Piezo", { width: 2344, height: 1920 }],
    ["PoE_ENCL_ABS_W", { width: 2490, height: 2337 }],
    ["PoE_ENCL_ABS_B", { width: 2490, height: 2337 }],
  ])
  let converter = (resolution: Resolution): Resolution => {
    const width = 600;
    return {
      width: width,
      height: width * resolution.height / resolution.width
    }
  }
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get<FindResponse<ProductItem>>(`/api/product/${productName}?culture=${i18n.language}`);

      let result = response.data.result
      let tmp: Record<string, JSX.Element> = {}

      tmp["Overview"] = <Fragment>
        <div className="mb-4"></div>
        {result && <ReactMarkdown children={result.LongDesc} />}
        <p></p>
        <hr className="my-6" />

        {
          result.Features?.length != 0
            ? <React.Fragment >
              <div className="row">
                {result.Features != null ?
                  <FeatureList feature={result.Features} /> :
                  <></>
                }
              </div>
            </React.Fragment>
            : <></>
        }
      </Fragment>

      if (result.Specifications && result.Specifications.length != 0) {
        tmp["Specification"] = <React.Fragment>
          {result.Specifications.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }
      if (result.Functions && result.Functions.length != 0) {
        tmp["Advanced Function"] = <React.Fragment>
          {result.Functions.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }
      if (result.PartNumbers && result.PartNumbers.length != 0) {
        tmp["Part Number"] = <React.Fragment>
          {result.PartNumbers.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }
      if (result.RelatedPartNumbers && result.RelatedPartNumbers.length != 0) {
        tmp["Related Part Numbers"] = <React.Fragment>
          {result.RelatedPartNumbers.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }
      if (result.HardwareComponents && result.HardwareComponents.length != 0) {
        tmp["Hardware Components"] = <React.Fragment>
          {result.HardwareComponents.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }
      if (result.PoeTable && result.PoeTable?.length != 0) {
        let va = result.PoeTable[0]
        let test = va.key
        console.log(va)
        tmp["Related Part Numbers"] = <React.Fragment>
          <table className="table table-striped">
            <thead>
            </thead>
            <tbody>
              {
                result.PoeTable.map(row =>
                  <tr>
                    {
                      row.value.split('|').map(data =>
                        <td>
                          {data}
                        </td>
                      )
                    }
                  </tr>
                )
              }
            </tbody>
          </table>
        </React.Fragment>
      }
      if (result.AdditionalInfomation && result.AdditionalInfomation.length != 0) {
        tmp["Additional Information"] = <React.Fragment>
          {result.AdditionalInfomation.map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }

      if (result.FullDimensions && result.FullDimensions.length != 0) {
        tmp["Full Dimensions"] = <React.Fragment>
          <a href={result.FullDimensions[0].value} target="_blank" rel="noopener,nonreferrer">
            {
              additionalDiagram.has(result.PartName)
                ? <img src={result.FullDimensions[0].value} width={600} height={converter(additionalDiagram.get(result.PartName)!).height} />
                : <img src={result.FullDimensions[0].value} width={600} height={850} />
            }
          </a>
        </React.Fragment>
      }

      if (result.Integration && result.Integration.length != 0) {
        tmp["Assa Abloy Integration"] = <React.Fragment>
          <a href={result.Integration[0].value} target="_blank" rel="noopener,nonreferrer">
            {
              additionalDiagram.has(result.PartName)
                ? <img src={result.Integration[0].value} width={600} height={converter(additionalDiagram.get(result.PartName)!).height} />
                : <img src={result.Integration[0].value} width={600} height={600} />
            }
          </a>
        </React.Fragment>
      }

      if (result.Diagram && result.Diagram.length != 0) {
        tmp["Example Diagram"] = <React.Fragment>
          <a href={result.Diagram[0].value} target="_blank" rel="noopener,nonreferrer">
            {
              additionalDiagram.has(result.PartName)
                ? <img src={result.Diagram[0].value} width={600} height={converter(additionalDiagram.get(result.PartName)!).height} />
                : <img src={result.Diagram[0].value} width={600} height={600} />
            }
          </a>
        </React.Fragment>
      }

      if (result.IdentificationModes && result.IdentificationModes.length != 0) {
        tmp["Identification Modes"] = <React.Fragment>
          {result && <ReactMarkdown children={result.IdentificationModes[0].value} />}
          <hr className="my-6" />
          {result.IdentificationModes.slice(1).map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }

      if (result.Credentials && result.Credentials.length != 0) {
        tmp["Credentials"] = <React.Fragment>
          {result && <ReactMarkdown children={result.Credentials[0].value} />}
          <hr className="my-6" />
          {result.Credentials.slice(1).map((card, index) =>
            <Card key={nanoid()} head={card.key} value={card.value} />
          )}
        </React.Fragment>
      }


      setTab(tmp)
      setData(result);
    };
    fetchData();
  }, [i18n.language,productName]);

  // useLayoutEffect(() => {
  //   //@ts-ignore
  //   new HSStickyBlock('.js-sticky-block', {
  //     //@ts-ignore
  //     targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
  //   })
  //   return () => { }
  // }, [])
  return (
    <React.Fragment>
      <div className="container content-space-t-3 pb-3">
        <Breadcrumb breadcrumbObject={[
          { text: "Products", link: "/products" },
          { text: `${productName}` }
        ]} />
      </div>
      {
        data && <MultiImageLightbox data={data} />
      }
      <div className="container content-space-2">
        <div className="row">
          <div className="col-lg-8 mb-9 mb-lg-0">
            <div className="row justify-content-lg-between mb-7">
              <div className="col-12 col-sm-6 mb-5 mb-sm-0" >
                <h1 className="h2 mb-0">{data?.FullPartName}</h1>
                <span className="d-block text-dark mb-3">{data?.PartName}</span>
                
                
                

                {/* <ul className="list-inline list-separator font-size-1 text-body">
                    <li className="list-inline-item">
                      <i className="fas fa-bed text-muted me-1"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="fas fa-bath text-muted me-1"></i> {data?.category}
                    </li>
                    <li className="list-inline-item">
                      <i className="fas fa-ruler-combined text-muted me-1"></i> {data?.weight} lb
                    </li>
                  </ul> */}
              </div>
              {/* <div className="col-lg-3">
                  <div className='text-light fw-bold button btn-barry'>Cut Sheet</div>
              </div> */}

              {/* <div className="col-sm-6 column-divider-sm">
                  <div className="pl-md-4">
                    <h2 className="mb-0">&#163;8,999,000</h2>
                    <span className="d-block text-dark mb-3">Est. Mortgage &#163;2,562/mo</span>
                  </div>
                </div> */}
            </div>

            <NavTabsHorizontal tab={Object.keys(tab)} pages={Object.values(tab)} />
          </div>
          <div className="col-lg-4">
            <Contact></Contact>
          </div>
        </div>
        <div id="stickyBlockEndPoint"></div>
        <hr className="my-6" />
      </div>

      {
        data?.SimilarItems?
          <div className="container content-space-2">
            <div className="w-md-75 w-lg-50 mb-4">
              <h3>{t('similar items you may like')}</h3>
            </div>

            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              {
                data?.SimilarItems?.map(s =>
                  <SimilarItem name={s.key} description={""} key={s.key}></SimilarItem>
                )
              }

            </div>
          </div>
          : <></>
      }

    </React.Fragment>

  )
}


const SimilarItem = (props: { name: string, description: string }) => {

  return (
    <Link className="card card-flush shadow-none" to={`../${props.name}`} relative="path">
      <div className="card-pinned">
        <img className="card-img" src={`/images/ProductPics/${props.name}.png`} alt="Image Description" />
        <div className="card-pinned-top-start">
          <span className="badge bg-success">{t('new')}</span>
        </div>
      </div>

      <div className="card-body mx-4">
        {/* <span className="card-subtitle text-body">For sale</span> */}
        <div className="row align-items-center mb-3">
          <div className="col">
            <h2 className="card-title text-inherit text-center">{props.name}</h2>
            <h4 className="card-text text-inherit">{props.description}</h4>
          </div>

          {/* <div className="col-auto">
              <h3 className="card-title text-primary">{props.price}</h3>
            </div> */}
        </div>
      </div>
    </Link>
  )
}




interface CardProps {
  head: string,
  value: string
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: "15px" }} className="card card-bordered">
        <div className="card-body product-details">
          <dt>{props.head}</dt>
          <ReactMarkdown>{props.value}</ReactMarkdown>
        </div>
      </div>
    </React.Fragment>
  );
}