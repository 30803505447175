import React from 'react';
import HalfPageHeroImage from '../../../components/halfPageHeroImage';
import ImageCollage3x2 from '../../../components/imageCollage3x2';
import ImageSideBySideWithText from '../../../components/imageSideBySideWithText';
import ReadMoreWithPics from '../../../components/readMoreWithPics';
import SectionPopOutWithParagraphs from '../../../components/sectionPopOutWithParagrapsh';
import wavepattern from '../../../assets/svg/components/wave-pattern-light.svg';
import { useTranslation } from 'react-i18next';
import {ReactComponent as Gra004} from '../../../assets/svg/duotone-icons/gra004.svg'
import {ReactComponent as Txt009} from '../../../assets/svg/duotone-icons/txt009.svg'
import {ReactComponent as Map002} from '../../../assets/svg/duotone-icons/map002.svg'
import {ReactComponent as Gen013} from '../../../assets/svg/duotone-icons/gen013.svg'
import {ReactComponent as Gen007} from '../../../assets/svg/duotone-icons/gen007.svg'
import {ReactComponent as Ecm006} from '../../../assets/svg/duotone-icons/ecm006.svg'
import {ReactComponent as Com006} from '../../../assets/svg/duotone-icons/com006.svg'


const Healthcare: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <HalfPageHeroImage image='/images/1920x800/Healthcare-Header.jpg' heading={"Healthcare facilities"} paragraph={"Streamlined solutions ensuring secure and efficient access management for healthcare environments"} headingBelow={false}/>
            <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <div className="mb-5 mb-md-10">
                        <p className="lead">Access control is crucial in healthcare facilities for several reasons. Firstly, it helps maintain the security and safety of patients, staff, and visitors by preventing unauthorized access to restricted areas such as patient rooms, staff  rooms, offices, and labs.</p>
                        <p className="lead">It also ensures that only authorized personnel can access sensitive information and equipment, such as medical records and pharmaceuticals, reducing the risk of theft or tampering. Additionally, it can track and record the movement of individuals within the facility, which can aid in investigations of security breaches or incidents.</p>
                        <p className="lead">Overall, access control is an essential component of a comprehensive security strategy in healthcare facilities and Hartmann offers complete solutions to help provide protection for patients, staff, and assets on the property.</p>
                    </div>
                </div>
                <div className="row gx-3">

                    <div className="w-100"></div>

                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - healthcare 1.jpg')`, height: "15rem" }}></div>
                    </div>
                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - healthcare 2.jpg')`, height: "15rem" }}></div>
                    </div>
                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - healthcare 3.jpg')`, height: "15rem" }}></div>
                    </div>
                </div>
            </div>
            
            <div className="container content-space-1">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2 className="h1">Key Benefits</h2>
                    <p>Using Hartmann’s Access Control in Healthcare Facilities.</p>
                </div>

                <div className="row gx-3 mb-5 mb-md-9">
                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/prevent-access.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Prevent unauthorized access</span>
                                <div className="card-text lh-base">By implementing access control, you can prevent unauthorized users from accessing sensitive information or resources.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/tracking.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Auditing and tracking</span>
                                <div className="card-text lh-base">Receive detailed logs of who accesses what resources and when. It can also be useful for auditing and tracking purposes.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/safety.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Patient and healthcare worker safety</span>
                                <div className="card-text lh-base">Access control ensures a safer environment for healthcare facilities, particularly those that serve vulnerable populations, such as elderly and ill patients.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/efficiency.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Improved efficiency</span>
                                <div className="card-text lh-base">Having authorized access can improve the overall efficiency of your healthcare facility, as it reduces the need for security personnel to manually check IDs or monitor entry points.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SectionPopOutWithParagraphs backgroundPattern={wavepattern}
                sectionHeading={"Hartmann is making healthcare facilities safer and easier to manage."} sectionSubHeading={""}
                paragraphs={
                    [
                        {
                            icon: <Gra004/>,
                            heading: 'Lockdown and Crisis Levels',
                            paragraph: `The Lockdown feature provides a quick and efficient way to secure a facility in case of a security breach or emergency situation. When a lockdown is activated, all doors controlled by Hartmann’s access control system can be locked, preventing anyone from entering the affected areas except for authorized users based on their crisis-level permissions set by the administrator.`,

                        },
                        {
                            icon: <Ecm006/>,
                            heading: 'Ambulance / Emergency vehicle functionality',
                            paragraph: `Unlock car doors and start and stop the engine with the same cards, fobs, and other devices used throughout the facility. Full restrictions on time and allowed vehicles. Advanced I/O logic for extra functions.`,

                        },
                        {
                            icon: <Com006/>,
                            heading: 'Auto-sync users through LDAP Integration',
                            paragraph: `Access Privilege Groups associated with an LDAP group are synced, giving LDAP users permission to access doors and elevators. LDAP integration features group and administrator synchronization, conflict notification, instant disabling of cards and users, and much more.`,

                        },
                        {
                            icon: <Gen007/>,
                            heading: "Notifications",
                            paragraph: `Alert notifications are essential and ensure no notifications are missed. They provide real-time information from late employees to notify administrators of doors forced or held open. One or more administrators will then be able to respond and acknowledge the event, ensuring the system is always operational.`,

                        },
                        {
                            icon: <Gen013/>,
                            heading: 'Flexible schedules with Door OTR',
                            paragraph: `Using Door OTRs allows for different schedules to be assigned to different areas of a facility for specified durations without affecting the main schedule, ensuring security and optimizing the flow of people and resources in a facility. It also allows for adjustments to be made as needed for special events or temporary access.`,

                        },
                        {
                            icon: <Map002/>,
                            heading: 'Active maps',
                            paragraph: `Hartmann offers interactive maps in our access control software to create a visual representation of the floors of buildings. This includes doors, elevator floors, cameras, and more. With these active maps, administrators can monitor all aspects of the system. They can also execute action plans like lockdowns with the click of a button anywhere in the world to change the facilities’ state.`,

                        },
                        {
                            icon: <Txt009/>,
                            heading: 'Additional features',
                            paragraph: `Healthcare facilities may also benefit from having multiple partitions, each for a specific wing or department with its own administrator. As well as automated schedules, unmanaged door monitoring, and easy integration with other systems such as IP Bell and much more!`,

                        },
                    ]
                } />
            
        </>
    )
}

export default Healthcare