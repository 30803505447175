import axios, { AxiosError } from 'axios'
import React, { Fragment, useState } from 'react'
import { ContactRequest } from '../Types'
import { validateCompanyName, validateEmail, validateName, validatePhoneNumber } from '../utility'

type Props = {}

function Contact({ }: Props) {
    const [fields, setFields] = useState<ContactRequest>({ name: "", email: "", phoneNumber: "", companyName: "", contactReason: "", product: "", message: "" })
    const setField = (field: string, value: string) => {
        setFields({ ...fields, [field]: value })
    }
    const [err, setErr] = useState<string>('')

    let isValid = () => {
        let [result, msg] = validateName(fields.name)
        console.log(err);
        if (!result) {
            setErr(msg)
            return false
        }

        [result, msg] = validatePhoneNumber(fields.phoneNumber)
        if (!result) {
            setErr(msg)
            return false
        }

        [result, msg] = validateEmail(fields.email)
        if (!result) {
            setErr(msg)
            return false
        }

        setErr('')
        return true
    }

    const sendRequest = () => {
        if (!isValid()) return
        console.log(fields)

        axios.post("/api/contact/request", {
            ...fields,
            toWhom: "Sales"
        })
            .then(() => {
                alert("Message sent!");
            })
            .catch((err: AxiosError) => {
                console.log(err)
                alert(err.response?.statusText)
            })

    }
    return (
        <div id="stickyBlockStartPoint" >
            <div className="js-sticky-block"
                data-hs-sticky-block-options='{
                 "parentSelector": "#stickyBlockStartPoint",
                 "breakpoint": "lg",
                 "startPoint": "#stickyBlockStartPoint",
                 "endPoint": "#stickyBlockEndPoint",
                 "stickyOffsetTop": 80,
                 "stickyOffsetBottom": 0
               }'>
                <div className="card card-bordered">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                            {/* <div className="flex-shrink-0">
                                <img className="avatar avatar-circle" src="/images/160x160/img8.jpg" alt="Image Description" />
                            </div> */}
                            <div className="flex-grow-1 ms-3">
                                <h4 className="card-title">Ask me for more information</h4>
                            </div>
                        </div>

                        <form>
                            <div className="mb-2">
                                <label className="form-label visually-hidden">Full Name</label>
                                <input type="text" className="form-control" id="fullNamePropertyOverviewContactForm" placeholder="Full Name" aria-label="Full Name"
                                    onChange={(e) => { setField("name", e.target.value); }} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label visually-hidden">Email</label>
                                <input type="email" className="form-control" id="emailPropertyOverviewContactForm" placeholder="Email" aria-label="Email"
                                    onChange={(e) => { setField("email", e.target.value); }} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label visually-hidden">Phone number</label>
                                <input type="tel" className="form-control" id="phoneNumberPropertyOverviewContactForm" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx"
                                    onChange={(e) => { setField("phoneNumber", e.target.value); }} />
                            </div>

                            <div className="mb-2">
                                <label className="form-label visually-hidden">Property question</label>
                                <textarea className="form-control" id="questionPropertyOverviewContactForm" placeholder="I would like more information about ..." aria-label="I would like more information about ..."
                                    onChange={(e) => { setField("message", e.target.value) }}></textarea>
                            </div>
                            {err != ''
                                ? <div style={{ color: "red" }}>{err}</div>
                                : <Fragment />}
                            <div className="d-grid">
                                <button type="button" className="btn btn-primary" onClick={sendRequest} >Request info</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact