import './styles/main.scss';
import AOS from 'aos';


import { WebsiteShellComponent } from './views/website/WebsiteShellComponent';
import { ToastContainer, toast } from 'react-toastify';
import HolidayHandler from './views/website/holidayHandler';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  AOS.init();

  HolidayHandler()
  return (
      <div>
        <ToastContainer />
        <WebsiteShellComponent />
      </div>
  );
}

export default App;
