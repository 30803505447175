import React, { ChangeEvent } from 'react'
import { useLocation } from "react-router-dom";
import { Position } from '../../Types';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

type Props = {}
interface Values {
    fullName: string;
    email: string;
    phone: string;
    currentCompany: string;
    linkedin: string;
    twitter: string;
    github: string;
    portfolio: string;
    other: string;
    desiredSalary: string;
    availableStartDate: string;
    additionalInfo: string;
}
const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/
const Schema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    currentCompany: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    linkedin: Yup.string().max(50, 'Too Long!'),
    twitter: Yup.string().max(50, 'Too Long!'),
    github: Yup.string().max(50, 'Too Long!'),
    portfolio: Yup.string().max(50, 'Too Long!'),
    desiredSalary: Yup.number().integer('Not an number'),
    availableStartDate: Yup.date().min(new Date(), "Too early"),
    additionalInfo: Yup.string().max(250, 'Too Long!'),
});


export default function CarrersOverview({ }: Props) {
    const location = useLocation();
    let job = location.state as Position

    const [resume, setFile] = React.useState<File | null>(null)

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const file = (event.target as HTMLInputElement).files;
        if (file != null) {
            setFile(file[0])
        }
    }


    return (
        <div className='container content-space-2 content-space-lg-3'>
            <div className="w-lg-75 mx-auto">
                <div className="mb-5">
                    <a className="link" href="/career">
                        <i className="bi-chevron-left small ms-1"></i> Back to Listings
                    </a>
                </div>

                <div className="card card-lg">
                    <div className="card-body">
                        <div className="row justify-content-sm-between align-items-sm-center mb-5">
                            <div className="col-sm mb-3 mb-sm-0">
                                <h1 className="card-title h2">{job.title}</h1>
                                <p className="card-text">{job.location}</p>
                            </div>
                        </div>

                        <div className="mb-5">
                            {job.jobDescription.map(e => {
                                return <p>{e}</p>
                            })}
                        </div>

                        <div className="mb-5">
                            <h2 className="h4">We have:</h2>

                            <ul>
                                {job.weHave.map(e => {
                                    return <li>{e}</li>
                                })}

                            </ul>
                        </div>

                        <div className="mb-5">
                            <h3 className="h4">Requirements:</h3>

                            <ul>
                                {job.requirement.map(e => {
                                    return <li>{e}</li>
                                })}
                            </ul>
                        </div>

                        <div className="mb-5">
                            <h3 className="h4">You have:</h3>

                            <ul>
                                {job.youHave.map(e => {
                                    return <li>{e}</li>
                                })}
                            </ul>
                        </div>

                        <div className="mb-5">
                            <h3 className="h4">Extra awesome:</h3>

                            <ul>
                                {job.extra.map(e => {
                                    return <li>{e}</li>
                                })}

                            </ul>
                        </div>

                        <div className="border-top text-center pt-5 pt-md-9 mb-5 mb-md-9">
                            <h2>Apply for this Job</h2>
                        </div>
                        <Formik
                            initialValues={{
                                fullName: "",
                                email: "",
                                phone: "",
                                currentCompany: "",
                                linkedin: "",
                                twitter: "",
                                github: "",
                                portfolio: "",
                                other: "",
                                desiredSalary: "",
                                availableStartDate: "",
                                additionalInfo: "",
                            }}
                            validationSchema={Schema}
                            onSubmit={(
                                values: Values,
                                { setSubmitting }: FormikHelpers<Values>
                            ) => {
                                let formData = new FormData();
                                formData.append("Position", job.title);
                                //required field
                                formData.append("FullName", values.fullName)
                                formData.append("Email", values.email)
                                formData.append("PhoneNumber", values.phone)
                                //optional field
                                formData.append("AvailableStartDate", values.availableStartDate)
                                formData.append("CurrnetCompany", values.currentCompany)
                                formData.append("Linkedin", values.linkedin)
                                formData.append("Twitter", values.twitter)
                                formData.append("Github", values.github)
                                formData.append("Portfolio", values.portfolio)
                                formData.append("Other", values.other)
                                formData.append("DesiredSalary", values.desiredSalary)
                                formData.append("AdditioanlInfo", values.additionalInfo)
                                if (resume != null) {
                                    formData.append("Resume", resume, resume.name);
                                }
                                axios.post("/api/contact/applyJob", formData, {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    }
                                })
                            }}
                        >
                            {({ errors, touched }) => (

                                <Form id="applyForJob" className="js-validate" data-bitwarden-watching="1">
                                    <div className="mb-5">
                                        <h3>Submit your application</h3>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="resume" className="col-sm-4 col-form-label">Resume/CV<span style={{ color: "red" }}>*</span></label>
                                        <div className="col-sm-8">
                                            <Field type="file" accept="application/pdf, application/msword" className="js-file-attach form-control" id="resume" name="resume" onChange={handleFileUpload} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="fullName" className="col-sm-4 col-form-label">Full name<span style={{ color: "red" }}>*</span></label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="fullName" name="fullName" placeholder="Jacob Williams" aria-label="Jacob Williams" />
                                            {errors.fullName && touched.fullName ? (
                                                <div>{errors.fullName}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="email" className="col-sm-4 col-form-label">Email<span style={{ color: "red" }}>*</span></label>
                                        <div className="col-sm-8">
                                            <Field type="email" className="form-control" id="email" name="email" placeholder="example@site.com" aria-label="example@site.com" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="phone" className="col-sm-4 col-form-label">Phone<span style={{ color: "red" }}>*</span></label>
                                        <div className="col-sm-8">
                                            <Field type="phone" className="form-control" id="phone" name="phone" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="currentCompany" className="col-sm-4 col-form-label">Current company</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="currentCompany" name="currentCompany" placeholder="Google" aria-label="Google" />
                                        </div>
                                    </div>

                                    <hr className="my-5 my-sm-10" />

                                    <div className="mb-5">
                                        <h3>Links</h3>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="linkedin" className="col-sm-4 col-form-label">LinkedIn URL</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="linkedin" name="linkedin" placeholder="www.linkedin.com/in/jacob" aria-label="www.linkedin.com/in/jacob" />
                                            {errors.linkedin && touched.linkedin ? (
                                                <div>{errors.linkedin}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="twitter" className="col-sm-4 col-form-label">Twitter URL</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="twitter" name="twitter" placeholder="www.twitter.com/jacob" aria-label="www.twitter.com/jacob" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="github" className="col-sm-4 col-form-label">GitHub URL</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="github" name="github" placeholder="www.github.com/jacob" aria-label="www.github.com/jacob" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="portfolio" className="col-sm-4 col-form-label">Portfolio URL</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="portfolio" name="portfolio" placeholder="www.mysite.com/jacob" aria-label="www.mysite.com/jacob" />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="other" className="col-sm-4 col-form-label">Other website</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="other" name="other" placeholder="www.site.com" aria-label="www.site.com" />
                                        </div>
                                    </div>

                                    <hr className="my-5 my-sm-10" />

                                    <div className="mb-5">
                                        <h3>Before sending your application, please let us know...</h3>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="desiredSalary" className="col-sm-4 col-form-label">Desired Salary</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="desiredSalary" name="desiredSalary" placeholder="Type your response" aria-label="Type your response" />
                                            {errors.desiredSalary && touched.desiredSalary ? (
                                                <div>{errors.desiredSalary}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="availableStartDate" className="col-sm-4 col-form-label">Available Start Date</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control" id="availableStartDate" name="availableStartDate" placeholder="mm/dd/yyyy" aria-label="Type your response" />
                                            {errors.availableStartDate && touched.availableStartDate ? (
                                                <div>{errors.availableStartDate}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <hr className="my-5 my-sm-10" />

                                    <div className="mb-5">
                                        <h3>Additional information</h3>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="additionalInfo" className="form-label visually-hidden">Additional information</label>
                                        <Field as="textarea" className="form-control" name="additionalInfo" id="additionalInfo" placeholder="Add a cover letter or anything else you want to share." aria-label="Add a cover letter or anything else you want to share." rows={5} />
                                    </div>

                                    <div className="d-grid text-center mt-7">
                                        <button className="btn btn-primary" type="submit">Submit application</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}