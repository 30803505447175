import React from 'react'
import Gallery from '../../../components/career/Gallery'
import IconBlocks from '../../../components/career/IconBlocks'
import JobList from '../../../components/career/JobList'

type Props = {}

export default function
    ({ }: Props) {
    return (
        <div>
            <Gallery />

            <IconBlocks />

            <div className="border-top mx-auto" style={{ maxWidth: "25rem" }}></div>

            <div className="container content-space-2 content-space-lg-3">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <span className="text-cap">CAREERS AT HARTMANN</span>
                    <h2>We are always looking for talented individuals</h2>
                </div>

                <div className="w-md-75 mx-md-auto">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="pe-lg-4">
                                <p>There is no one kind of employee we are looking for. Hartmann employees are talented and valued team members from all different backgrounds.</p>

                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="ps-lg-4">
                                <p>If you're interested in a career at Hartmann check out our jobs board or give us a call. We have careers in a variety of fields, ranging from software/hardware developers to product trainers and marketing staff.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <JobList />

        </div>
    )
}