import React, { ChangeEvent, Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../components/Blog/useFetch';
import { ProductItem, ProductCard, FetchResponse, Category } from "../../../Types";
import Product from './Product';
import { Link } from 'react-router-dom';
import ProductItemCard from './ProductItemCard';
import axios from 'axios';

type Props = {}

export default function ProductListComponent({ }: Props): ReactElement {
    const { t, i18n } = useTranslation();
    const [perPage, setPerPage] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [category, setCategory] = useState<Category[]>([]);
    const [select, setSelect] = useState<number>(1);
    const { loading, error, results, hasMore } = useFetch<ProductCard>("/api/product", perPage, pageNum, { categoryId: select });
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback((node:any) => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNum(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])
    const [el,setEl]= useState<HTMLElement>();

    function handleSelected(event: React.MouseEvent<HTMLButtonElement>) {
        if (event.currentTarget.parentElement!=null){
            if(el!=null){
                el.className="dropdown-item"
            }
            setEl(event.currentTarget.parentElement)
            event.currentTarget.parentElement.className ="dropdown-item active"
        }
        let val = Number(event.currentTarget.value)
        if (val == select) {
            return
        }
        setSelect(val)
        setPageNum(1);
    }
    useEffect(() => {
        axios.get<Category[]>("/api/category")
            .then(res => {
                setCategory(res.data)
            })
            .catch(err => {
                console.log(err)
            });
    }, [])

    return (
        <div className='container content-space-2 content-space-lg-3'>
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <h2>{t('product.Browse more products')}</h2>
            </div>
            <h2>{t('product.Category')}</h2>

            <div className="row col-lg-divider gx-2 gx-md-3 mb-7">
                <nav className="col-lg-3 col-md-4 mb-2 mb-md-0">
                    <div className="navbar-expand-lg">
                        <div className="d-grid">
                            <button type="button" className="navbar-toggler btn btn-white mb-3" data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenuEg1" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenuEg1">
                                <span className="d-flex justify-content-between align-items-center">
                                    <span className="text-dark">{t('menu')}</span>

                                    <span className="navbar-toggler-default">
                                        <i className="bi-list"></i>
                                    </span>

                                    <span className="navbar-toggler-toggled">
                                        <i className="bi-x"></i>
                                    </span>
                                </span>
                            </button>
                        </div>

                        <div id="navbarVerticalNavMenuEg1" className="collapse navbar-collapse">
                            <div className="d-grid gap-4 flex-grow-1">
                                <div className="d-grid">
                                    <h3 className="dropdown-header">{t('explore')}</h3>
                                    {category.map(c => (
                                        <a className={`dropdown-item`} key={c.categoryId}>
                                            <button className="btn btn-link px-0 py-0" value={c.categoryId} onClick={handleSelected} style={{color:"black"}}>
                                                {t('product.' + c.category)}
                                            </button>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="col-lg-9 col-md-8 mb-2 mb-md-0">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-5">
                                        
                        {results.length !== 0 && results.map((e, index) => (
                            <div key={e.id}>
                                {
                                    results.length === index + 1 ?
                                        <div ref={lastElementRef} >
                                            <ProductItemCard product={e}></ProductItemCard>
                                        </div>
                                        :
                                        <div >
                                            <ProductItemCard product={e}></ProductItemCard>
                                        </div>
                                }
                            </div>
                        ))}
                    </div>
                </section>
            </div>

        </div>
    )
}