import React from 'react';
import { nanoid } from 'nanoid';
import { Link, NavLink } from 'react-router-dom';
interface BreadcrumbProps {
    breadcrumbObject: { text: string, link?: string }[],
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
    return (
        <React.Fragment>
            <div className="row align-items-lg-center">
                <div className="col-lg mb-2 mb-lg-0">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            {
                                props.breadcrumbObject.map((object, index) => {
                                    return (
                                        <li key={nanoid()} className="breadcrumb-item active" aria-current="page">
                                            {object.link?<Link to={object.link}>{object.text}</Link>:object.text}
                                        </li>
                                    );
                                })
                            }
                        </ol>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Breadcrumb