import React, { ReactElement } from 'react';
import wavepattern from '../assets/svg/components/wave-pattern-light.svg';
import {ReactComponent as Abs028} from '../assets/svg/duotone-icons/abs028.svg'
import { nanoid } from 'nanoid';


type Paragraph = {
    heading: string,
    paragraph: string,
    icon?: ReactElement
}

type Props = {
    sectionHeading?: string,
    sectionSubHeading?: string,
    backgroundPattern?: string,
    paragraphs: Paragraph[],
};

const SectionPopOutWithParagraphs: React.FC<Props> = (props) => {
    return (
        <>
            <div className="bg-dark rounded-2 mx-3 mx-xl-10" style={{ backgroundImage: `url('${props.backgroundPattern? props.backgroundPattern: wavepattern}')` }}>
                <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                    <div className="">
                        <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                            <span className="text-cap text-white-70">{props.sectionSubHeading}</span>
                            <h2 className="text-white">{props.sectionHeading}</h2>
                        </div>
                        <div className="row mb-5 mb-sm-9">
                            {
                                props.paragraphs.map((paragraph, id) => {
                                    return (
                                        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7" key={nanoid()}>
                                            <span className="svg-icon text-white mb-3">
                                                {
                                                    paragraph.icon!=null
                                                    ?paragraph.icon
                                                    :<Abs028/>
                                                }
                                                    
                                            </span>
                                            <h4 className="text-white">{paragraph.heading}</h4>
                                            <p className="text-white-70">{paragraph.paragraph}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionPopOutWithParagraphs