import React, { useEffect, useLayoutEffect } from 'react';
import Subscribe from './Subscribe';
import { } from '@fortawesome/free-solid-svg-icons'

import * as dropdown from '../js/vendor/hs.bs-dropdown';
import "../../node_modules/flag-icons/css/flag-icons.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState<String>("English (US)");
    useEffect(() => {
        dropdown.HSBsDropdown.init()

    }, [])
    const changeLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log(i18n.language.substring(3).toLowerCase())
        i18n.changeLanguage(event.currentTarget.value);
        if (event.currentTarget.childNodes[1].firstChild != null && event.currentTarget.childNodes[1].firstChild.nodeValue != null) {
            setLang(event.currentTarget.childNodes[1].firstChild.nodeValue)
        }

    }

    return (
        <React.Fragment>
            {/* <Subscribe /> */}
            <footer className="bg-light">
                <div className="container pb-1 pb-lg-7">
                    <div className="row content-space-t-2">
                        <div className="col-lg-3 col-12 col ">
                            <div className="mb-5">
                                <a className="navbar-brand" href="#" aria-label="Space">
                                    <img className="navbar-brand-logo" src="/images/Logos/logo50px-dark.svg" alt="Image Description" />
                                </a>
                            </div>
                            <ul className="list-unstyled list-py-1">
                                <li><a className="link-sm link-secondary" href="https://goo.gl/maps/yF4bimMydPt83cPbA" target="_blank" rel="nonreferrer"><i className="bi-geo-alt-fill me-1"></i> 10 Lockhart Rd, Barrie, ON L4N 9G8</a></li>
                                <li><a className="link-sm link-secondary" href="tel:1-877-411-0101"><i className="bi-telephone-inbound-fill me-1"></i> +1 (877) 411-0101</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 mb-7 mb-sm-0">
                            <h5 className="mb-3">Company</h5>

                            <ul className="list-unstyled list-py-1 mb-0">
                                <li><Link className="link-sm link-secondary" to="/company/aboutus">About</Link></li>
                                <li><Link className="link-sm link-secondary" to="/career">Careers </Link></li>
                                <li><Link className="link-sm link-secondary" to="/contact">Contact us </Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 mb-7 mb-sm-0">
                            <h5 className="mb-3">Solutions</h5>

                            <ul className="list-unstyled list-py-1 mb-0">
                                <li><Link className="link-sm link-secondary" to="/integrations">Integrations</Link></li>
                                <li><Link className="link-sm link-secondary" to="/products">All products</Link></li>
                                <li><Link className="link-sm link-secondary" to="/casestudies/all">Case studies</Link></li>
                            </ul>
                        </div>

                        {/* <div className="col-lg-2 col-sm-6">
                            <h5 className="mb-3">Documentation</h5>

                            <ul className="list-unstyled list-py-1 mb-0">
                                <li><a className="link-sm link-secondary" href="#">Support</a></li>
                                <li><a className="link-sm link-secondary" href="#">Docs</a></li>
                                <li><a className="link-sm link-secondary" href="#">Status</a></li>
                                <li><a className="link-sm link-secondary" href="#">API Reference</a></li>
                                <li><a className="link-sm link-secondary" href="#">Tech Requirements</a></li>
                            </ul>
                        </div> */}

                        <div className="col-lg-2 col-6">
                            <h5 className="mb-3">Resources</h5>

                            <ul className="list-unstyled list-py-1 mb-5">
                                <li><a className="link-sm link-secondary" href="https://portal.hartmann-controls.com" target="_blank" rel="nonreferrer"> Dealer portal <i className="bi-box-arrow-up-right small ms-1"></i></a></li>
                                <li><Link className="link-sm link-secondary" to="/resources/instructional-videos"> Instructional videos</Link></li>
                                <li><Link className="link-sm link-secondary" to="/resources/softwaredownloads"> Software downloads</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="col-sm-auto">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.facebook.com/people/Hartmann-Controls/100063940153694/">
                                            <i className="bi bi-facebook"></i>
                                        </a>
                                    </li>

                                    <li className="list-inline-item">
                                        <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://www.google.com/search?q=hartmann+controls">
                                            <i className="bi bi-google"></i>
                                        </a>
                                    </li>

                                    <li className="list-inline-item">
                                        <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://twitter.com/hartmanncontrol">
                                            <i className="bi bi-twitter"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="btn btn-soft-secondary btn-xs btn-icon" href="https://ca.linkedin.com/company/hartmann-controls-corp-">
                                            <i className="bi bi-linkedin"></i>
                                        </a>
                                    </li>



                                    {/* <li className="list-inline-item">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-soft-secondary btn-xs dropdown-toggle" id="footerLightSelectLanguage" data-bs-toggle="dropdown" aria-expanded="false" data-bs-dropdown-animation>
                                            <span className="d-flex align-items-center">
                                                <span className={`avatar avatar-xss avatar-circle me-2 fi fi-${i18n.language.substring(3).toLowerCase()}`}></span>
                                                <span>{lang}</span>
                                            </span>
                                        </button>

                                        <div className="dropdown-menu" aria-labelledby="footerLightSelectLanguage">
                                            <button className="dropdown-item d-flex align-items-center" value={"en-US"} onClick={changeLanguage}>
                                                <span className="avatar avatar-xss avatar-circle me-2 fi fi-us"></span>
                                                <span>English (US)</span>
                                            </button>
                                            <button className="dropdown-item d-flex align-items-center" value={"fr-FR"} onClick={changeLanguage}>
                                                <span className="avatar avatar-xss avatar-circle me-2 fi fi-fr"></span>
                                                <span>Français</span>
                                            </button>
                                            <button className="dropdown-item d-flex align-items-center" value={"es-ES"} onClick={changeLanguage}>
                                                <span className="avatar avatar-xss avatar-circle me-2 fi fi-es"></span>
                                                <span>Español</span>
                                            </button>
                                        </div>
                                    </div>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="border-top my-7">
                        <div className="w-md-86 text-lg-center mx-lg-auto">
                            <p className="text-muted small">© Hartmann Controls Corp 2023. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;