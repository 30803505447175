import axios from 'axios'
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Position } from '../../Types';
import JobModal from './JobModal';
import jobPosting  from '../../assets/job.json';

type Props = {
}

export default function JobList(props: Props) {
  const [modalShow, setModalShow] = React.useState(false);

  const [positions, setPosition] = useState<Position[]>();

  let fetch = async(url: string) => {
    let response = await axios.get<Position[]>(url);
    setPosition(response.data)
  }
  useEffect(() => {
    // fetch("/api/job");
  }, [])
  const [field,setField]=useState();
  console.log(jobPosting)

  return (
    <div id="openRolesSection" className="container content-space-b-1 content-space-b-md-3">
      <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2>Current opportunities</h2>
        <p>Find your next career move with our current job openings.</p>
      </div>


      {/* <ul className="list-group list-group-lg">
        {
          jobPosting==undefined?
          <li className="list-group-item" key={nanoid()}>

          <div className='text-center'>No current opportunities</div>
          </li>
          :
          jobPosting?.map(p => {
            
            return <li className="list-group-item" key={nanoid()}>
              <div className="row">
                <div className="col-md-2 mb-2 mb-md-0">
                  <span className="h6">{p.title}</span>
                </div>

                <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                  <span>{p.location}</span>
                </div>

                <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                  <span>{p.department}</span>
                </div>

                <div className="col-sm-2 text-sm-end">
                  <Link to="./overview" state={p}>Apply <i className="bi-chevron-right small ms-1 small ms-1"></i></Link>
                </div>
              </div>
            </li>
          })
        }
      </ul> */}

      <div className="w-lg-65 text-center mx-lg-auto mt-7">
        <p>Don't worry if you don't see any roles you want to apply for now. Register your interest to be notified of any roles that come along that meet your criteria.</p>
        {/* <a className="btn btn-outline-primary" href="./page-contacts-agency.html">Register your interest</a> */}
        <Button variant="primary"  onClick={()=>setModalShow(true)}>
          Register your interest
        </Button>

      </div>
      <JobModal show={modalShow} onHide={setModalShow}/> 
      
    </div>
  )
}