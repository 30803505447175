import { nanoid } from 'nanoid';
import React from 'react'

type Props = {
    description:string,
    bulletPoints:string[],
    image:string
}

export default function ContentSections15({description, bulletPoints,image }: Props) {
    let med=bulletPoints.length/2;
    let first:string[]=bulletPoints.slice(0,Math.ceil(med))
    let second:string[]=bulletPoints.slice(-med)
    return (
        <div className="container content-space-1">
            <div className="row">
                <div className="col-md-7 col-lg-12">
                    <img className="img-fluid w-lg-50 mx-auto d-block" src={image} alt="Image Description"
                                                style={{ borderRadius: "0px", borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} />
                    <p>{description}</p>
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="list-checked list-checked-primary">
                                {
                                    first.map(e=><li className="list-checked-item my-5" key={nanoid()}>{e}</li>)
                                }
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul className="list-checked list-checked-primary">
                                {
                                    second.map(e=><li className="list-checked-item my-5" key={nanoid()}>{e}</li>)
                                }
                            </ul>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}