import React from 'react';
import HalfPageHeroImage from '../../../components/halfPageHeroImage';
import HeroImageWithText from '../../../components/heroImageWithText';
import ImageCollage3x2 from '../../../components/imageCollage3x2';
import ImageSideBySideWithText from '../../../components/imageSideBySideWithText';
import ReadMoreWithPics from '../../../components/readMoreWithPics';
import SectionPopOutWithParagraphs from '../../../components/sectionPopOutWithParagrapsh';
import wavepattern from '../../../assets/svg/components/wave-pattern-light.svg';
import { useTranslation } from 'react-i18next';
import {ReactComponent as Gra004} from '../../../assets/svg/duotone-icons/gra004.svg'
import { ReactComponent as Abs027 } from '../../../assets/vendor/duotone-icons/abs/abs027.svg';
import { ReactComponent as Abs011 } from '../../../assets/vendor/duotone-icons/abs/abs011.svg';
import { ReactComponent as Fil002 } from '../../../assets/vendor/duotone-icons/fil/fil002.svg';
import { ReactComponent as Map002 } from '../../../assets/vendor/duotone-icons/map/map002.svg';
import { ReactComponent as Gen007 } from '../../../assets/vendor/duotone-icons/gen/gen007.svg';
import { ReactComponent as Fil001 } from '../../../assets/vendor/duotone-icons/fil/fil001.svg';

const Education: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <HalfPageHeroImage image='/images/1920x800/Edu-Header.jpg' heading={t('education.Educational Institutions')} paragraph={"Securing the future - Integrated solutions that provide peace of mind."} headingBelow={false} />
            <div className="container-xl container-fluid content-space-1 content-space-md-2 px-4 px-md-8 px-lg-10">
                <div className="w-lg-75 text-center mx-lg-auto">
                    <div className="mb-5 mb-md-10">
                        <p className="lead">Access control is an important aspect of security in educational institutions, such as schools and universities. It can be used to restrict access to the facility and certain areas within it, such as classrooms, labs, and administrative offices, to authorized personnel only. This helps to minimize the risk of unauthorized access and potential security threats, such as violence or intrusions, ensuring the safety and well-being of students, staff, and visitors.</p>
                        <p className="lead">Many educational institutions have specific security requirements that they must adhere to. Hartmannʼs access control systems can help to ensure compliance with these regulations by tracking and monitoring access to the facility and certain areas within it, such as classrooms, labs, and administrative offices. Keeping records of who enters and exits the building, it can be used for monitoring attendance and detecting any issues.</p>
                        <p className="lead">Hartmann offers complete access control solutions to help protect students, educators, and the assets of the educational institution on the property.</p>
                    </div>
                </div>
                <div className="row gx-3">

                    <div className="w-100"></div>

                    <div className="col mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - education 3.jpg')`, height: "15rem" }}></div>
                    </div>

                    <div className="col-4 d-none d-md-block mb-3 mb-md-0">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - education 1.jpg')`, height: "15rem" }}></div>
                    </div>

                    <div className="col">
                        <div className="bg-img-start" style={{ backgroundImage: `url('/images/480x320/480x320 - education 2.jpg')`, height: "15rem" }}></div>
                    </div>
                </div>
            </div>



            <div className="container content-space-1">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <h2 className="h1">Key Benefits</h2>
                    <p>Using Hartmann’s Access Control in Educational Institutions</p>
                </div>

                <div className="row gx-3 mb-5 mb-md-9">
                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/prevent-access.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Prevent unauthorized access</span>
                                <div className="card-text lh-base">By implementing access control, you can prevent unauthorized users from accessing sensitive information or resources.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/tracking.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Auditing and tracking</span>
                                <div className="card-text lh-base">Receive detailed logs of who accesses what resources and when. It can also be useful for auditing and tracking purposes.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/safety.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Student and educator safety</span>
                                <div className="card-text lh-base">Access control provides a safer environment. This can be particularly important for institutions that serve vulnerable populations, such as young children and their educators.</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card card-transition h-100" >
                            <img className="card-img-top" src="/images/education/efficiency.png" alt="Image Description" />
                            <div className="card-body">
                                <span className="card-subtitle fw-bold text-dark fs-5">Improved efficiency</span>
                                <div className="card-text lh-base">Having authorized access can improve the overall efficiency of your educational institution, as it reduces the need for security personnel to manually check IDs or monitor entry points.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SectionPopOutWithParagraphs backgroundPattern={wavepattern}
                sectionHeading={"Hartmann tools catered for educational institutions."} sectionSubHeading={""}
                paragraphs={
                    [
                        {
                            icon:<Gra004/>,
                            heading: "Lockdown and Crisis Levels",
                            paragraph: "The Lockdown feature provides a quick and efficient way to secure a facility in case of a security breach or emergency situation. When a lockdown is activated, all doors controlled by Hartmannʼs access control system can be locked, preventing anyone from entering the affected areas except for authorized users based on their crisis-level permissions set by the administrator.",

                        },
                        {
                            icon:<Abs027/>,
                            heading: "Auto-sync users through LDAP Integration",
                            paragraph: "Access Privilege Groups associated with an LDAP group are synced, giving LDAP users permission to access doors and elevators. LDAP integration features group and administrator synchronization, conflict notification, instant disabling of cards and users, and much more.",

                        },
                        {
                            icon:<Fil002/>,
                            heading: "Flexible schedules with Door OTR",
                            paragraph: "Using Door OTRs allows for different schedules to be assigned to different areas of a facility for specified durations without affecting the main schedule, ensuring security and optimizing the flow of people and resources in a facility. It also allows for adjustments to be made as needed for special events or temporary access.",

                        },
                        {
                            icon:<Map002/>,
                            heading: "Active Maps",
                            paragraph: "Hartmann offers interactive maps in our access control software to visually represent the floors of buildings. This includes doors, elevator floors, cameras, and more. With these active maps, administrators can monitor all aspects of the system. They can also execute action plans like lockdowns with the click of a button anywhere in the world to change the school state.",

                        },
                        {
                            icon:<Gen007/>,
                            heading: "Notifications",
                            paragraph: "Alert notifications are essential and ensure no notifications are missed. They provide real-time information from late employees to notify administrators of doors forced or held open. One or more administrators will then be able to respond and acknowledge the event, ensuring the system is always operational and secure.",

                        },
                        {
                            icon:<Fil001/>,
                            heading: "Additional features",
                            paragraph: "School boards may also benefit from having multiple partitions, each for an institution with its own administrators. As well as automated schedules, unmanaged door monitoring, easy integration with other systems such as IP Bell and much more!",

                        },
                    ]
                } />
            {/* <ImageSideBySideWithText
                alignChild='right'
                image={"/images/600x400/600x400 - Jewish holidays.jpg"}
            >
                <>
                    <div className="mb-5">
                        <h2 className="mb-3">Jewish Holiday Case Study</h2>
                        <p>Read how Hartmann has created a script to keep exterior doors secured on major Jewish Holidays.</p>
                    </div>

                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                        <li className="list-checked-item">One time configuration</li>
                        <li className="list-checked-item">Fully Automated</li>
                    </ul>
                </>
            </ImageSideBySideWithText>
            <ImageSideBySideWithText
                alignChild='left'
                image={"/images/600x400/600x400 - Synagogue.jpg"}
            >
                <>
                    <div className="mb-5">
                        <h2 className="mb-3">Shabbat Case Study</h2>
                        <p>Read how the Hartmann team created a script to create easier access when a Synagogue observes Shabbat.</p>
                    </div>

                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                        <li className="list-checked-item">Fully Automated</li>
                        <li className="list-checked-item">DTZ Modifications</li>
                        <li className="list-checked-item">Resets Weekly</li>
                    </ul>
                </>
            </ImageSideBySideWithText>
            <ImageSideBySideWithText
                alignChild='right'
                image={"/images/600x400/img1.jpg"}
            >
                <>
                    <div className="mb-5">
                        <h2 className="mb-3">See How we helped this group</h2>
                        <p>https://www.youtube.com/watch?v=yeaV1l3NTsI</p>
                    </div>

                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                        <li className="list-checked-item">Extensive API documentation</li>
                        <li className="list-checked-item">Customizable & open source</li>
                        <li className="list-checked-item">Continiously improved</li>
                    </ul>
                </>
            </ImageSideBySideWithText>
            <ReadMoreWithPics heading='Results'
                images={['/images/900x900/img1.jpg', '/images/900x900/img1.jpg', '/images/900x900/img1.jpg',
                    '/images/900x900/img1.jpg', '/images/900x900/img1.jpg', '/images/900x900/img1.jpg']}
                labels={[
                    {
                        key: 'Founded',
                        value: '2009'
                    },
                    {
                        key: 'Company Size',
                        value: '150-300'
                    },
                    {
                        key: 'Fun?',
                        value: 'Yes'
                    },
                    {
                        key: 'Foosball',
                        value: 'Obviously'
                    },
                ]}
                paragraph="Our ACE scripting engine made every challenge possible with the use of 3rd party APIs. Implementing these features has saved building managers precious time and procided them with the peace of mind that their buildings are secure."
                readMore="The holiday script imports all names, dates, and descriptions from the Hebcal Jewish calendar API. That information is then used to create and apply holidays occuring yearly without any admin assistance. The Shabbat/Sabbath script runs weekly and reaches out to a 3rd party API called sunrise-sunset.org to get the sunrise and sunset based on the Synagogue's GPS coordinates and rewrote our Door Timezones based on the response we received" />
         */}
        
        </>
    );
}

export default Education;