import 'core-js/actual';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import './assets/theme/theme.css';
import App from './App';
import ScrollToTop from './views/website/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <ScrollToTop/>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>,
);