import { nanoid } from 'nanoid';
import React, { Fragment } from 'react'

type Props = {
    tabs: {
        image: JSX.Element;
        heading: string;
        paragraph: string;
    }[];
}

export default function horizontalNavTabs(props: Props) {
    return (
        <Fragment>
            <div className="row align-items-lg-center">
                <div className="js-nav-scroller hs-nav-scroller-horizontal">
                    <span className="hs-nav-scroller-arrow-prev" style={{ display: "none" }}>
                        <a className="hs-nav-scroller-arrow-link" href="#">
                            <i className="bi-chevron-left"></i>
                        </a>
                    </span>

                    <span className="hs-nav-scroller-arrow-next" style={{ display: "none" }}>
                        <a className="hs-nav-scroller-arrow-link" href="#">
                            <i className="bi-chevron-right"></i>
                        </a>
                    </span>
                    <ul className="nav nav-segment nav-pills nav-fill" id="propertyOverviewNavTab" role="tablist">
                        {
                            props.tabs?.map((tab, index) => {
                                return (
                                    <li key={index} className="nav-item" role="presentation">
                                        <a className={`py-3 nav-link ${index === 0 ? 'active' : ''}`}
                                            href={`#hardware${index}`}
                                            id={`#hardware${index}-tab`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#hardware${index}`}
                                            role="tab"
                                            aria-controls="propertyOverviewNavOne"
                                            aria-selected="true"
                                            style={{ minWidth: "7rem" }}>
                                            <div className='fw-bold text-start px-7'>
                                                {tab.heading}
                                            </div>
                                            <div className='text-start px-7'>
                                                {tab.paragraph}
                                            </div>

                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className="tab-content" id="featuresTabContent">
                    {
                        props.tabs.map((tab, index) => {
                            return (
                                <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`hardware${index}`} role="tabpanel" aria-labelledby={`hardware${index}-tab`} key={nanoid()}>
                                    {tab.image}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
}