import { PostingCard } from '../../Types'

type Props = {
    post:PostingCard
}

function BlogCard(props: Props) {
    return (
            <div className="card h-100">
                <img className="card-img-top" src={props.post.image} alt="Image Description"></img>
                <div className="card-body d-flex flex-column">
                    <div className="mb-3">
                        <img className="avatar avatar-lg avatar-4x3" src="../assets/svg/brands/mapbox.svg" alt="Logo" />
                    </div>
                    <p className="card-text">{props.post.title}</p>
                </div>

                <div className="card-footer pt-0">
                    <a className="card-link" href={`blog/${props.post.id}`}>Read story <i className="bi-chevron-right small ms-1"></i></a>
                </div>
            </div>
    )
}

export default BlogCard