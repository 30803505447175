import ReactMarkdown from 'react-markdown'
import holiday from '../../assets/holidays.json'
import { toast } from 'react-toastify'

type Props = {}


const HolidayHandler = () => {
    let filtered = holiday.Holidays.filter(h=>{
        let d = new Date();
        let today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        let sd = h.StartDate.split('-');
        let start = new Date(Number(sd[0]), Number(sd[1]) - 1, Number(sd[2]));
        let ed = h.EndDate.split('-');
        let end = new Date(Number(ed[0]), Number(ed[1]) - 1, Number(ed[2]));
        return today >= start && today <= end
    })

    let msg = filtered.map(h => {
            switch (h.Type) {
                case "Christmas":
                    return holiday.Messages.Christmas.replace("{0}", h.Description as string)
                case "Holiday":
                    return holiday.Messages.Holiday.replace("{0}", h.Date as string).replace("{1}", h.Description as string)
                case "Summer":
                    return holiday.Messages.Summer
                default:
            }
        }
    )
    
    msg.map((m,idx)=> toast.info(`${m}`, {
        position:toast.POSITION.TOP_RIGHT,
        toastId:idx,
        closeOnClick:true,
        autoClose:40000,
        draggable:true,
        rtl:false,
        pauseOnFocusLoss:true,
        theme: 'light',
    }))
    
}

export default HolidayHandler