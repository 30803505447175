import axios, { AxiosError } from 'axios'
import React, { useState } from 'react'
import { Button, Form, FormGroup, Modal } from 'react-bootstrap'

type Props = {
    show: boolean,
    onHide: any
}
type Apply = {
    name: string
    email: string
    interested: string
}

export default function JobModal({ show, onHide }: Props) {
    const [fields, setFields] = useState<Apply>({ name: "", email: "", interested: "" })
    const setField = (field: string, value: string) => {
        setFields({ ...fields, [field]: value })
    }
    const sendRequest = async () => {

        axios.post("/api/contact/interested", {
            ...fields,
        })
            .then(() => {
                alert("Message sent!");
            })
            .catch((err: AxiosError) => {
                console.log(err)
                alert(err.response?.statusText)
            })
    }
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Register and get notification!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="John Doe"
                            autoFocus
                            onChange={(e) => setField("name", e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Johndoe@google.com"
                            autoFocus
                            onChange={(e) => setField("email", e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                        </Form.Control.Feedback>
                    </Form.Group>
                    <FormGroup>
                        <Form.Label>Interested in</Form.Label>
                        <Form.Select onChange={(e) => setField("interested", e.currentTarget.value)} >
                            <option key={1} value={"Sales"}>Sales</option>
                            <option key={2} value={"Tech support"}>Tech support</option>
                            <option key={3} value={"R&D"}>R&D</option>
                            <option key={4} value={"Development"}>Development</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                        </Form.Control.Feedback>
                    </FormGroup>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => sendRequest()}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}