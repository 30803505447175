export const CaseStudyCategories = ["All","Commercial", "Jewish", "Covid", "Healthcare"];

export const CaseStudies = [
    {
        Id: '1',
        Title: "Billy Bishop Airport",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-billybishop.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '2',
        Title: "Canadian Holidays",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-canadianholidays.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '3',
        Title: "Country Club",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-countryclub.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '4',
        Title: "Covid screening",
        Desc: 'PROTECTOR.Net',
        Industry: '["Covid"]',
        Thumbnail: "/images/400x500/400x500-covidscreening.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '5',
        Title: "Gym",
        Desc: 'PROTECTOR.Net',
        Industry: '["Healthcare"]',
        Thumbnail: "/images/400x500/400x500-gym.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '6',
        Title: "Jewish Holidays",
        Desc: 'PROTECTOR.Net',
        Industry: '["Jewish"]',
        Thumbnail: "/images/400x500/400x500-jewishholidays.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '7',
        Title: "Museum",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-museum.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '8',
        Title: "Pharmaceutical Industry",
        Desc: 'PROTECTOR.Net',
        Industry: '["Healthcare"]',
        Thumbnail: "/images/400x500/400x500-pharmaceutical.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '9',
        Title: "Spa",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-spa.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '10',
        Title: "Synagogue",
        Desc: 'PROTECTOR.Net',
        Industry: '["Jewish"]',
        Thumbnail: "/images/400x500/400x500-synagogue.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '11',
        Title: "Vaccine Status",
        Desc: 'PROTECTOR.Net',
        Industry: '["Covid"]',
        Thumbnail: "/images/400x500/400x500-vaccinestatus.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
    {
        Id: '12',
        Title: "Warehouse",
        Desc: 'PROTECTOR.Net',
        Industry: '["Commercial"]',
        Thumbnail: "/images/400x500/400x500-warehouse.jpg",
        Location: "Seattle, Washington, U.S",
        Notes: "Build campaigns that bring in quality leads who convert from free to paid.",
        Features: [
            {
                Title: "Amazon Wallet",
                Text: "Send targeted currencies to the right people at the right time."
            },
            {
                Title: "Amazon Fire TV",
                Text: "Amazon Fire TV is a digital media player and its microconsole remote."
            },
        ],
    },
];
