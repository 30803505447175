import React from 'react'

type Props = {}

export default function IconBlocks({ }: Props) {
    return (
        <div className="container">
            <div className="border-bottom content-space-2 content-space-lg-3">
                {/* <!-- Heading --> */}
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <span className="d-block small font-weight-bold text-cap mb-2">Company values</span>
                    <h2>The Hartmann culture</h2>
                </div>
                {/* <!-- End Heading --> */}

                <div className="row">
                    <CultureCard head={"Rewarding environment"} para={"An environment in which you strive to be your best self because you know you're part of something great."}></CultureCard>
                    <CultureCard head={"Growth mindset"} para={"We love people who aim for greatness. Inspire and excite your teammates, and raise the bar for all of us."}></CultureCard>
                    <CultureCard head={"Teamwork"} para={"Issues are bound to happen, but everyone at Hartmann is willing to give a helping hand to help you solve any issues."}></CultureCard>
                    <CultureCard head={"Vibrant"} para={"A newly built office space with modern technologies, tasteful esthetics, and a great deal of natural light."}></CultureCard>
                    <CultureCard head={"Work-life balance"} para={"Aside from being amazing to work with, people are eager to meet outside of work for activities like skiing, dinners, and much more."}></CultureCard>
                    <CultureCard head={"Communication and transparency"} para={"We strive to be open and transparent for many reasons, but the abridged version is: it creates trust and makes everything easier."}></CultureCard>
                </div>
            </div>
        </div>
    )
}


const CultureCard = (props: { head: string, para: string }) => {
    return (
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
            {/* <!-- Icon Blocks --> */}
            <div className="d-flex">
                <div className="flex-shrink-0">
                    <span>—</span>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h4>{props.head}</h4>
                    <p>{props.para}</p>
                </div>
            </div>
            {/* <!-- End Icon Blocks --> */}
        </div>
    )
}