import React, { useCallback,useState } from 'react';
import SoftwareDownloadData from '../../../data/softwareDownloadData';
import StarSVG from '../../svg/illustrations/star.svg';
import DownloadModal from '../../../components/downloadModal';
import DocumentationData from '../../../data/DocumentationData';
import SpecificationData from '../../../data/SpecificationData';
import { nanoid } from 'nanoid';
interface SoftawreDownloadComponentProps {
    image: string,
    tag?: string,
    tagColor?: "red" | "orange" | "green",
    header: string,
    paragraph: string,
    link?: string,
    filePath:string,
    onClick: (event: string) => void,
}

const SoftwareDownloadComponent: React.FC<SoftawreDownloadComponentProps> = (props) => {
    return (
    <div className="d-grid gap-2 w-lg-100 mx-lg-auto p-2">
        
        <div className="card card-sm" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div className="card-body">
                <div className="row align-items-sm-center">
                    
                    <div className="col text-center">
                        <img src={props.image} width={196} height={140}></img>
                    </div>

                    <div className="card-pinned-top-start">
                        <small className="badge bg-warning">{props.tag}</small>
                    </div>
                    
                    <div className="col-6">
                        <h4 className="card-subtitle text-body">{props.tag}</h4>
                        <h3 className="card-title text-inherit fs-5">{props.header}</h3>
                        <p className="card-text text-body fs-6">{props.paragraph}</p>
                        <small></small>
                    </div>
                
                <div className="col-12 col-sm-4 col-xl-3 mt-3 mt-sm-0">
                   <div className="d-grid ">
                    <div className="card button btn-alternate" onClick={(event)=>{event.preventDefault(); props.onClick(props.filePath)}}>Download</div>
                </div>
                </div>
            </div>  
        </div>
    </div>
</div>
                    
    )
}

const DocumentationComponent: React.FC<SoftawreDownloadComponentProps> = (props) => {
    return (
    <div className="d-grid gap-2 w-lg-100 mx-lg-auto p-2">
        
        <div className="card card-sm" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div className="card-body">
                <div className="row align-items-sm-center">
                    
                    <div className="col text-left">
                    <h3 className="card-title text-inherit fs-5">{props.header}</h3>
                    </div>

                    <div className="card-pinned-top-start">
                        <small className="badge bg-warning">{props.tag}</small>
                    </div>
                    
                    <div className="col-6">
                        <h4 className="card-subtitle text-body">{props.tag}</h4>
                        <p className="card-text text-body fs-6">{props.paragraph}</p>
                    </div>
                
                <div className="col-12 col-sm-4 col-xl-3 mt-3 mt-sm-0">
                   <div className="d-grid">
                    <div className="card btn btn-outline-primary" onClick={(event)=>{event.preventDefault(); props.onClick(props.filePath)}}>Download</div>
                </div>
                </div>
            </div>  
        </div>
    </div>
</div>
                    
    )
}
const SpecificationComponent: React.FC<SoftawreDownloadComponentProps> = (props) => {
    return (
    <div className="d-grid gap-2 w-lg-100 mx-lg-auto p-2">
        
        <div className="card card-sm" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div className="card-body">
                <div className="row align-items-sm-center">
                    
                    <div className="col text-left">
                    <h3 className="card-title text-inherit fs-5">{props.header}</h3>
                    </div>

                    <div className="card-pinned-top-start">
                        <small className="badge bg-warning">{props.tag}</small>
                    </div>
                    
                    <div className="col-6">
                        <h4 className="card-subtitle text-body">{props.tag}</h4>
                        <p className="card-text text-body fs-6">{props.paragraph}</p>
                    </div>
                
                <div className="col-12 col-sm-4 col-xl-3 mt-3 mt-sm-0">
                   <div className="d-grid">
                    <div className="card btn btn-outline-primary" onClick={(event)=>{event.preventDefault(); props.onClick(props.filePath)}}>Download</div>
                </div>
                </div>
            </div>  
        </div>
    </div>
</div>
                    
    )
}
type DownloadProp={
    data:Data[],
    handler:(t:string)=>void
}

interface Data{
    heading:string,
    desc:string,
    image:string,
    filePath:string
}

const DownloadList = React.memo(function DownloadList(props:DownloadProp){
    const mapped = (data:Data) => <SoftwareDownloadComponent header={data.heading} image={data.image} paragraph={data.desc} key={nanoid()} filePath={data.filePath}
                                        onClick={props.handler} />
    return <div>{props.data.map(mapped)}</div>
})
const DocumentDownloadList = React.memo(function DocumentDownloadList(props:DownloadProp){
    const mapped = (data:Data) => <DocumentationComponent header={data.heading} image={data.image} paragraph={data.desc} key={nanoid()} filePath={data.filePath}
                                        onClick={props.handler} />
    return <div>{props.data.map(mapped)}</div>
})
const SpecDownloadList = React.memo(function SpecDownloadList(props:DownloadProp){
    const mapped = (data:Data) => <SpecificationComponent header={data.heading} image={data.image} paragraph={data.desc} key={nanoid()} filePath={data.filePath}
                                        onClick={props.handler} />
    return <div>{props.data.map(mapped)}</div>
})
const SoftwareDownloads: React.FC = () => {
    const [authedFileToDownload, setAuthedFileToDownload] = useState<string>("");
    
    const memorizedSetter = useCallback((fileName:string)=>
        downloadFile(fileName)
    ,[])

    function clearAuthedFileToDownload() {
        setAuthedFileToDownload("");
    }

    function downloadFile(fileName: string) {
        if (!fileName.toLowerCase().startsWith("/public")) {
            setAuthedFileToDownload(fileName);
        }else{
            window.open("/api/Download/RequestFile" + fileName);
        }
    }

    return (
        <React.Fragment>
            <div className='container content-space-t-3 content-space-t-lg-2 content-space-b-2'>
                <DownloadModal fileToDownload={authedFileToDownload} close={clearAuthedFileToDownload} />
                <h2 className="text-center">Software</h2>
                <div className="container content-space-b-2 content-space-b-lg-3" style={{ marginTop: "100px" }}>
                    <div className="d-grid gap-5">
                        <DownloadList data={SoftwareDownloadData} handler={memorizedSetter}/>
                    </div>
                </div>
                <h2 className="text-center">Documentation</h2>
                <div className="container content-space-b-2 content-space-b-lg-3" style={{ marginTop: "100px" }}>
                    <div className="d-grid gap-5">
                        <DocumentDownloadList data={DocumentationData} handler={memorizedSetter}/>
                    </div>
                </div>
                <h2 className="text-center">Specification</h2>
                <div className="container content-space-b-2 content-space-b-lg-3" style={{ marginTop: "100px" }}>
                    <div className="d-grid gap-5">
                        <SpecDownloadList data={SpecificationData} handler={memorizedSetter}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SoftwareDownloads;