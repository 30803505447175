import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RouteProps } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Posting, FindResponse } from '../../Types';
import ReactMarkdown from 'react-markdown';
import { getLastUpdated } from '../../utility'
import axios from "axios";
type Props = {
};

const Blog = (props: Props) => {
  let { blogId } = useParams();
  let [data, setData] = useState<Posting>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get<FindResponse<Posting>>(`/api/blog/${blogId}`);
      console.log(response.data)
      setData(response.data.result);
    };
    fetchData();
  }, []);
  console.log(data?.contents)
  return (
    <div className="container-fluid p-5">
      <h1 className="d-flex justify-content-center">
        <ReactMarkdown>
        {data?.title??"loading..."}
        </ReactMarkdown>
      </h1>
      <div className="d-flex justify-content-between">
        <h4 className="py-3">
            {data && <ReactMarkdown>{getLastUpdated(data.createdDate)}</ReactMarkdown>}
          </h4>
      </div>
      <img className="d-flex justify-content-center img-fluid"
       src={data?.image}>
      </img>
      <div>
        <ReactMarkdown>
          {data?.contents??"loading..."}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default Blog;
