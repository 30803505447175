import axios from 'axios';
import FsLightbox from 'fslightbox-react';
import { nanoid } from 'nanoid';
import React, { useEffect, useReducer } from 'react';
import { useState } from 'react'
import { Grid, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import FsLightCard from '../../../components/instructional-video/FsLightCard';
import { Playlist, PlaylistItem, PlaylistItemListResponse, PlaylistItemSnippet, PlaylistListResponse } from '../../../components/instructional-video/ResponseType';
import VideoList from '../../../components/instructional-video/VideoList';
import NavTabsHorizontal from '../../../components/navTabsHorizontal';
import { FaArrowCircleLeft, FaArrowCircleRight,FaAngleLeft, FaAngleRight } from 'react-icons/fa'
type Props = {}


function PlayListComponent({ list }: { list: Playlist }) {
    const part = "snippet,status";
    const itemsUrl = `/api/RelayStation/PlaylistItems?part=${part}&playlistId=${list.id}`;
    const [playListItemResponse, setItemsResponse] = useState<PlaylistItemListResponse>();

    let fetchItems = async (url: string) => {
        let response = await axios.get<PlaylistItemListResponse>(url);
        setItemsResponse(response.data)
    }
    let getNext = () => {
        if (playListItemResponse?.nextPageToken != null) {
            let url = itemsUrl + `${playListItemResponse?.nextPageToken ? `&pageToken=${playListItemResponse?.nextPageToken}` : ""}`
            fetchItems(url)
        }
    }
    let getPrev = () => {
        if (playListItemResponse?.prevPageToken != null) {
            let url = itemsUrl + `${playListItemResponse?.prevPageToken ? `&pageToken=${playListItemResponse?.prevPageToken}` : ""}`
            fetchItems(url)
        }
    }
    useEffect(() => {
        fetchItems(itemsUrl);
    }, [])

    return (
        <div className="hs-nav-scroller-horizontal">
            <div className='row'>
                <button className="col-1 btn btn-outline-light" onClick={getPrev}>
                    <FaAngleLeft className='text-dark'/>
                </button>
                <div className='col-10'>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 swiper-container" >
                        {
                            playListItemResponse?.items?.map(item => {
                                return <FsLightCard key={nanoid()} source={item.snippet} />
                            })
                        }
                    </div>
                </div>
                <button className="col-1 btn btn-outline-light" onClick={getNext}>
                    <FaAngleRight className='text-dark'/>
                </button>
            </div>
        </div>
    )
}


export default function InstructionalVideo({ }: Props) {
    const [playList, setList] = useState<Playlist[]>();
    const youtubePlaylist = "https://www.youtube.com/embed/";

    const playlistId = "PLQx4AaCA2zNWSnpmZEDA9f5Nwkvp68-bS";
    const part = "snippet,status";
    const listUrl = `/api/RelayStation/Playlist?part=${part},id&playlistId=${playlistId}`;

    let fetchList = async (url: string) => {
        let response = await axios.get<PlaylistListResponse>(url);
        setList(response.data.items)
    }

    useEffect(() => {
        fetchList(listUrl);
    }, [])

    return (
        <React.Fragment>
            <div className='container content-space-t-3 content-space-t-lg-5 content-space-b-2'>
                <NavTabsHorizontal
                    tab={playList?.map(e => e.snippet.localized.title)}
                    pages={playList?.map(e => <PlayListComponent list={e}></PlayListComponent>)}
                />
            </div>
        </React.Fragment>
    )
}