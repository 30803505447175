import axios, { AxiosError } from 'axios'
import React, { useState } from 'react'
import { Button, Form, FormGroup, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ContactRequest } from '../../Types'
import { validateCompanyName, validateEmail, validateName, validatePhoneNumber } from '../../utility'
import { nanoid } from 'nanoid'

type Props = {
  whom: string
  show: boolean,
  onHide: any, // the actual type of onHid is React.Dispatch<React.SetStateAction<boolean>>
  dropdownlist?:Map<string,string[]>
}


export default function GenericModal(props: Props) {
  const { t, i18n } = useTranslation();
  const [err, setErr] = useState<Map<string, string>>(new Map<string, string>())
  const setField = (field: string, value: string) => {
    setFields({ ...fields, [field]: value })
  }
  const validatiaon = (field: string, val: string) => {
    let [result, msg] = validator.get(field)!(val)
    if (!result) {
      err.set(field, msg)
    } else {
      err.clear()
    }
  }
  let isValid = () => {
    let [result, msg] = validateName(fields.name)
    if (!result) {
      setErr(new Map<string, string>([["name", msg]]))
      return false
    }

    [result, msg] = validatePhoneNumber(fields.phoneNumber)
    if (!result) {
      setErr(new Map<string, string>([["phoneNumber", msg]]))
      return false
    }

    [result, msg] = validateEmail(fields.email)
    if (!result) {
      setErr(new Map<string, string>([["email", msg]]))
      return false
    }

    [result, msg] = validateCompanyName(fields.companyName)
    if (!result) {
      setErr(new Map<string, string>([["companyName", msg]]))
      return false
    }

    setErr(new Map<string, string>())
    return true
  }

  let validator = new Map<string, Function>([
    ["name", validateName],
    ["companyName", validateCompanyName],
    ["email", validateEmail],
    ["phoneNumber", validatePhoneNumber],
  ])
  
  const [fields, setFields] = useState<ContactRequest>({ name: "", email: "", phoneNumber: "", companyName: "", contactReason: "", product: "", message: "" })

  const sendRequest = () => {
    console.log(fields)
    if (!isValid()) return


    axios.post("/api/contact/request", {
      ...fields,
      toWhom: props.whom
    })
    .then(()=>{
      alert("Message sent!");
    })
    .catch((err:AxiosError)=>{
      console.log(err)
      alert(err.response?.statusText)
    })

  }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t('contact.name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              autoFocus
              value={fields.name}
              onChange={(e) => {setField("name", e.target.value); validatiaon("name", e.target.value)}}
              isInvalid={err.has("name")}
            />
            <Form.Control.Feedback type="invalid">
              {err.get("name")}
            </Form.Control.Feedback>
          </Form.Group>

          <FormGroup>
            <Form.Label>{t('contact.phone number')}</Form.Label>
            <Form.Control
              type="text"
              placeholder="(123) 456-7890"
              value={fields.phoneNumber}
              onChange={(e) => {setField("phoneNumber", e.target.value); validatiaon("phoneNumber", e.target.value)}}
              isInvalid={err.has("phoneNumber")}
            />
            <Form.Control.Feedback type="invalid">
              {err.get("phoneNumber")}
            </Form.Control.Feedback>
          </FormGroup>

          <FormGroup>
            <Form.Label>{t('contact.email address')}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Johndoe@google.com"
              value={fields.email}
              onChange={(e) => {setField("email", e.target.value);validatiaon("email", e.target.value)}}
              isInvalid={err.has("email")}
            />
            <Form.Control.Feedback type="invalid">
              {err.get("email")}
            </Form.Control.Feedback>
          </FormGroup>

          <FormGroup>
            <Form.Label>{t('contact.company')}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Innovative Thinker"
              value={fields.companyName}
              onChange={(e) => {setField("companyName", e.target.value);validatiaon("companyName", e.target.value)}}
              isInvalid={err.has("companyName")}
            />
            <Form.Control.Feedback type="invalid">
              {err.get("companyName")}
            </Form.Control.Feedback>
          </FormGroup>

        {
          //this section is optional, if dropdownlist props is passed from parent this will be shown
            props.dropdownlist != undefined && 
            Array.from(props.dropdownlist).map((li)=>{
                return (
                <FormGroup key={nanoid()}>
              <Form.Label>{li[0]}</Form.Label>
              <Form.Select onChange={(e) => setField(li[0], e.currentTarget.value)} >
                <option>{t('contact.open this select menu')}</option>
                {
                  li[1].map((e, i) => (
                    <option key={i} value={e}>{e}</option>
                  ))
                }
              </Form.Select>
            </FormGroup>)
            })
            
        }

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{t('contact.message')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={fields.message}
              onChange={(e) => setField("message", e.target.value)} />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
        <Button variant="primary" onClick={sendRequest} disabled={err.size > 0}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}